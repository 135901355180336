import templ8r from '../libs/templ8r';

const MAILING_LIST = {
    PREFERENCES:       '/mailinglist/users/{uid}',
    OPT_IN:            '/mailinglist/users/{uid}/optin',    // Set to true to opt-in to mailing list
    STATUS:            '/mailinglist/users/{uid}/status',   // Latest status from Mailchimp (pending, subscribed, unsubscribed, cleaned)
    EMAIL:             '/mailinglist/users/{uid}/email'    // Email address used for mailing list
};

export default class MailingListResources {
    static Preferences(uid) {
        return templ8r(MAILING_LIST.PREFERENCES, {uid});
    }
    static OptIn(uid) {
        return templ8r(MAILING_LIST.OPT_IN, {uid});
    }
    static OptInRoute() {
        return MAILING_LIST.OPT_IN;
    }
    static Status(uid) {
        return templ8r(MAILING_LIST.STATUS, {uid});
    }
    static StatusRoute() {
        return MAILING_LIST.STATUS;
    }
    static Email(uid) {
        return templ8r(MAILING_LIST.EMAIL, {uid});
    }
    static EmailRoute() {
        return MAILING_LIST.EMAIL;
    }
}
