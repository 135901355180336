var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "modal" } }, [
    _c(
      "div",
      {
        staticClass: "modal-mask",
        style: { "background-color": _vm.mask },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.click.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "modal-wrapper" }, [
          _c("div", { staticClass: "modal-container" }, [
            _c("div", { staticClass: "modal-header" }, [_vm._t("header")], 2),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _vm._t("default", function () {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "modal-default-button",
                        on: { click: _vm.close },
                      },
                      [_vm._v("Close")]
                    ),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [_vm._t("footer")], 2),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }