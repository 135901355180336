var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "platform-icon" },
    _vm._l(_vm.platforms, function (platform) {
      return _c("img", { key: platform, class: platform })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }