var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "header-tab",
      class: { active: _vm.active },
      on: {
        click: _vm.click,
        mouseover: function ($event) {
          _vm.hover = true
        },
        mouseleave: function ($event) {
          _vm.hover = false
        },
      },
    },
    [
      _c("div", { staticClass: "tab-item" }, [
        _c("div", { staticClass: "close" }),
        _vm._v(" "),
        _vm.isAuthenticated && _vm.photo
          ? _c("img", {
              staticClass: "icon",
              attrs: { src: _vm.photo, referrerpolicy: "no-referrer" },
            })
          : _vm.isAuthenticated
          ? _c("img", {
              staticClass: "icon profile default",
              attrs: { referrerpolicy: "no-referrer" },
            })
          : _c(
              "svg",
              {
                staticClass: "icon",
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "25",
                  height: "25",
                  viewBox: "0 0 25 25",
                },
              },
              [
                !_vm.hover && !_vm.active
                  ? _c("g", [
                      _c("path", {
                        staticStyle: {
                          fill: "#333",
                          stroke: "#fff",
                          opacity: ".005",
                        },
                        attrs: {
                          d: "M0 0h24v24H0z",
                          transform: "translate(.5 .5)",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "g",
                        {
                          staticStyle: {
                            fill: "transparent",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                          },
                        },
                        [
                          _c("path", {
                            staticStyle: { stroke: "none" },
                            attrs: {
                              d: "M317.29 436.954c-1.303-.743-3.279-1.672-5.23-2.46v-1.917c.421-.326 1.094-1.145 1.17-3.076.514-.297.808-1.046.808-1.775 0-.65-.235-1.158-.626-1.423.37-.927.504-1.928.385-2.928l-.003-.032-.008-.032c-.188-.743-.756-1.351-1.643-1.757-.733-.336-1.67-.52-2.637-.52-.968 0-1.905.184-2.638.52-.71.325-1.215.779-1.484 1.328a2.562 2.562 0 0 0-.2-.008c-.653 0-.976.288-1.132.53-.498.768.067 2.025.441 2.857l.005.012c-.486.276-.798.864-.798 1.594 0 .812.387 1.448.97 1.676.09 1.925.821 2.732 1.27 3.048v1.904c-1.951.787-3.926 1.716-5.23 2.459A11.397 11.397 0 0 1 297.5 429c0-6.341 5.159-11.5 11.5-11.5s11.5 5.159 11.5 11.5c0 2.988-1.137 5.798-3.21 7.954z",
                              transform: "translate(-296.5 -416.5)",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            staticStyle: { fill: "#fff", stroke: "none" },
                            attrs: {
                              d: "M317.19 436.326A10.892 10.892 0 0 0 320 429c0-6.065-4.935-11-11-11s-11 4.935-11 11c0 2.73.992 5.303 2.81 7.326 1.235-.676 2.929-1.47 4.63-2.167v-1.335c-.499-.444-1.108-1.313-1.25-2.959-.605-.372-.99-1.101-.99-1.998 0-.732.256-1.352.679-1.757-.394-.908-.814-2.101-.247-2.978.177-.274.6-.728 1.464-.757.34-.524.868-.957 1.564-1.276.797-.365 1.807-.566 2.846-.566 1.038 0 2.048.201 2.845.566 1.03.472 1.693 1.194 1.92 2.09.01.042.018.084.023.127.114.964.012 1.93-.295 2.84.34.376.54.922.54 1.57 0 .505-.12 1.01-.335 1.421a2.04 2.04 0 0 1-.487.621c-.114 1.697-.68 2.585-1.157 3.04v1.35c1.701.699 3.395 1.492 4.63 2.168m.182 1.255c-1.391-.836-3.734-1.925-5.812-2.747V432.3s1.178-.443 1.178-3.166c.896 0 1.23-2.534-.01-2.534a5.498 5.498 0 0 0 .573-3.166c-.64-2.534-6.95-2.534-7.59 0-2.689-.545-.55 2.801-.55 3.166-1.281 0-1.281 2.534 0 2.534 0 2.723 1.279 3.166 1.279 3.166v2.534c-2.078.822-4.42 1.91-5.812 2.747A11.96 11.96 0 0 1 297 429c0-6.617 5.383-12 12-12s12 5.383 12 12c0 3.362-1.394 6.4-3.628 8.58z",
                              transform: "translate(-296.5 -416.5)",
                            },
                          }),
                        ]
                      ),
                    ])
                  : _c("g", { attrs: { "data-name": "Icon 24 • Contact" } }, [
                      _c("path", {
                        staticStyle: {
                          stroke: "#fff",
                          "stroke-width": ".5px",
                          opacity: ".005",
                          fill: "#fff",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                        },
                        attrs: {
                          d: "M0 0h24v24H0z",
                          transform: "translate(.25 .25)",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "g",
                        {
                          staticStyle: {
                            fill: "#fff",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                          },
                        },
                        [
                          _c("path", {
                            staticStyle: { stroke: "none" },
                            attrs: {
                              d: "M300.667 437.267A11.65 11.65 0 0 1 297.25 429c0-6.479 5.271-11.75 11.75-11.75s11.75 5.271 11.75 11.75a11.65 11.65 0 0 1-3.417 8.267c-1.32-.771-3.445-1.773-5.523-2.603v-2.218c.347-.228 1.127-.977 1.175-3.105.508-.18.803-.909.803-1.615 0-.665-.263-1.142-.695-1.31.42-.945.578-1.98.456-3.012l-.002-.016-.004-.016c-.364-1.443-2.29-2.089-4.037-2.089-1.655 0-3.47.58-3.967 1.867a2.577 2.577 0 0 0-.355-.026c-.434 0-.744.14-.922.415-.424.655.108 1.837.46 2.62l.106.24c-.578.18-.878.827-.878 1.468 0 .671.33 1.35.963 1.49.056 2.133.907 2.874 1.277 3.096v2.211c-2.078.83-4.202 1.832-5.523 2.603z",
                              transform: "translate(-296.75 -416.75)",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            staticStyle: { fill: "#fff", stroke: "none" },
                            attrs: {
                              d: "M317.29 436.954A11.397 11.397 0 0 0 320.5 429c0-6.341-5.159-11.5-11.5-11.5s-11.5 5.159-11.5 11.5c0 2.988 1.137 5.798 3.21 7.954 1.304-.743 3.279-1.672 5.23-2.46v-1.903c-.449-.316-1.18-1.123-1.27-3.048-.583-.228-.97-.864-.97-1.676 0-.73.312-1.318.798-1.594l-.005-.012c-.374-.832-.939-2.089-.441-2.858.156-.241.48-.53 1.132-.53.064 0 .13.004.2.009.27-.55.775-1.003 1.484-1.328.733-.336 1.67-.52 2.638-.52.967 0 1.904.184 2.637.52.887.406 1.455 1.014 1.643 1.757.005.021.009.042.011.064.119 1-.014 2.001-.385 2.928.39.265.626.772.626 1.423 0 .729-.294 1.478-.807 1.775-.077 1.931-.75 2.75-1.17 3.076v1.918c1.95.787 3.926 1.716 5.229 2.459m.082.627c-1.391-.836-3.734-1.925-5.812-2.747V432.3s1.178-.443 1.178-3.166c.896 0 1.23-2.534-.01-2.534a5.498 5.498 0 0 0 .573-3.166c-.64-2.534-6.95-2.534-7.59 0-2.689-.545-.55 2.801-.55 3.166-1.281 0-1.281 2.534 0 2.534 0 2.723 1.279 3.166 1.279 3.166v2.534c-2.078.822-4.42 1.91-5.812 2.747A11.96 11.96 0 0 1 297 429c0-6.617 5.383-12 12-12s12 5.383 12 12c0 3.362-1.394 6.4-3.628 8.58z",
                              transform: "translate(-296.75 -416.75)",
                            },
                          }),
                        ]
                      ),
                    ]),
              ]
            ),
        _vm._v(" "),
        _c("span", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))]),
        _vm._v(" "),
        _c("div", { staticClass: "underline", class: { active: _vm.active } }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }