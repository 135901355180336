var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "support" },
    [
      _vm.authenticated && _vm.photo
        ? _c("profile-icon", {
            staticClass: "profile icon",
            attrs: { icon: _vm.photo },
          })
        : _c("img", { staticClass: "bullpinkle icon" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("div", { staticClass: "secondary-title" }, [_vm._v("My account")]),
          _vm._v(" "),
          _c("div", { staticClass: "title" }, [_vm._v("Help and support")]),
          _vm._v(" "),
          _c("chip"),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Check out the resources below to help get the most out of iHunter. If you don’t see the answer you’re looking\n            for please get in touch using the contact form at the bottom of this page."
            ),
          ]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [
              _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function ($event) {
                      return _vm.navigate("support", "purchases")
                    },
                  },
                },
                [_vm._v("Purchases from other stores")]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function ($event) {
                      return _vm.navigate("support", "videos")
                    },
                  },
                },
                [_vm._v("Walkthrough videos")]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function ($event) {
                      return _vm.navigate("support", "contact")
                    },
                  },
                },
                [_vm._v("Contact us")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("divider"),
          _vm._v(" "),
          _c("h2", { attrs: { id: "purchases" } }, [
            _vm._v("Purchases from other stores"),
          ]),
          _vm._v(" "),
          _c("chip"),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "iHunter is available in your desktop web browser and on your Android or iOS mobile device. If you’ve been\n            with us for a while you might already have maps that you purchased there. Don’t worry. An iHunter purchase\n            is an iHunter purchase. But you may need some more information on how they all work together, what it means\n            to transfer them between platforms, and how purchasing from the iHunter Store can make things easier for you\n            going forward."
            ),
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("iHunter Store purchases")]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "p",
            [
              _c("platform-label", {
                attrs: { purchase: { web: { mock: "purchase" } } },
              }),
              _c("span", { staticClass: "details" }, [
                _vm._v(
                  "Look for this\n                icon on your purchases page to help identify them."
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("h3", [_vm._v("iOS purchases")]),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c(
            "p",
            [
              _c("platform-label", {
                attrs: { purchase: { apple: { mock: "purchase" } } },
              }),
              _c("span", { staticClass: "details" }, [
                _vm._v(
                  "Look for\n                this icon on your purchases page to help identify them."
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("h3", [_vm._v("Android purchases")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Purchases made on Android will be tied to your Google Play Account on your device. As long as you’re using\n            the same Google Account on both devices you should have access to your purchases."
            ),
          ]),
          _vm._v(" "),
          _c(
            "p",
            [
              _c("platform-label", {
                attrs: { purchase: { android: { mock: "purchase" } } },
              }),
              _c("span", { staticClass: "details" }, [
                _vm._v(
                  "Look for\n                this icon on your purchases page to help identify them."
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("divider"),
          _vm._v(" "),
          _c("h2", { attrs: { id: "videos" } }, [_vm._v("Walkthrough videos")]),
          _vm._v(" "),
          _c("chip"),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Check out our YouTube playlist for for walkthroughs on some of the powerful features available in the iHunter\n            mobile app."
            ),
          ]),
          _vm._v(" "),
          _c("iframe", {
            staticClass: "video-player",
            attrs: {
              width: "560",
              height: "315",
              src: "https://www.youtube.com/embed/videoseries?list=PLqBh7ZG8WbCx-IIkjuiAd70J5P1B5JJFR",
              title: "iHunter Walkthrough videos",
              frameborder: "0",
              allow:
                "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;",
              allowfullscreen: "",
            },
          }),
          _vm._v(" "),
          _c("divider"),
          _vm._v(" "),
          _c("h2", { attrs: { id: "contact" } }, [_vm._v("Contact us")]),
          _vm._v(" "),
          _c("chip"),
          _vm._v(" "),
          !_vm.sent
            ? _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "product" } }, [
                      _vm._v("Name"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.name,
                            expression: "name",
                          },
                        ],
                        attrs: {
                          type: "text",
                          id: "name",
                          name: "name",
                          placeholder: "Your name",
                          required: "",
                        },
                        domProps: { value: _vm.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.name = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "email" } }, [_vm._v("Email")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.email,
                            expression: "email",
                          },
                        ],
                        attrs: {
                          type: "email",
                          id: "email",
                          name: "email",
                          placeholder: "Your email",
                          required: "",
                        },
                        domProps: { value: _vm.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.email = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "price" } }, [
                      _vm._v("Message"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.message,
                            expression: "message",
                          },
                        ],
                        attrs: {
                          id: "message",
                          name: "message",
                          placeholder: "Enter message here",
                          rows: "5",
                          required: "",
                        },
                        domProps: { value: _vm.message },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.message = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "button",
                    { attrs: { type: "submit", disabled: _vm.processing } },
                    [_c("span", [_vm._v("Send")])]
                  ),
                ]
              )
            : _c("div", [
                _c("h3", [_vm._v("Message sent")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Thanks for your inquiry. We’ll be in touch as soon as possible."
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "detail" }, [
                  _vm._v("Click "),
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          _vm.sent = false
                        },
                      },
                    },
                    [_vm._v("here")]
                  ),
                  _vm._v(" to send another\n                message."),
                ]),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("Purchases made on "),
      _c("b", [_vm._v("store.ihunterapp.com")]),
      _vm._v(
        " are tied directly to your iHunter Account. That means they go\n            with you wherever you use iHunter and sign in with the same details. Web browser, Android, iOS, your\n            purchases will be there and work how you expect."
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "Purchases made on iOS using the App Store are tied to the Apple ID on your device. This is possible even if\n            you haven’t signed in to an iHunter Account. If you notice missing purchases on an iOS device, navigate to\n            the "
      ),
      _c("b", [_vm._v("My Purchases")]),
      _vm._v(" screen from the settings menu within iHunter and tap the "),
      _c("b", [_vm._v("Restore Purchases")]),
      _vm._v("\n            button."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }