<style lang="scss" scoped>
    @import 'styles/common.scss';

    .notification {
        padding: $space;
        background-color: $validation-good-dark;
        width: 100%;

        @include Notification;
    }

</style>

<template>
    <div class="notification" v-if="notifications.length">
        <div v-for="(message, i) in notifications" :key="i" v-html="message"></div>
    </div>
</template>

<script>
    import { Component, Watch, Vue } from 'vue-property-decorator';
    import AppState from './AppState.vue';

    @Component
    export default class NotificationBar extends Vue {

        notice = {};
        timers = {};

        @Watch('state.cart.items')
        onCartUpdate(after, before) {
            //console.log("Cart update");
            
            if(after.length >= before.length) {
                let products = this.findMissingProducts(after, before);
                for(let sku of products){
                    let info = this.state.productInfo(sku);
                    if(info?.name){
                        this.addNotice(sku, `Added <b>'${info?.name}'</b> to cart.`);
                    }
                }
            }else {
                let products = this.findMissingProducts(before, after);
                for(let sku of products){
                    let info = this.state.productInfo(sku);
                    if(info?.name){
                        this.addNotice(sku, `Removed <b>'${info?.name}'</b> from cart.`);
                    }
                }
            }
        }

        addNotice(sku, notice) {
            this.$set(this.notice, sku, notice);

            if(this.timers[sku]) {
                clearTimeout(this.timers[sku]);
            }
            this.timers[sku] = setTimeout(() => {
                this.$delete(this.notice, sku);
                delete this.timers[sku]; // Not responsive
            }, 5000);
        }

        findMissingProducts(after, before) {
            let products = [];
            for(let i in after) {
                let match = false;
                for(let j in before) {
                    if(before[j].sku === after[i].sku) {
                        match = true;
                        continue;
                    }
                }
                if(!match) {
                    products.push(after[i].sku);
                    //return after[i].sku;
                }
            }
            return products;
        }

        get notifications() {
            return Object.values(this.notice);
        }

        get state() {
            return AppState;
        }
    }

    Vue.component('notification-bar', NotificationBar);

</script>
