var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "purchase-summary" },
    [
      _vm.emptyPurchases
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showHeader,
                  expression: "showHeader",
                },
              ],
              staticClass: "header",
            },
            [
              _c("h2", [_vm._v("No purchases")]),
              _vm._v(" "),
              _c("chip"),
              _vm._v(" "),
              _c("div", { staticClass: "warning-content" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      "data-name": "Icon 12 • Alert",
                      width: "16",
                      height: "16",
                      viewBox: "0 0 16 16",
                    },
                  },
                  [
                    _c("path", {
                      staticStyle: { fill: "#777" },
                      attrs: {
                        d: "M167.281 550.333h-14.572a.7.7 0 0 1-.608-1.053l7.293-11.941a.717.717 0 0 1 1.219 0l7.282 11.926a.7.7 0 0 1-.614 1.068zm-6.929-1.411a1.253 1.253 0 0 0 .425-.915 1 1 0 0 0-.3-.736 1.018 1.018 0 0 0-.741-.3 1.425 1.425 0 0 0-1.4 1.364.938.938 0 0 0 .3.716 1.053 1.053 0 0 0 .749.279 1.353 1.353 0 0 0 .967-.407zm-1.051-6.384c-.089.748-.183 1.411-.276 1.989s-.141.895-.141.948a.573.573 0 0 0 .275.509 1.283 1.283 0 0 0 .713.18 2.251 2.251 0 0 0 1-.252 2.025 2.025 0 0 1-.029-.411 12.107 12.107 0 0 1 .411-2.752 14.8 14.8 0 0 0 .409-1.968q0-.784-1.131-.783a5.052 5.052 0 0 0-1.129.145 3.856 3.856 0 0 1 .028.571 15.96 15.96 0 0 1-.131 1.825z",
                        transform: "translate(-152 -535.667)",
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _vm._m(0),
              ]),
            ],
            1
          )
        : _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showHeader,
                  expression: "showHeader",
                },
              ],
              staticClass: "header",
            },
            [_c("h2", [_vm._v("My purchases")]), _vm._v(" "), _c("chip")],
            1
          ),
      _vm._v(" "),
      _vm._l(_vm.regionalPurchases, function (products, region) {
        return _c(
          "div",
          { key: region, staticClass: "region-area" },
          [
            _c("div", { staticClass: "section-row" }, [
              _c("span", { staticClass: "section-title" }, [
                _vm._v("iHUNTER " + _vm._s(region.toUpperCase()) + " "),
                _c("b", [_vm._v(_vm._s(products.length))]),
              ]),
              _c("span", { staticClass: "item-count" }),
            ]),
            _vm._v(" "),
            _vm._l(products, function (product) {
              return _c(
                "div",
                { key: product?.sku, staticClass: "item-row" },
                [
                  _c("div", [
                    product?.name
                      ? _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(product?.name)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    product?.version
                      ? _c("span", { staticClass: "details" }, [
                          _vm._v(_vm._s(product?.version)),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  product
                    ? _c("platform-label", {
                        attrs: { purchase: product.purchase },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
          ],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "details" }, [
      _vm._v(
        "Not what you were expecting? If you have made purchases from your mobile device it’s possible they aren’t currently associated with an iHunter Account. Please visit the "
      ),
      _c("a", { attrs: { href: "/#/support" } }, [
        _vm._v("Help and support page"),
      ]),
      _vm._v(" for more information."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }