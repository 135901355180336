<style lang="scss" scoped>
    @import 'styles/common.scss';

    $card-hover-colour: $mono-glow;// #faf8f0; // This colour is unnamed in Zeplin

    //$card-height: 145px;
    $action-button-height: 48px;

    .list-item {
        position: relative;
        display: flex;
        flex-direction: row;

        //width: 100%;
        //height: $card-height;

        //margin-left: $space;
        
        // background-color: $mono-white;
        color: $mono-black;

        overflow: hidden; // Round outside corners; no scrollbars

        .icon-section {
            display: flex;
            align-items: center;
            margin: 0 $gap;
        }

        .item-content {
            display: flex;
            flex-direction: row;
            width: 100%;
            padding: $space 0;
        }

        .info-section {
            flex-grow: 1;

            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-rows: auto 1fr auto;

            grid-template-areas:
            'secondary alternate'
            'title     price'
            'details   recurring';
            
            .title {
                grid-area: title;
                //padding: 0 $space;
                a{
                    font-size: 16px;
                    font-weight: normal;
                    color: $mono-black;
                    // @include Title-16;
                }
            }

            .secondary {
                grid-area: secondary;

                padding: $notch 0; 
                font-size: 11px;
                text-align: left;
                color: $general-orange-dark;
            }

            .price {
                grid-area: price;
                padding: 0 $space;
                text-align: left;
                @include Price-18;
            }

            .alternate{
                grid-area: alternate;
                padding: $notch $space; 
                text-align: right;
                @include Text-11;
                text-decoration: line-through;
            }

            .recurring {
                grid-area: recurring;
                font-size: 11px;
                text-align: right;
                color: $mono-black;
            }

            .details {
                grid-area: details;
                font-size: 12px;
                color: $mono-dark;
                //padding: 0 $space;
            }
        }


        .action-section {
            grid-area: action;
            flex-grow: 0;

            display: flex;
            flex-direction: row;

            padding: 0 $space;
            // button {
            //     margin-right: $space;
            //     width: $medium-size;
            //     @include above(small)  {
            //         width: $large-size;
            //     }

            //     &:hover, &:active, &:focus {
            //         background-color: $general-blue-dark;
            //         border: 2px solid $general-orange-light;
            //     }

            //     .action-icon {
            //         display: flex;
            //         align-items: center;
            //         text-align: center;
            //         margin: 0 $space;

            //         svg {
            //             fill: $mono-white;
            //         }
            //     }

            //     .action-text {
            //         display: flex;
            //         margin: 0 $notch;

            //         align-items: center;
            //         text-align: center;
                    

            //         span {
            //             font-size: 12px;
            //             font-weight: 900;
            //             letter-spacing: 3.07px;
            //             color: $mono-white;
            //             text-transform: uppercase;
            //         }
            //     }
            // }
        }

        &:hover {
            .title {
                a:visited, a:active, a:focus, a:link { 
                    text-decoration: underline; 
                    color: $general-blue; 
                }

                a:hover {
                    color: $general-blue-dark; 
                }
            }

            .details {
                color: $mono-black;
            }

        }

        &.cart {
            .action-section {
                background-color: transparent;

                // button {
                //     background-color: $mono-grey;
                //     border: solid 1px $mono-misc;

                //     .action-icon {                   
                //         svg {
                //             fill: $mono-light;
                //         }
                //     }
                // }
            }
        }
    }

</style>

<template>

    <div class="list-item" :class="{cart: incart}" @click="click">
        <div class="icon-section">
            <layer-icon :type="type" fill="#D92121"></layer-icon>
        </div>
        <div class="item-content">
            <div class="info-section">
                <div class="secondary" v-if="subscription">Subscription</div>
                <div class="secondary" v-else-if="map && outdated">Update {{productVersion(outdated)}} ▸ {{version}}</div>
                <div class="secondary" v-else-if="map">{{version}}</div>

                <div class="title"><a :href="`#/${type}/${sku}`" @click.stop>{{name}}</a></div>
                <div class="details" v-if="map"><b>{{region}}</b></div>
                <div class="alternate" v-if="alternate">${{alternate}}</div>

                <div class="price">
                    <div v-if="!owned">
                        ${{price}}
                        <div class="recurring" v-if="subscription">/year</div>
                    </div>
                    <div v-else>
        
                        <platform-label :purchase="purchase"></platform-label>
                    </div>
                </div>

                
            </div>
            
            <div class="action-section" v-if="!owned">
                <product-cart-button :sku="sku"></product-cart-button>
            </div>
        </div>

        <sash v-if="alternate" :small="true"></sash>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import ProductItem from './ProductItem.vue';

    @Component
    export default class ProductListItem extends ProductItem {

        click() {
            if(this.owned){
                return;
            }

            if(this.cart.contains(this.sku)){
                this.cart.remove(this.sku);
            }else {
                this.cart.buy(this.sku);
            }
        }
    }

    Vue.component('product-list-item', ProductListItem);

</script>

