var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c("h2", [_vm._v(_vm._s(_vm.user?.displayName))]),
    _vm._v(" "),
    _c("div", [
      _c("span", { staticClass: "orange" }, [_vm._v(_vm._s(_vm.user?.email))]),
    ]),
    _vm._v(" "),
    _c("div", [
      !_vm.isProduction
        ? _c("span", { staticClass: "red" }, [_vm._v(_vm._s(_vm.projectId))])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "item" }, [
      _c("span", [_vm._v("Account created:")]),
      _c("span", { domProps: { textContent: _vm._s(_vm.creationTime) } }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "item" }, [
      _vm._v("Last login: "),
      _c("span", { domProps: { textContent: _vm._s(_vm.lastSignInTime) } }),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "secondary-title" }, [_vm._v("My Content")]),
    _vm._v(" "),
    _c("div", { staticClass: "item" }, [
      _vm._v("Waypoints: "),
      _c("span", {
        domProps: { textContent: _vm._s(this.summary?.waypoints) },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "item" }, [
      _vm._v("Tracks"),
      _c("span", { domProps: { textContent: _vm._s(this.summary?.tracks) } }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "item" }, [
      _vm._v("Drawings: "),
      _c("span", { domProps: { textContent: _vm._s(this.summary?.drawings) } }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "item" }, [
      _vm._v("Contacts: "),
      _c("span", { domProps: { textContent: _vm._s(this.summary?.contacts) } }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "item" }, [
      _vm._v("Map Layers: "),
      _c("span", { domProps: { textContent: _vm._s(this.summary?.layers) } }),
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "secondary",
        on: {
          click: function ($event) {
            return _vm.logout()
          },
        },
      },
      [_vm._v("Sign out")]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        on: {
          click: function ($event) {
            return _vm.confirm()
          },
        },
      },
      [_vm._v("Confirm")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }