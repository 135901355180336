<style lang="scss" scoped>
    @import 'styles/common.scss';

    .platform-label {
        display: inline-flex;
        flex-direction: row;

        .description {
            display: none;

            @include above(min)  {
                display: flex;
            }

            span {
                // color: $mono-dark;
                margin: auto;
                @include Text-13;
            }
        }
    }



</style>

<template>

    <div class="platform-label">
        <div class="description" v-if="!multiplePlatforms">
            <span v-if="platform === 'apple'">iOS</span>
            <span v-if="platform === 'android'">Android</span>
            <span v-if="platform === 'web'">Store</span>
        </div>

        <platform-icon :purchase="purchase"></platform-icon>
    </div>


</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    @Component
    export default class PlatformLabel extends Vue.extend({
        props: {
            purchase: {
                type: Object,
                default: () => { return {} }
            }
        }
    }) {

        get platforms() {
            return Object.keys(this.purchase);
        }

        get multiplePlatforms() {
            return this.platforms?.length > 1;
        }

        get empty() {
            return !this.platforms?.length;
        }

        get platform() {
            return this.platforms[0];
        }

    }

    Vue.component('platform-label', PlatformLabel);

</script>

