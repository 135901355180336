var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.error.found
    ? _c(
        "div",
        { staticClass: "notification" },
        [
          _vm._l(_vm.error.notice, function (message, i) {
            return _c("div", { key: i, staticClass: "error-block" }, [
              _c("div", [_c("b", [_vm._v(_vm._s(message))])]),
              _vm._v(" "),
              _c("div", {
                staticClass: "close",
                on: {
                  click: function ($event) {
                    return _vm.dismiss(i)
                  },
                },
              }),
            ])
          }),
          _vm._v(" "),
          _vm._m(0),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "notice" }, [
      _vm._v("If the problem persists, please "),
      _c("a", { attrs: { href: "#/support/" } }, [_vm._v("contact support")]),
      _vm._v(" for further assistance."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }