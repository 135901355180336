// Load favicons
// import 'assets/favicon.ico';
// import 'assets/logo.png';
// import 'assets/favicon-16x16.png';
// import 'assets/favicon-32x32.png';

// Load global styles
import '../styles/store.scss';

// Load common components
import 'components/Modal.vue';
import 'components/Expandable.vue';
import 'components/JsonDiff.vue';
import 'components/CatalogExplorer.vue';
import 'components/AppIcon.vue';
import 'components/SubscriptionIcon.vue';
import 'components/PromotionBanner.vue';
import 'components/ToggleSwitch.vue';
import 'components/Popup.vue';
import 'components/InputDialog.vue';
import 'components/TextBox.vue';
import 'components/ProfileIcon.vue';


// Load components
import './WebAppBadge.vue';
import './AppStoreBadge.vue';
import './PlayStoreBadge.vue';
import './AppNotice.vue';
import './AccountConfirmation.vue';
import './PurchaseSummary.vue';
import './WordLogo.vue';
import './Chip.vue';
import './Close.vue';
import './AccountMenu.vue';
import './CartMenu.vue';
import './ProductList.vue';
import './ProductListItem.vue';
import './ProductCard.vue';
import './NotificationBar.vue';
import './ErrorBar.vue';
import './CartItem.vue';
import './PlatformIcon.vue';
import './PlatformLabel.vue';
import './CartIcon.vue';
import './Sash.vue';
import './Login.vue';
import './AccountMenu.vue';
import './MenuItem.vue';
import './BadgeMenuItem.vue';
import './RegionCard.vue';
import './BagPinkle.vue';
import './LayerIcon.vue';
import './Landscape.vue';
import './Divider.vue';
import './ProductCartButton.vue';
import './CartPromoNotice.vue';
import './WebAppIcon.vue';
import './ProductIcon.vue';
import './ProductNotice.vue';
import './HomePage.vue';
import './UpdatesPage.vue';
import './SearchResultsPage.vue';
import './PurchasesPage.vue';
import './SupportPage.vue';
import './BillingPage.vue';
import './BillingSubscriptionItem.vue';
import './BillingSubscriptionDate.vue';
import './AccountPage.vue';
import './RegionPage.vue';
import './MapProductPage.vue';
import './SubscriptionProductPage.vue';
import './PurchaseUpdateSection.vue';

import './HeaderHomeTab.vue';
import './HeaderSearchTab.vue';
import './HeaderCartTab.vue';
import './HeaderAccountTab.vue';



// Load app view
import App from '../../common/src/App';
import Store from './Store.vue';
// import StoreDataAccessLayer from './StoreDataAccessLayer';

// window.dal = new StoreDataAccessLayer();// Load data access layer
window.app = new App(Store); // App entry-point
 