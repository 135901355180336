<style lang="scss" scoped>
    @import 'styles/common.scss';

    button {
        margin-right: $space;
        width: $medium-size;
        @include between(small, large)  {
            width: $large-size;
        }
        @include above(large)  {
            width: $large-size + $medium-size;
        }

        &.full {
            width: $large-size + $medium-size;
            margin-top: $icon-size;
            margin-bottom: $icon-size;
        }


        &:hover, &:active, &:focus {
            background-color: $general-blue-dark;
            border: 2px solid $general-orange-light;
        }

        .action-icon {
            display: flex;
            align-items: center;
            text-align: center;
            margin: 0 $space;

            svg {
                fill: $mono-white;
            }
        }

        .action-text {
            display: flex;
            margin: 0 $notch;

            align-items: center;
            text-align: center;
            

            span {
                font-size: 12px;
                font-weight: 900;
                letter-spacing: 3.07px;
                color: $mono-white;
                text-transform: uppercase;
            }
        }

        &.cart {
            background-color: $mono-grey;
            border: solid 1px $mono-misc;

            .action-icon {                   
                svg {
                    fill: $mono-light;
                }
            }
        }

        &.owned {
            display: none;
        }

        .show-small {
            @include above(small)  {
                display: none;
            }
        }

        .show-medium {
            display: none;
            @include above(small)  {
                display: initial;
            }
        }

        .show-large {
            display: none;
            @include above(large)  {
                display: initial;
            }
        }
    }

</style>

<template>

    <button class="cart-button" :class="{cart: incart, owned: owned || ownGroup, full}" @click.stop="buy">
        <div class="action-icon">
            <cart-icon></cart-icon>
        </div>
        <div class="action-text" v-if="!cart.contains(sku)">
            <div v-if="full">
                <span>Add to cart</span>
            </div>
            <div v-else>
                <span class="show-small">+</span>
                <span class="show-medium">Add</span>
                <span class="show-large">to cart</span>
            </div>

        </div>
    </button>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import ProductItem from './ProductItem.vue';

    @Component
    export default class ProductCartButton extends ProductItem.extend({
        props: {
            full: {
                args: Boolean,
                default: false
            }
        }
    }){
    }

    Vue.component('product-cart-button', ProductCartButton);

</script>

