var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "billing-item" }, [
    _c(
      "div",
      { staticClass: "top" },
      [
        _c("app-icon", {
          staticClass: "icon",
          attrs: { region: _vm.productInfo?.region },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.name) + " "),
          _c("span", { staticClass: "generic" }, [
            _vm._v(_vm._s(_vm.productInfo?.generic)),
          ]),
        ]),
        _vm._v(" "),
        _vm.cancelled
          ? _c("div", { staticClass: "details" }, [
              _c("p", { staticClass: "more" }, [
                _vm._v(
                  "This subscription has been set to expire. All features are still active until the expiry date."
                ),
              ]),
            ])
          : _vm.phasing
          ? _c("div", { staticClass: "details" }, [
              _c("p", { staticClass: "more" }, [
                _vm._v("Your current "),
                _c("b", [_vm._v(_vm._s(_vm.upgradeInfo?.name))]),
                _vm._v(
                  " subscription is scheduled to downgrade. All features are still active until the change date."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("New subscription price at renewal: "),
                _c("b", [
                  _vm._v("$" + _vm._s(_vm.productInfo?.price) + " / year."),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("billing-subscription-date", { attrs: { info: _vm.info } }),
        _vm._v(" "),
        _c("div", { staticClass: "action-button-row" }, [
          _vm.action?.downgrade
            ? _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "arrow",
                    attrs: { href: "javascript:void(0)" },
                    on: { click: _vm.downgradeSubscription },
                  },
                  [_vm._v("Downgrade to Core ▸")]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.action?.upgrade
            ? _c("div", [
                _vm.phasing
                  ? _c(
                      "a",
                      {
                        staticClass: "arrow",
                        attrs: { href: "javascript:void(0)" },
                        on: { click: _vm.upgradeSubscription },
                      },
                      [_vm._v("Undo change ▸")]
                    )
                  : _c(
                      "a",
                      {
                        staticClass: "arrow",
                        attrs: { href: "javascript:void(0)" },
                        on: { click: _vm.confirmUpgradeSubscription },
                      },
                      [_vm._v("Upgrade to Pro ▸")]
                    ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.action?.renew
            ? _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "arrow",
                    attrs: { href: "javascript:void(0)" },
                    on: { click: _vm.renewSubscription },
                  },
                  [_vm._v("Set to renew ▸")]
                ),
              ])
            : _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "arrow secondary",
                    attrs: { href: "javascript:void(0)" },
                    on: { click: _vm.cancelSubscription },
                  },
                  [
                    _vm._v("Cancel"),
                    _c("span", { staticClass: "extra" }, [
                      _vm._v(" subscription"),
                    ]),
                    _vm._v(" ▸"),
                  ]
                ),
              ]),
        ]),
        _vm._v(" "),
        _vm.processing === "upgrade"
          ? _c(
              "dialog-box",
              {
                attrs: { title: _vm.title, action: "Upgrade now" },
                on: {
                  action: _vm.upgradeSubscription,
                  cancel: function ($event) {
                    _vm.processing = null
                  },
                },
              },
              [
                _c("p", [
                  _vm._v("Selecting "),
                  _c("i", [_vm._v("“Upgrade now” ")]),
                  _vm._v(
                    " will immediately change your current subscription to\n                "
                  ),
                  _c("b", [_vm._v(_vm._s(_vm.upgradeInfo?.name))]),
                  _vm._v(
                    " and charge you the difference in price based on the time left until your\n                renewal date."
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "details" }, [
                  _vm._v(
                    "At the next renewal you will charged the regular " +
                      _vm._s(_vm.upgradeInfo?.name) +
                      " price: "
                  ),
                  _c("b", [
                    _vm._v(
                      "$" +
                        _vm._s(_vm.upgradeInfo?.price) +
                        " /\n                year."
                    ),
                  ]),
                ]),
              ]
            )
          : _vm.processing
          ? _c(
              "dialog-box",
              { attrs: { title: _vm.title, cancel: null, action: null } },
              [
                _c("p", [
                  _vm._v(
                    "Your subscription details will refresh in just a moment..."
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }