var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "billing" },
    [
      _c("profile-icon", {
        staticClass: "profile icon",
        attrs: { icon: _vm.photo },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("div", { staticClass: "secondary-title" }, [_vm._v("My account")]),
          _vm._v(" "),
          _c("div", { staticClass: "title" }, [_vm._v("Manage billing")]),
          _vm._v(" "),
          _c("chip"),
          _vm._v(" "),
          _c("p", [
            _c("span", [
              _vm._v(
                "All purchases made through the iHunter Store use Stripe to process payments to Inside Outside Studios\n                Inc."
              ),
            ]),
            _vm._v(" "),
            _vm.portalAvailable
              ? _c("span", [
                  _vm._v(
                    "Using the link below will take you to a Stripe billing page where you can edit\n                your payment details."
                  ),
                ])
              : _c("span", [
                  _vm._v(
                    "Once you have made your first purchase you will be able to manage the billing details here."
                  ),
                ]),
          ]),
          _vm._v(" "),
          _vm.portalAvailable
            ? _c("p", [
                _c(
                  "a",
                  {
                    staticClass: "arrow",
                    attrs: { href: "javascript:void(0)" },
                    on: { click: _vm.portal },
                  },
                  [_vm._v("Edit payment details ▸")]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("divider"),
          _vm._v(" "),
          _c("h3", { staticClass: "subscription-title" }, [
            _vm._v("Active subscriptions\n            "),
            _c(
              "svg",
              {
                attrs: {
                  width: "16",
                  height: "16",
                  viewBox: "0 0 16 16",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
                on: { click: _vm.refreshSubscriptions },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M14.98 3.759a.667.667 0 0 0-.886.322l-.84 1.8a5.662 5.662 0 1 0-.867 5.711.667.667 0 0 0-1.03-.847 4.337 4.337 0 1 1 .83-3.866L9.649 5.696a.667.667 0 1 0-.563 1.208l4.241 1.978 1.978-4.241a.667.667 0 0 0-.326-.882z",
                    fill: "#2875B4",
                  },
                }),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.processing
            ? _c(
                "dialog-box",
                {
                  attrs: {
                    title: "Refreshing subscriptions",
                    cancel: null,
                    action: null,
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      "Your subscription details will refresh in just a moment..."
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.subscriptions.length
            ? _c("p", [_vm._v("No subscriptions found.")])
            : _vm._l(_vm.subscriptions, function (info, index) {
                return _c(
                  "billing-subscription-item",
                  {
                    key: index,
                    staticClass: "billing-item",
                    attrs: { info: info },
                  },
                  [index !== _vm.subscriptions.length - 1 ? _c("hr") : _vm._e()]
                )
              }),
          _vm._v(" "),
          _c("divider"),
          _vm._v(" "),
          _c("h3", [_vm._v("App Store billing")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "If you have active subscriptions that you purchased from within the iHunter mobile app then the billing is\n            handled by the appropriate App Store. Use the links below to be taken to the appropriate billing portal:"
            ),
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Should you wish to move your subscription payments to the iHunter Store instead, it's as simple as waiting\n            for your annual subscription date to come up and then purchase one here instead of letting it renew from the\n            App Store."
            ),
          ]),
          _vm._v(" "),
          _vm._m(1),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "a",
        {
          attrs: {
            href: "https://support.apple.com/en-ca/HT204088",
            target: "blank",
          },
        },
        [_c("img", { staticClass: "platform-icon apple" })]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: {
            href: "https://support.google.com/googleplay/answer/2850369?hl=en",
            target: "blank",
          },
        },
        [_c("img", { staticClass: "platform-icon android" })]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("b", [_vm._v("Note:")]),
      _vm._v(
        " You will have paid for a full year when you subscribed so be sure to make note of your renewal\n            date."
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }