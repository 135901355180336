<script>
    import { Component, Vue } from 'vue-property-decorator';
    import Settings from './UserSettings.vue';

    import StoreAnalytics from './StoreAnalytics';

    @Component
    export default class Cart extends Vue {

        cart = {}
        promo = '';

        created() {
            this.load();

            this.analytics = new StoreAnalytics();
        }
        
        onPurchasesChanged(purchases) {
            for(let sku of Object.keys(purchases)){
                // console.log(`Checking cart for '${sku}' purchase`);
                if(this.cart[sku]) {
                    console.log(`Cart item already purchased; removing '${sku}' from cart`);
                    this.remove(sku);
                }
            }
        }

        load() {
            if(Settings.cart && typeof Settings.cart === 'object') {
                this.cart = Settings.cart;
            }

            if(Settings.promo && typeof Settings.promo === 'string') {
                this.promo = Settings.promo;
            }
        }

        save() {
            Settings.cart = this.cart;
            Settings.promo = this.promo;
        }

        contains(sku) {
            let count = this.quantity(sku);
            return count && count > 0;
        }

        quantity(sku) {
            return this.cart[sku];
        }

        buy(sku) {
            console.log(`Cart buy item: '${sku}'`)
            this.update(sku, 1);
            this.analytics.reportAddToCart(sku, 1);
        }

        update(sku, quantity) {
            if(!sku || sku === undefined) {
                console.warn(`Unable to update cart; invalid product '${sku}': ${quantity}`)
            }
            console.log(`Cart update '${sku}': ${quantity}`)
            //this.cart[sku] = quantity;
            this.$set(this.cart, sku, quantity);
            this.save();
        }

        remove(sku) {
            this.$delete(this.cart, sku);

            this.analytics.reportRemoveFromCart(sku, 1);
        }

        clear() {
            this.cart = {};
        }

        isEmpty() {
            return this.cart == {};
        }
        
        get code() {
            return this.promo.toUpperCase();
        }

        get items() {
            return Object.keys(this.cart).map(sku => {
                return { 
                    sku: sku,
                    count: this.cart[sku]
                };
            });
        }

        set items(items) {
            if(!items) {
                return;
            }

            for(let item of items) {
                if(item.sku) {
                    this.cart[item.sku] = item.count;
                }
                
            }
        }

        print() {
            console.log('Shopping cart:');
            for(let i in this.cart) {
                let v = this.cart[i];
                console.log(' - ' + i + ': ' + v);
            }
        }

    }
  
</script>
