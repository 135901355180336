<style lang="scss" scoped>
    @import 'styles/common.scss';

    .sash {
        position: absolute;
        top: 0;
        right: 0;
        pointer-events: none;
        svg {
            fill: $general-orange;
            &.invert {
                fill: $mono-white;
            }
        }
    }
</style>

<template>
    <div class="sash">
        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 42 42" :class="{'invert': invert}">
            <defs>
                <clipPath id="kz5zdy8uja"><path d="M0 0h38a4 4 0 0 1 4 4v38H0V0z" style="fill:#d03f3f"/></clipPath>
            </defs>
            <g style="clip-path:url(#kz5zdy8uja)">
                <path transform="rotate(-45 -.775 -.13)" style="fill:#332e2e;opacity:.08" d="M0 0h12v60H0z"/>
                <path transform="rotate(-45 -2.883 -1.869)" d="M0 0h12v60H0z"/>
            </g>
        </svg>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    @Component
    export default class Sash extends Vue.extend({
        props: {
            small: {
                type: Boolean,
                default: false,
            },            
            invert: {
                type: Boolean,
                default: false,
            },
        },
    }) {

        get size() {
            return this.small ? 26 : 42;
        }
    }

    Vue.component('sash', Sash);
</script>

