<script>
import { Component, Vue } from 'vue-property-decorator';
import { reactive } from 'vue';

import Cart from './Cart.vue';
import ErrorManager from './ErrorManager.vue';
import PurchaseProvider from 'common/PurchaseProvider';
import CatalogFirebaseProvider from 'common/CatalogFirebaseProvider';
import CatalogPromotions from 'common/CatalogPromotions';
import StoreDataAccessLayer from './StoreDataAccessLayer';

@Component
class GlobalState extends Vue {
    // Defaults for reactive state
    catalog = {};
    purchases = {};
    products = {};
    prices = {};
    promotions = [];
    subscriptions = [];
    groups = {};
    
    cart = null;

    user = null;
    jwt = null;

    route = null;
    region = null;

    dal = null;
    catalogProvider = null;

    error = null;

    search = '';


    created() {
        console.log("Initializing global app state");

        this.cart = reactive(new Cart());
        this.error = reactive(new ErrorManager());

        this.dal = new StoreDataAccessLayer();// Load data access layer
    }

    authenticated(jwt, user) {
        if (!user) {
            this.jwt = null;
            this.user = null;
            return;
        }

        this.jwt = jwt;
        this.user = user;
        this.watchPurchases(this.uid);
        this.refreshSubscriptions(); // Don't await
    }

    watchCatalog() {
        this.catalogProvider = new CatalogFirebaseProvider('ihunter');

        this.catalogProvider.watchCatalog((region, catalog) => {
            console.log("Catalog loaded for ihunter " + region);
            this.$set(this.catalog, region, catalog);
            this.region = this.routeRegion(); // Region depends on catalog, so recalculate after update

            
            this.purchaseProvider?.updateProductCatalog(this.catalog[region], region);
        });

        this.catalogProvider.watchProducts((products) => {

            console.log("Products list loaded");
            for (let sku in products) {
                const product = products[sku];
                this.$set(this.products, sku, product);

                // Track group products by group ID
                if (product?.group && product?.rank) {
                    if (!this.groups[product?.group]) {
                        this.groups[product?.group] = [];
                    }

                    this.groups[product?.group].push({
                        sku,
                        ...product
                    });
                }
            }

            // Sort group products by rank
            for (const products of Object.values(this.groups)) {
                products.sort((a, b) => {
                    return a.rank < b.rank;
                });
            }
        });

        this.catalogProvider.watchPrices((prices) => {
            console.log("Prices list loaded");
            for (let sku in prices) {
                this.$set(this.prices, sku, prices[sku]);
            }
        });
    }

    watchPurchases(uid) {
        this.purchaseProvider = new PurchaseProvider();
        
        this.purchaseProvider.watch(uid, (purchases) => {
            for (let sku in purchases) {
                //console.debug(sku, purchases[sku]);
                this.$set(this.purchases, sku, purchases[sku]);
            }

            // Cart needs to remove any purchased items
            this.cart.onPurchasesChanged(this.purchases);
        });
    }


    watchPromotions() {
        this.catalogProvider.watchPromotions((promotions) => {
            console.log("Promotion list loaded");
            this.promotions = promotions;
            //this.database = JSON.parse(JSON.stringify(promotions)); // Clone object
        });
    }

    subscriptionBillingInfo(sku) {
        return this.subscriptions.find((subscription) => subscription.sku === sku);
    };

    async refreshSubscriptions() {
        try {
            let subscriptions = await this.dal.subscriptions();

            for (const subscription of subscriptions) {
                if (subscription.group) {
                    if (subscription.group[0] === subscription.sku) {
                        // Upgrade is top sku in group
                        subscription.upgrade = subscription.group[subscription.group.length - 1]; //getSubscription(subscription.group[subscription.group.length - 1]);
                    } else {
                        // Downgrade is first sku in group
                        subscription.downgrade = subscription.group[0]; //getSubscription(subscription.group[0]);
                    }
                }
            }
            return this.subscriptions = subscriptions;

        } catch (error) {
            this.error.notify(new Error(`Subscription refresh error (${error.message})`, error));
        }
    }

    routeChanged(route, args, params) {
        this.route = route;
        this.region = this.routeRegion();
    }

    routeRegion() {
        let id = this.route.split('/')[0].toLowerCase();
        if (!this.catalog[id]) {
            return null;
        }
        return this.catalog[id];
    }

    get isAuthenticated() {
        return this.user !== null;
    }

    get uid() {
        return this.user.uid;
    }

    get regionalPurchases() {
        let purchases = {};
        for (let sku in this.purchases) {
            const product = this.products[sku];
            if (!product) {
                continue;
            }
            if (!purchases[product?.region]) {
                purchases[product?.region] = [];
            }
            purchases[product?.region].push(this.productInfo(sku));
        }
        return purchases;
    }

    purchasableSubscriptionsByRegion(region) {
        // let subscriptions = this.catalog[region]?.subscriptions;
        // if (subscriptions) {
        //     return Object.values(subscriptions)[0];// Note: hardcoded first subscription product; may not be safe in the future
        // }
        return this.catalog[region]?.subscriptions;
    }

    purchasableLayerByRegion(region) {
        let layers = this.catalog[region]?.resources?.purchasable_layers?.layers;
        if (layers) {
            return Object.values(layers)[0];// Note: hardcoded first purchasable layer; may not be safe in the future
        }
        return null;
    }

    get purchasableMapLayer() {
        let layers = this.catalog[this.region?.id]?.resources?.purchasable_layers?.layers;
        if (layers) {
            return Object.values(layers)[0];// Note: hardcoded first purchasable layer; may not be safe in the future
        }
        return null;
    }

    get regionalMapProducts() {

        let products = {};
        let maps = this.purchasableMapLayer?.maps;
        if (!maps) {
            return products;
        }
        for (let i in maps) {
            const map = maps[i];
            let latest = null;
            for (let j in map?.products) {
                const product = map.products[j]
                if (!latest || product.version > latest.version) {
                    latest = product;
                }
            }

            if (latest && this.prices[latest.sku]) {
                products[latest.sku] = this.productInfo(latest.sku);
            }
        }
        return products;
    }

    get updates() {
        let updates = {};

        for (let product in this.purchases) {
            let info = this.productInfo(product);
            if (info?.update) {
                updates[info.update] = this.productInfo(info.update);
            }
        }

        return updates;
    }

    productInfo(sku) {
        try {
            const product = this.products[sku];
            if (!product) {
                return;
                //throw Error(`Missing product for '${sku}'`);
            }

            let region = product?.region;
            if (!region) {
                return;
                // throw Error(`Missing product region for '${sku}'`);
            }
            let info = {
                sku,
                region,
                name: '',
                //type: 'map' or 'subscription'
                //purchase: <-- Purchase value of this map purchase
                //update: <-- SKU of the latest version of this map
                //outdated: <-- SKU of the latest purchased version of this map
            };

            let purchase = this.purchases[sku];
            if (purchase) {
                info.purchase = purchase;
            }

            let price = this.prices[sku];
            if (price) {
                info.price = price;
            }

            // Map products must have these properties
            if (this.catalog[region] && product?.resource && product?.layer && product?.map) {
                // Map info
                let map = this.catalog[region]?.resources[product?.resource]?.layers[product?.layer]?.maps[product?.map];
                if (!map) {
                    throw Error(`Missing product map for '${sku}'`);
                }

                info.type = 'map';
                info.name = map?.name;
                info.version = product?.version;
                info.map = product?.map;


                let revisions = map?.products;
                if (revisions && Object.keys(revisions).length > 1) {
                    for (let revision in revisions) {
                        if (this.purchases[revision]) {
                            continue; // If we own it, its not a valid upgrade
                        }

                        // Add latest update revision to the product info structure
                        if (revisions[revision].version > product?.version && (!info.newest || revisions[revision].version > revisions[info.newest].version)) {
                            info.update = revision;
                        }
                    }
                }

                // If this product is latest and unpurchased, check purchases for outdated maps
                if (!info.update && !info.purchase) {
                    for (let purchase in this.purchases) {
                        const purchaseProduct = this.products[purchase];
                        if (!purchaseProduct) {
                            continue;
                        }
                        // Add the latest, out-of-date, map to the outdated property
                        if (purchaseProduct.map === product.map && purchaseProduct.region === product.region && purchaseProduct.version < product.version) {
                            // Only show the latest as update
                            if ((!info.outdated || purchaseProduct.version > this.products[info.outdated].version)) {
                                info.outdated = purchase;
                            }
                        }
                    }

                    if (info.outdated) {
                        info.alternate = info.price; // Original price
                        info.price = CatalogPromotions.ApplyUpgradeDiscount(info.price);
                    }
                }

            } else if (product?.subscription && this.catalog[region]?.subscriptions[product.subscription]) {
                let subscription = this.catalog[region]?.subscriptions[product.subscription];
                if (!subscription) {
                    // return;
                    throw Error(`Missing product subscription for '${sku}'`);
                }

                info.type = 'subscription';
                info.name = subscription.name;
                info.generic = subscription.generic;

            } else {
                // Might not have loaded fully or the product is missing required metadata/prices
                //throw Error(`Missing product properties for '${sku}'`);
                return info;
            }

            return info;

        } catch (error) {
            console.warn(error)
        }
    }

    get upgradeDiscount() {
        return CatalogPromotions.UpgradeDiscount();
    }

    get searchableProducts() {

        let products = {};
        for (let region in this.catalog) {

            let subs = this.purchasableSubscriptionsByRegion(region);
            for (let sub of Object.keys(subs)) {
                let subscription = subs[sub];
                products[subscription.sku_web] = this.productInfo(subscription.sku_web);
            }

            let maps = this.purchasableLayerByRegion(region)?.maps;
            if (!maps) {
                continue;
            }
            for (let i in maps) {
                const map = maps[i];
                let latest = null;
                for (let j in map?.products) {
                    const product = map.products[j]
                    if (!latest || product.version > latest.version) {
                        latest = product;
                    }
                }

                if (latest) {

                    products[latest.sku] = this.productInfo(latest.sku);
                }
            }
        }
        return products;
    }


    get today() {
        let now = new Date();
        return now.getFullYear() + '-' + ('0' + (now.getMonth() + 1)).slice(-2) + '-' + ('0' + now.getDate()).slice(-2);
    }

    isActivePromotion(promo) {
        if (!promo.active) {
            return false;
        }
        if (promo.start && promo.start > this.today) {
            return false;
        }
        if (promo.end && promo.end < this.today) {
            // console.log(promo.end);
            // console.log(this.today);
            return false;
        }

        return true;
    }

    get promoTagline() {
        if (this.promotions && this.promotions.length) {
            for (let promo of this.promotions) {
                if (this.isActivePromotion(promo) && promo.tagline) {
                    return promo.tagline;
                }
            }
        }
        return null;
    }

    get promoCode() {
        if (this.promotions && this.promotions.length) {
            for (let promo of this.promotions) {
                if (this.isActivePromotion(promo) && promo.code) {
                    return promo.code;
                }
            }
        }
        return null;
    }
}



const AppState = reactive(new GlobalState());
export { AppState, AppState as default };

</script>
