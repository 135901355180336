var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "textbox",
      class: { focus: _vm.focus, readonly: _vm.readonly, action: _vm.action },
      on: { click: _vm.onClick },
    },
    [
      _c("div", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))]),
      _vm._v(" "),
      _c("div", { staticClass: "input-block" }, [
        _vm.type === "checkbox" && !_vm.readonly && !_vm.action
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.input,
                  expression: "input",
                },
              ],
              attrs: {
                placeholder: _vm.placeholder,
                required: _vm.required,
                autocomplete: _vm.autocomplete,
                type: "checkbox",
              },
              domProps: {
                checked: Array.isArray(_vm.input)
                  ? _vm._i(_vm.input, null) > -1
                  : _vm.input,
              },
              on: {
                focus: _vm.onFocus,
                blur: _vm.onBlur,
                change: [
                  function ($event) {
                    var $$a = _vm.input,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.input = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.input = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.input = $$c
                    }
                  },
                  _vm.onChange,
                ],
              },
            })
          : _vm.type === "radio" && !_vm.readonly && !_vm.action
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.input,
                  expression: "input",
                },
              ],
              attrs: {
                placeholder: _vm.placeholder,
                required: _vm.required,
                autocomplete: _vm.autocomplete,
                type: "radio",
              },
              domProps: { checked: _vm._q(_vm.input, null) },
              on: {
                focus: _vm.onFocus,
                blur: _vm.onBlur,
                change: [
                  function ($event) {
                    _vm.input = null
                  },
                  _vm.onChange,
                ],
              },
            })
          : !_vm.readonly && !_vm.action
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.input,
                  expression: "input",
                },
              ],
              attrs: {
                placeholder: _vm.placeholder,
                required: _vm.required,
                autocomplete: _vm.autocomplete,
                type: _vm.type,
              },
              domProps: { value: _vm.input },
              on: {
                focus: _vm.onFocus,
                blur: _vm.onBlur,
                change: _vm.onChange,
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.input = $event.target.value
                },
              },
            })
          : _c("div", { staticClass: "mock" }, [_vm._v(_vm._s(_vm.input))]),
        _vm._v(" "),
        _vm.focus
          ? _c(
              "svg",
              {
                attrs: {
                  id: "clear",
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "24",
                  height: "24",
                  viewBox: "0 0 24 24",
                },
                on: {
                  click: _vm.onClear,
                  focus: function ($event) {
                    $event.preventDefault()
                    return _vm.preventFocus.apply(null, arguments)
                  },
                },
              },
              [
                _c("g", [
                  _c("path", {
                    staticStyle: { fill: "#aaa" },
                    attrs: {
                      d: "M135.833 824.167a7.667 7.667 0 1 1-7.667-7.667 7.675 7.675 0 0 1 7.667 7.667zm-6.724 0 2.717-2.717a.667.667 0 1 0-.943-.943l-2.717 2.717-2.717-2.717a.667.667 0 0 0-.943.943l2.717 2.717-2.717 2.717a.667.667 0 1 0 .943.943l2.717-2.717 2.717 2.717a.667.667 0 0 0 .943-.943z",
                      transform: "rotate(180 70.084 418.084)",
                    },
                  }),
                ]),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "line" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }