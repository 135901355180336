var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "app-icon",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "120",
        height: "120",
        viewBox: "0 0 120 120",
      },
    },
    [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "03a8u7vv1a",
                x1: ".5",
                x2: ".5",
                y2: "1",
                gradientUnits: "objectBoundingBox",
              },
            },
            [
              _c("stop", { attrs: { offset: "0" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#555" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "qxqdng8dla",
                x1: ".5",
                x2: ".5",
                y2: "1",
                gradientUnits: "objectBoundingBox",
              },
            },
            [
              _c("stop", { attrs: { offset: "0", "stop-color": "#ccc" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#ededed" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.dark
        ? _c("g", { attrs: { "data-name": "iHunter Web (dark)" } }, [
            _c("path", {
              staticStyle: { fill: "none" },
              attrs: { d: "M0 0h120v120H0z" },
            }),
            _vm._v(" "),
            _c("path", {
              staticStyle: { fill: "#332e2e" },
              attrs: {
                "data-name": "Base (radius)",
                d: "M2.813 0h44.062a2.813 2.813 0 0 1 0 5.625H2.813a2.813 2.813 0 0 1 0-5.625z",
                transform: "translate(35.156 114.375)",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticStyle: { fill: "#332e2e" },
              attrs: {
                "data-name": "Face (radius)",
                d: "M2.813 0h114.375A2.813 2.813 0 0 1 120 2.813V7.5a7.5 7.5 0 0 1-7.5 7.5H7.5A7.5 7.5 0 0 1 0 7.5V2.813A2.812 2.812 0 0 1 2.813 0z",
                transform: "translate(0 85.313)",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticStyle: { fill: "url(#03a8u7vv1a)" },
              attrs: {
                d: "M13.239 14.25a29.093 29.093 0 0 1-5.994 14.063h32.64a28.967 28.967 0 0 1-5.993-14.063",
                transform: "translate(36.435 86.062)",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticStyle: { fill: "#1d7cbf" },
              attrs: {
                "data-name": "Screen (radius)",
                d: "M9.375 0h101.25A9.375 9.375 0 0 1 120 9.375v67.5a2.813 2.813 0 0 1-2.812 2.813H2.813A2.813 2.813 0 0 1 0 76.875v-67.5A9.375 9.375 0 0 1 9.375 0z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticStyle: { fill: "#d6efff" },
              attrs: {
                d: "M136.593 241.2s-7.085-.131-8.628-2.734c-2.529-4.257-2.628-10.344-2.712-11.75-.031-.554-.11-1.682-.9-2.051s-1.481 1.4-1.522 1.977-.608 6.261-1.473 6.2c-3.247-.232-4.987-7.306-5.215-10.362-.125-1.669-.321-2.006-.877-2.163-.665-.189-.966 1.738-.986 2.371s.054 5.363-.71 5.455c-2.315.278-3.809-4.926-4.008-7.375-.064-.782-.3-1.88-.806-1.78-.907.179-1.4 2.217-1.474 4.049-.185 4.826 2.51 8.443 1.708 8.9-1.9 1.08-5.275-3.8-6.4-3.128s-.49 2.412.642 4.037 7.686 5.582 6.817 7.593c-.877.256-3.334-.516-3.649-.546-.946-.1-1.571.693-1.359 1.275a5.127 5.127 0 0 0 2.672 2.411c3.553 1.5 11.091.455 11.323 3.587-.464.638-2.615.673-3.492.678s-1.719.97-1.5 1.55c.391 1.078 1.877 1.2 2.4 1.256 2.124.231 4.429-1.213 4.509-.991s-.447 1.8.093 2.075a4.18 4.18 0 0 0 2.649-.649c.4-.457.389-3.224 2.177-3.672a14.392 14.392 0 0 1 2.581-.218v.033a1.933 1.933 0 0 0 2.181 1.387 2.063 2.063 0 0 0 1.254-1.933c.2-1.2 1.413-1.72 4.158-1.531 2.238.149.547-3.951.547-3.951z",
                transform: "translate(-88.667 -206.779)",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticStyle: { fill: "#d6efff" },
              attrs: {
                "data-name": "Antler",
                d: "M613.309 241.075s7.085-.131 8.628-2.734c2.53-4.257 2.628-10.344 2.712-11.75.031-.554.11-1.682.9-2.051s1.482 1.4 1.522 1.977.608 6.261 1.473 6.2c3.247-.232 4.987-7.306 5.215-10.362.125-1.669.321-2.006.877-2.163.665-.19.966 1.738.986 2.371s-.054 5.363.71 5.455c2.315.278 3.809-4.926 4.008-7.375.063-.782.3-1.88.806-1.78.907.179 1.4 2.217 1.474 4.049.185 4.826-2.51 8.443-1.708 8.9 1.9 1.08 5.275-3.8 6.4-3.128s.49 2.412-.642 4.037-7.686 5.582-6.817 7.593c.877.256 3.334-.516 3.649-.546.946-.1 1.571.693 1.359 1.275a5.127 5.127 0 0 1-2.672 2.411c-3.553 1.5-11.091.455-11.323 3.587.464.638 2.615.673 3.492.678s1.719.97 1.5 1.55c-.391 1.078-1.877 1.2-2.4 1.256-2.124.231-4.429-1.213-4.51-.991s.447 1.8-.093 2.075a4.18 4.18 0 0 1-2.649-.649c-.4-.457-.389-3.224-2.177-3.672a14.394 14.394 0 0 0-2.581-.218v.033a1.933 1.933 0 0 1-2.181 1.387 2.063 2.063 0 0 1-1.254-1.933c-.2-1.2-1.413-1.72-4.158-1.531-2.236.153-.546-3.951-.546-3.951z",
                transform: "translate(-541.237 -206.67)",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticStyle: { fill: "#fff" },
              attrs: {
                d: "M395.692 359.814c2.563-12.719 14.938-20.609 14.938-31.217 0-7.255-6.688-13.137-14.938-13.137s-14.938 5.881-14.938 13.137c0 10.608 12.375 18.503 14.938 31.217z",
                transform: "translate(-335.693 -292.277)",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticStyle: { fill: "#555" },
              attrs: {
                d: "M25.781 6.563a.937.937 0 1 1 .937.937.938.938 0 0 1-.937-.937zm-4.687 0a.937.937 0 1 1 .937.937.938.938 0 0 1-.937-.937zm-4.687 0a.937.937 0 1 1 .937.937.938.938 0 0 1-.938-.937zm-4.687 0a.937.937 0 1 1 .937.937.938.938 0 0 1-.938-.937zm-4.687 0a.937.937 0 1 1 .937.937.937.937 0 0 1-.939-.937zm-4.688 0a.938.938 0 1 1 .938.937.938.938 0 0 1-.939-.937zm25.78-2.813a.937.937 0 1 1 .937.937.937.937 0 0 1-.937-.937zm-4.688 0a.938.938 0 1 1 .938.937.938.938 0 0 1-.938-.937zm-4.687 0a.938.938 0 1 1 .938.937.938.938 0 0 1-.938-.937zm-4.687 0a.938.938 0 1 1 .937.937.938.938 0 0 1-.938-.937zm-4.687 0a.938.938 0 1 1 .937.937.938.938 0 0 1-.938-.937zm-4.687 0a.937.937 0 1 1 .937.937.938.938 0 0 1-.939-.937zM0 3.75a.937.937 0 1 1 .937.937A.938.938 0 0 1 0 3.75zM25.781.937a.937.937 0 1 1 .937.937.938.938 0 0 1-.937-.937zm-4.687 0a.937.937 0 1 1 .937.937.938.938 0 0 1-.937-.937zm-4.687 0a.937.937 0 1 1 .937.937.938.938 0 0 1-.938-.937zm-4.687 0a.937.937 0 1 1 .937.937.938.938 0 0 1-.938-.937zm-4.687 0a.937.937 0 1 1 .937.937.937.937 0 0 1-.939-.937zm-4.688 0a.938.938 0 1 1 .938.937.938.938 0 0 1-.939-.937z",
                transform: "translate(45 89.063)",
              },
            }),
          ])
        : _c("g", { attrs: { "data-name": "iHunter Web (light)" } }, [
            _c("path", {
              staticStyle: { fill: "none" },
              attrs: { d: "M0 0h120v120H0z" },
            }),
            _vm._v(" "),
            _c("path", {
              staticStyle: { fill: "#fff" },
              attrs: {
                "data-name": "Base (radius)",
                d: "M2.813 0h44.062a2.813 2.813 0 0 1 0 5.625H2.813a2.813 2.813 0 0 1 0-5.625z",
                transform: "translate(35.156 114.375)",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticStyle: { fill: "#fff" },
              attrs: {
                "data-name": "Face (radius)",
                d: "M2.813 0h114.375A2.813 2.813 0 0 1 120 2.813V7.5a7.5 7.5 0 0 1-7.5 7.5H7.5A7.5 7.5 0 0 1 0 7.5V2.813A2.812 2.812 0 0 1 2.813 0z",
                transform: "translate(0 85.313)",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticStyle: { fill: "url(#qxqdng8dla)" },
              attrs: {
                d: "M13.239 14.25a29.093 29.093 0 0 1-5.994 14.063h32.64a28.967 28.967 0 0 1-5.993-14.063",
                transform: "translate(36.435 86.062)",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticStyle: { fill: "#1d7cbf" },
              attrs: {
                "data-name": "Screen (radius)",
                d: "M9.375 0h101.25A9.375 9.375 0 0 1 120 9.375v67.5a2.813 2.813 0 0 1-2.812 2.813H2.813A2.813 2.813 0 0 1 0 76.875v-67.5A9.375 9.375 0 0 1 9.375 0z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticStyle: { fill: "#d6efff" },
              attrs: {
                d: "M136.593 241.2s-7.085-.131-8.628-2.734c-2.529-4.257-2.628-10.344-2.712-11.75-.031-.554-.11-1.682-.9-2.051s-1.481 1.4-1.522 1.977-.608 6.261-1.473 6.2c-3.247-.232-4.987-7.306-5.215-10.362-.125-1.669-.321-2.006-.877-2.163-.665-.189-.966 1.738-.986 2.371s.054 5.363-.71 5.455c-2.315.278-3.809-4.926-4.008-7.375-.064-.782-.3-1.88-.806-1.78-.907.179-1.4 2.217-1.474 4.049-.185 4.826 2.51 8.443 1.708 8.9-1.9 1.08-5.275-3.8-6.4-3.128s-.49 2.412.642 4.037 7.686 5.582 6.817 7.593c-.877.256-3.334-.516-3.649-.546-.946-.1-1.571.693-1.359 1.275a5.127 5.127 0 0 0 2.672 2.411c3.553 1.5 11.091.455 11.323 3.587-.464.638-2.615.673-3.492.678s-1.719.97-1.5 1.55c.391 1.078 1.877 1.2 2.4 1.256 2.124.231 4.429-1.213 4.509-.991s-.447 1.8.093 2.075a4.18 4.18 0 0 0 2.649-.649c.4-.457.389-3.224 2.177-3.672a14.392 14.392 0 0 1 2.581-.218v.033a1.933 1.933 0 0 0 2.181 1.387 2.063 2.063 0 0 0 1.254-1.933c.2-1.2 1.413-1.72 4.158-1.531 2.238.149.547-3.951.547-3.951z",
                transform: "translate(-88.667 -206.779)",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticStyle: { fill: "#d6efff" },
              attrs: {
                "data-name": "Antler",
                d: "M613.309 241.075s7.085-.131 8.628-2.734c2.53-4.257 2.628-10.344 2.712-11.75.031-.554.11-1.682.9-2.051s1.482 1.4 1.522 1.977.608 6.261 1.473 6.2c3.247-.232 4.987-7.306 5.215-10.362.125-1.669.321-2.006.877-2.163.665-.19.966 1.738.986 2.371s-.054 5.363.71 5.455c2.315.278 3.809-4.926 4.008-7.375.063-.782.3-1.88.806-1.78.907.179 1.4 2.217 1.474 4.049.185 4.826-2.51 8.443-1.708 8.9 1.9 1.08 5.275-3.8 6.4-3.128s.49 2.412-.642 4.037-7.686 5.582-6.817 7.593c.877.256 3.334-.516 3.649-.546.946-.1 1.571.693 1.359 1.275a5.127 5.127 0 0 1-2.672 2.411c-3.553 1.5-11.091.455-11.323 3.587.464.638 2.615.673 3.492.678s1.719.97 1.5 1.55c-.391 1.078-1.877 1.2-2.4 1.256-2.124.231-4.429-1.213-4.51-.991s.447 1.8-.093 2.075a4.18 4.18 0 0 1-2.649-.649c-.4-.457-.389-3.224-2.177-3.672a14.394 14.394 0 0 0-2.581-.218v.033a1.933 1.933 0 0 1-2.181 1.387 2.063 2.063 0 0 1-1.254-1.933c-.2-1.2-1.413-1.72-4.158-1.531-2.236.153-.546-3.951-.546-3.951z",
                transform: "translate(-541.237 -206.67)",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticStyle: { fill: "#fff" },
              attrs: {
                d: "M395.692 359.814c2.563-12.719 14.938-20.609 14.938-31.217 0-7.255-6.688-13.137-14.938-13.137s-14.938 5.881-14.938 13.137c0 10.608 12.375 18.503 14.938 31.217z",
                transform: "translate(-335.693 -292.277)",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticStyle: { fill: "#f9f9f9" },
              attrs: {
                d: "M25.781 6.563a.937.937 0 1 1 .937.937.938.938 0 0 1-.937-.937zm-4.687 0a.937.937 0 1 1 .937.937.938.938 0 0 1-.937-.937zm-4.687 0a.937.937 0 1 1 .937.937.938.938 0 0 1-.938-.937zm-4.687 0a.937.937 0 1 1 .937.937.938.938 0 0 1-.938-.937zm-4.687 0a.937.937 0 1 1 .937.937.937.937 0 0 1-.939-.937zm-4.688 0a.938.938 0 1 1 .938.937.938.938 0 0 1-.939-.937zm25.78-2.813a.937.937 0 1 1 .937.937.937.937 0 0 1-.937-.937zm-4.688 0a.938.938 0 1 1 .938.937.938.938 0 0 1-.938-.937zm-4.687 0a.938.938 0 1 1 .938.937.938.938 0 0 1-.938-.937zm-4.687 0a.938.938 0 1 1 .937.937.938.938 0 0 1-.938-.937zm-4.687 0a.938.938 0 1 1 .937.937.938.938 0 0 1-.938-.937zm-4.687 0a.937.937 0 1 1 .937.937.938.938 0 0 1-.939-.937zM0 3.75a.937.937 0 1 1 .937.937A.938.938 0 0 1 0 3.75zM25.781.937a.937.937 0 1 1 .937.937.938.938 0 0 1-.937-.937zm-4.687 0a.937.937 0 1 1 .937.937.938.938 0 0 1-.937-.937zm-4.687 0a.937.937 0 1 1 .937.937.938.938 0 0 1-.938-.937zm-4.687 0a.937.937 0 1 1 .937.937.938.938 0 0 1-.938-.937zm-4.687 0a.937.937 0 1 1 .937.937.937.937 0 0 1-.939-.937zm-4.688 0a.938.938 0 1 1 .938.937.938.938 0 0 1-.939-.937z",
                transform: "translate(45 89.063)",
              },
            }),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }