var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "header-tab",
      on: {
        click: _vm.click,
        mouseover: function ($event) {
          _vm.hover = true
        },
        mouseleave: function ($event) {
          _vm.hover = false
        },
      },
    },
    [
      _c("div", { staticClass: "tab-item" }, [
        _c(
          "div",
          { staticClass: "section" },
          [
            _c("bag-pinkle", { attrs: { type: "header" } }),
            _vm._v(" "),
            _c("div", { staticClass: "underline" }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.label
          ? _c("span", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("word-logo", [
        _c("span", { staticClass: "logo-label" }, [_vm._v("Store")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }