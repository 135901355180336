var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.notifications.length
    ? _c(
        "div",
        { staticClass: "notification" },
        _vm._l(_vm.notifications, function (message, i) {
          return _c("div", { key: i, domProps: { innerHTML: _vm._s(message) } })
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }