var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { staticClass: "modal", attrs: { opacity: 0.4 } }, [
    _c(
      "form",
      {
        attrs: { id: "form" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.onAction.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "dialog-box" },
          [
            _vm.title
              ? _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
              : _c("div", [_vm._t("title")], 2),
            _vm._v(" "),
            _vm.details
              ? _c("div", { staticClass: "details" }, [
                  _c("p", [_vm._v(_vm._s(_vm.details))]),
                ])
              : _c("div", [_vm._t("details")], 2),
            _vm._v(" "),
            _vm._t("default"),
            _vm._v(" "),
            _vm._t("footer", function () {
              return [
                _c("div", { staticClass: "button-row" }, [
                  _vm.cancel
                    ? _c(
                        "button",
                        {
                          staticClass: "cancel alternate",
                          attrs: { type: "button" },
                          on: { click: _vm.onCancel },
                        },
                        [_vm._v(_vm._s(_vm.cancel))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.action
                    ? _c(
                        "button",
                        {
                          staticClass: "action",
                          class: { secondary: _vm.warning },
                          attrs: { type: "submit", disabled: _vm.diabled },
                        },
                        [_vm._v(_vm._s(_vm.action))]
                      )
                    : _vm._e(),
                ]),
              ]
            }),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }