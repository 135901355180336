<style lang="scss" scoped>
    @import 'styles/common.scss';

    .account {
        display: flex;
        flex-direction: column;
        background-color: $mono-black;
        user-select: none;
        height: 100vh;
        
        
        header {
            display: flex;
            flex-direction: row;

            .info {
                display: flex;
                flex-direction: column;
                margin: $gap 0;

                .name {
                    color: $mono-white;
                    font-size: 16px;
                }

                .email {
                    color: $mono-light;
                    font-size: 11px;
                }
            }

            .profile {
                width: $xsmall-size;
                height: $xsmall-size;
                border-radius: 50%;
                margin: $gap;
            }
        }

        main {
            color: $mono-white;
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            align-items: flex-start;

            ul {
                display: flex;
                flex-direction: column;
                margin: $space;
                //padding: $space 0;
                border-radius: 4px;
                background-color: $brand-i-h-black;
            }

            li {
                //margin: $space 0 $space $space;
            }

            ul > li + li {
                //margin-top: $notch;
                border-top: 1px solid $mono-black;
                border-radius: 0.5px;
            }

            .sign-out {
                display: grid;
                padding: $icon-size;
                width: 100%;
                a {
                    &.arrow {
                        justify-self: right;
                        color: $mono-dark;
                    }
                }
            }


        }
    }

</style>

<template>

    <div class="account">
        <error-bar></error-bar>
        <notification-bar></notification-bar>
        
        <header>
            <profile-icon class="profile dark" :icon="photo"></profile-icon>
            <!-- <img class="profile" :src="photo" referrerpolicy="no-referrer"> -->
            <div class="info">
                <div class="name">{{name}}</div>
                <div class="email">{{email}}</div>
            </div>
        </header>
        <main class="content">
            <ul class="list">
                <li class="list-item" @click="navigate('updates')">
                    <badge-menu-item title="Map updates" :count="count">New versions available.</badge-menu-item>
                </li>
            </ul>
            <ul class="list">
                <li class="list-item" @click="navigate('purchases')">
                    <menu-item title="My purchases">View your purchase history.</menu-item>
                </li>
                <li class="list-item" @click="navigate('support')">
                    <menu-item title="Help and support">View help resources or contact us.</menu-item>
                </li>
                <li class="list-item" @click="navigate('billing')">
                     <menu-item title="Billing and subscriptions">Manage your billing information.</menu-item>
                </li>
                <li class="list-item" @click="navigate('account')">
                     <menu-item title="Account settings">Update your personal details.</menu-item>
                </li>
            </ul>
            <div class="sign-out">
                <a href="javascript:void(0)" @click="signOut" class="arrow">Sign out ▸</a>
            </div>
        </main>

        <landscape :dark="true"></landscape>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import Page from './Page.vue';

    @Component
    export default class AccountMenu extends Page {

        get count() {
            return Object.keys(this.state.updates).length;
        }

        get name() {
            return this.user?.displayName;
        }


        get email() {
            return this.user?.email;
        }

        get photo() {
            return this.user?.photoURL;
        }

    }

    Vue.component('account-menu', AccountMenu);

</script>

