<style lang="scss" scoped>
    @import 'styles/common.scss';

    .platform-icon {
        display: inline-flex;
        flex-direction: row;
        align-self: center;
        height: $icon-size;

        svg {
            fill: $mono-dark;
        }


        img {
            padding: 3px;
            height: $icon-size;
        }

    
        .apple {
            content:url("../assets/platform-icon-apple.svg");
            margin-right: 2px;
        }

        .android {
            content:url("../assets/platform-icon-android.svg");
            margin-right: 2px;
        }

        .web {
            content:url("../assets/platform-icon-store.svg");
            margin-right: -2px;
        }
    }



</style>

<template>

    <div class="platform-icon">
        <img v-for="platform in platforms" :key="platform" :class="platform">
    </div>


</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    @Component
    export default class PlatformIcon extends Vue.extend({
        props: {
            purchase: {
                type: Object,
                default: {}
            }
        }
    }) {

        get platforms() {
            return Object.keys(this.purchase);
        }

        get primary() {
            return this.platforms[0];
        }

    }

    Vue.component('platform-icon', PlatformIcon);

</script>

