<style lang="scss" src="./HeaderTab.vue" scoped></style>

<style lang="scss" scoped>
    @import 'styles/common.scss';

    .header-tab {
        flex-grow: 1;

        @include above($searchbar-breakpoint)  {
            flex-direction: row;

            .tab-item {
                display: none;
            }
        }

        .search-container {
            position: absolute;
            background: $mono-black;
            pointer-events: none;

            top: $header-height;
            left: 0;

            width: 100%;
            height: $half;

            
            display: flex;
            align-items: center;
            justify-content: center;

            &.hidden {
                // Only hide when in small mode
                @include below($searchbar-breakpoint)  {
                    display: none;
                }
            }


            @include above($searchbar-breakpoint)  {
                position: relative;
                background: none;

                top: 0;
                right: 3 * $single;

                @include dynamic-padding-right;
            }


            .search {
                position: relative;
                width: 100%;
                max-width: $triple + (2 * $gap);
                margin: auto $icon-size;
                border-radius: 28px;
                border: solid 8px $mono-white;
                background-color: $mono-white;
                

                input {
                    width: 100%;
                    height: 28px;

                    padding-left: $icon-size;
                    //padding-right: $space;

                    border-radius: 20px;
                    pointer-events: all;
                    
                    background-color: $mono-white;

                    border: solid 2px $mono-white; 
                    //outline: solid 8px $mono-grey;
                    outline: none;

                    //box-shadow:0 0 1px 1px $mono-white;

                    &:focus {
                        border: solid 2px $general-orange;
                        
                    }
                }


                .search-icon {
                    position: absolute;
                    top: $notch + 2px;
                    left: $notch + 2px;;
                    margin: auto
                }

                button {
                    display: none;
                }
            }
            
        }


    }


</style> 

<template>
    <div class="header-tab" :class="{active}" @click="click" @mouseover="hover = true" @mouseleave="hover = false">
        <div :class="{hidden: !active}" class="search-container" @click.stop>
            <div class="search">

                <svg v-if="focus" class="search-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <path d="M5.869 9.202a3.333 3.333 0 1 0-3.333-3.333 3.333 3.333 0 0 0 3.333 3.333z"/>
                    <path d="m15.138 14.196-5.1-5.1a.647.647 0 0 0-.063-.042 5.216 5.216 0 1 0-.921.921.65.65 0 0 0 .042.063l5.1 5.1a.667.667 0 1 0 .943-.943zm-13.8-8.327a4.536 4.536 0 1 1 4.536 4.535 4.541 4.541 0 0 1-4.54-4.537z"/>
                </svg>

                <svg v-else class="search-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <path d="m55.471 230.529-5.1-5.1a.647.647 0 0 0-.063-.042 5.216 5.216 0 1 0-.921.921.65.65 0 0 0 .042.063l5.1 5.1a.667.667 0 1 0 .943-.943zm-13.8-8.327a4.536 4.536 0 1 1 4.536 4.535 4.541 4.541 0 0 1-4.54-4.537z" transform="translate(-40.333 -216.333)" style="fill:#332e2e"/>
                </svg>

                <form @submit.prevent="submit">
                    <input type="search" :placeholder="placeholder" required v-model="state.search" @focus="focus = true" @blur="focus = false">
                    <button type="submit"></button>
                </form>
            </div>
        </div>

        <div class="tab-item">
            <div class="close"></div>
            <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path v-if="!hover && !active" d="m62.707 237.293-7.65-7.65a.97.97 0 0 0-.095-.063 7.824 7.824 0 1 0-1.382 1.382.975.975 0 0 0 .063.095l7.65 7.65a1 1 0 0 0 1.414-1.414zM42 224.8a6.8 6.8 0 1 1 6.8 6.8 6.811 6.811 0 0 1-6.8-6.8z" transform="translate(-40 -216)" style="fill:#fff"/>
                <path v-else d="M144.8 229.8a5 5 0 1 0-5-5 5 5 0 0 0 5 5zm13.9 7.49-7.65-7.65a.977.977 0 0 0-.1-.063 7.824 7.824 0 1 0-1.382 1.382.977.977 0 0 0 .063.1l7.65 7.65a1 1 0 0 0 1.414-1.414zM138 224.8a6.8 6.8 0 1 1 6.8 6.8 6.811 6.811 0 0 1-6.8-6.8z" transform="translate(-136 -216)" style="fill:#fff"/>
            </svg>

            <div class="underline" :class="{active}"></div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import HeaderTab from './HeaderTab.vue';


    @Component
    export default class HeaderSearchTab extends HeaderTab {
        hover = false;
        focus = false;

        get placeholder() {
            return this.label + '...';
        }

        submit() {
            // console.log("searching for: " + this.state.search);
            this.navigate('search', this.state.search);
        }
    }

    Vue.component('header-search-tab', HeaderSearchTab);

</script>

