<style lang="scss" scoped>
    @import 'styles/common.scss';


</style>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M12 20.932a.54.54 0 0 1-.3-.092L.243 13.2a.545.545 0 1 1 .6-.908L12 19.731 23.152 12.3a.546.546 0 0 1 .6.908L12.3 20.84a.54.54 0 0 1-.3.092M12 24a.54.54 0 0 0 .3-.092l11.455-7.636a.546.546 0 0 0-.6-.908L12 22.8.847 15.365a.545.545 0 0 0-.6.908L11.7 23.908A.54.54 0 0 0 12 24" style="fill:#332e2e"/>
        <path :style="`fill: ${fill}`" d="M24 8.178a.544.544 0 0 0-.243-.45L12.3.091a.546.546 0 0 0-.6 0L.242 7.728A.541.541 0 0 0 0 8.175v1.008a.543.543 0 0 0 .242.453L11.7 17.272a.545.545 0 0 0 .606 0l11.45-7.636A.545.545 0 0 0 24 9.193V8.178" transform="translate(0 .001)"/>
        <path d="M24 8.178c0-.014-.009-.027-.01-.041a.531.531 0 0 1-.081.346.537.537 0 0 1-.152.152L12.3 16.271a.545.545 0 0 1-.3.092.555.555 0 0 1-.3-.091L.242 8.635a.535.535 0 0 1-.232-.492c0 .011-.01.021-.01.032v1.008a.543.543 0 0 0 .242.453L11.7 17.272a.545.545 0 0 0 .606 0l11.45-7.636A.545.545 0 0 0 24 9.193V8.178" style="opacity:.32;fill:#332e2e"/>
    
        <path v-if="map" d="M76 16.364a.54.54 0 0 0 .3-.092l3.328-2.22a33.073 33.073 0 0 0-1.795-.771v-1.814s.844-.318.844-2.27c.642 0 .882-1.815-.007-1.815a3.949 3.949 0 0 0 .411-2.27c-.459-1.816-4.982-1.816-5.44 0-1.927-.39-.395 2.007-.395 2.27-.917 0-.917 1.815 0 1.815 0 1.952.918 2.27.918 2.27v1.814c-.608.236-1.2.5-1.8.773l3.336 2.218a.545.545 0 0 0 .3.092" transform="translate(-64 .001)" style="fill:#fff"/>
        <path v-if="subscription" d="M135 8.5c1.011.833 2.921 1.562 2.135 2.917 0 0 .674-.1 2.247-.313.224 0 .45 0 .45.208a16.492 16.492 0 0 1-.113 2.188h.449a16.476 16.476 0 0 1-.112-2.187c0-.313.224-.209.45-.209 1.572.209 2.247.313 2.247.313-.787-1.355 1.236-2.083 2.247-2.813-.787-.208-.674-.208-.225-1.875a5.085 5.085 0 0 1-1.226.29c-.385 0-.4-.251-.684-.706 0 0-.112.1-1.123 1.25a.177.177 0 0 1-.116.04c-.137 0-.306-.092-.221-.249l.3-1.347c.259-1.181.262-1.257.262-1.257a5.349 5.349 0 0 1-.9.507c-.14.04-.168-.038-.224-.194-.562-1.042-.9-1.563-.9-1.563a12.363 12.363 0 0 1-.9 1.563c-.113.312-.113.312-1.124-.313 0 0 0 .1.562 2.6 0 .1-.224.208-.337.1a9.865 9.865 0 0 1-1.123-1.25c-.335.73-.223.938-1.909.417.562 1.77.675 1.667-.112 1.875" transform="translate(-128 .001)" style="fill:#fff"/>
    </svg>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    @Component
    export default class LayerIcon extends Vue.extend({
        props: {
            fill: {
                type: String,
                default: 'black'
            },
            type: {
                type: String, 
                default: 'layer'
            }
            
        }
    }) {

        get subscription() {
            return this.type === "subscription"
        }

        get map() {
            return this.type === "map"
        }

    }

    Vue.component('layer-icon', LayerIcon);

</script>

