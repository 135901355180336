<style lang="scss" scoped>
    @import 'styles/common.scss';

    .close {
        text-transform: uppercase;
        color: $mono-white;
        font-weight: bold;
        display: inline-flex;
        flex-direction: row;
        height: max-content;


        cursor: pointer;

        .label {
            font-size: 12px;
            
            margin: auto $space;

        }

        .x {
            font-size: 18px;
        }

    }

</style>

<template>
    <div class="close" @click="click">
        <span class="label">close</span>
        <span class="x">X</span>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    @Component
    export default class Close extends Vue {

        click() {
            this.$emit('click');
        }

    }

    Vue.component('close', Close);

</script>

