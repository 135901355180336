import templ8r from '../libs/templ8r';

const PURCHASE = {
    APPLE:              '/purchases_ios/{uid}',
    ANDROID:            '/purchases_android/{uid}',
    WEB:                '/purchases_web/{uid}',

    SKU:                '/{region}/{sku}'
};

export default class PurchaseResources {
    static Apple(uid) {
        return templ8r(PURCHASE.APPLE, {uid});
    }
    static Android(uid) {
        return templ8r(PURCHASE.ANDROID, {uid});
    }
    static Web(uid) {
        return templ8r(PURCHASE.WEB, {uid});
    }
    static WebPurchase(uid, region, sku) {
        return templ8r(PURCHASE.WEB, {uid}) + templ8r(PURCHASE.SKU, {region, sku});
    }
}
