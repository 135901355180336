var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "divider" }, [
    _c("div", { staticClass: "line" }),
    _vm._v(" "),
    _c(
      "svg",
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "40",
          height: "16",
          viewBox: "0 0 40 16",
        },
      },
      [
        _c("g", [
          _c("g", [
            _c("path", {
              attrs: {
                d: "M112 225.4s-2.048-.038-2.494-.79a7.8 7.8 0 0 1-.784-3.4c-.009-.16-.032-.486-.261-.593s-.428.4-.44.572-.176 1.81-.426 1.792c-.939-.067-1.442-2.112-1.508-3-.036-.483-.093-.58-.254-.625-.192-.055-.279.5-.285.685s.016 1.55-.205 1.577c-.669.08-1.1-1.424-1.159-2.132-.018-.226-.088-.543-.233-.514-.262.052-.405.641-.426 1.17-.053 1.4.725 2.441.494 2.572-.548.312-1.525-1.1-1.85-.9s-.142.7.186 1.167 2.222 1.614 1.971 2.195a3.563 3.563 0 0 1-1.055-.158c-.273-.028-.454.2-.393.369a1.482 1.482 0 0 0 .773.7c1.027.433 3.206.132 3.273 1.037-.134.184-.756.195-1.009.2s-.5.28-.435.448c.113.312.543.346.694.363.614.067 1.28-.351 1.3-.286s-.129.521.027.6a1.208 1.208 0 0 0 .766-.188c.115-.132.112-.932.629-1.061a4.161 4.161 0 0 1 .746-.063v.009a.559.559 0 0 0 .63.4.6.6 0 0 0 .363-.559c.056-.346.408-.5 1.2-.443.654.045.165-1.144.165-1.144z",
                transform: "translate(-95.156 -218.976)",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                "data-name": "Antler/dark",
                d: "M119.665 347.926a.306.306 0 0 0 .135-.092c-.04-.535-.816-.648-1.656-.741a5.769 5.769 0 0 0 1.82-.062c.631-.2 1.281-.743 1.88-.868a3.139 3.139 0 0 0-.8.532c-.298.28-.493.925-1.379 1.231zM118.5 345.8a1.084 1.084 0 0 1-.576-.108c-.262-.131-.169-.363-.412-.6a12.786 12.786 0 0 1-2.2-1.3c.481.516 2.124 1.552 1.888 2.1a.733.733 0 0 1-.259.008 2.67 2.67 0 0 0 1.559-.1zm5.606 1.056s-.721-.031-.786.308c-.071.374-.082.626-.423.72s-.386-.357-.386-.357c0-.047.012-.1.024-.154-.521 0-1.043.713-1.043.713a.534.534 0 0 1 .283-.182 3.323 3.323 0 0 1 .518-.056h.224v.009a.559.559 0 0 0 .63.4.6.6 0 0 0 .363-.559c.042-.255.243-.4.662-.443z",
                transform: "translate(-108.029 -339.684)",
              },
            }),
          ]),
          _vm._v(" "),
          _c("g", [
            _c("path", {
              attrs: {
                "data-name": "Antler",
                d: "M612.874 225.28s2.048-.038 2.494-.79a7.8 7.8 0 0 0 .784-3.4c.009-.16.032-.486.261-.593s.428.4.44.572.176 1.81.426 1.792c.939-.067 1.441-2.112 1.508-3 .036-.483.093-.58.254-.625.192-.055.279.5.285.685s-.016 1.55.205 1.577c.669.08 1.1-1.424 1.159-2.132.018-.226.088-.543.233-.514.262.052.405.641.426 1.17.053 1.4-.725 2.441-.494 2.572.548.312 1.525-1.1 1.85-.9s.142.7-.186 1.167-2.222 1.614-1.971 2.195a3.563 3.563 0 0 0 1.055-.158c.273-.028.454.2.393.369a1.482 1.482 0 0 1-.773.7c-1.027.433-3.206.132-3.273 1.037.134.184.756.195 1.009.2s.5.28.435.448c-.113.312-.543.347-.694.363-.614.067-1.28-.351-1.3-.286s.129.521-.027.6a1.208 1.208 0 0 1-.766-.188c-.115-.132-.112-.932-.629-1.061a4.162 4.162 0 0 0-.746-.063v.009a.559.559 0 0 1-.63.4.6.6 0 0 1-.363-.559c-.056-.346-.409-.5-1.2-.443-.653.042-.165-1.144-.165-1.144z",
                transform: "translate(-589.05 -218.857)",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                "data-name": "Antler/dark",
                d: "M642.456 346.573a3.139 3.139 0 0 0-.8-.532c.6.125 1.248.669 1.88.868a5.769 5.769 0 0 0 1.82.062c-.841.093-1.617.206-1.656.741a.306.306 0 0 0 .135.092c-.893-.304-1.089-.952-1.379-1.231zm4.1-.8a.733.733 0 0 1-.259-.008c-.235-.544 1.408-1.58 1.888-2.1a12.782 12.782 0 0 1-2.2 1.3c-.243.239-.151.472-.412.6a1.085 1.085 0 0 1-.576.108 2.67 2.67 0 0 0 1.555.102zm-7.233 1.354c.419.039.62.188.661.443a.6.6 0 0 0 .363.559.559.559 0 0 0 .63-.4v-.009h.224a3.331 3.331 0 0 1 .518.056.534.534 0 0 1 .283.182s-.521-.713-1.043-.713c.011.056.019.108.024.154 0 0-.045.45-.386.357s-.352-.346-.423-.72c-.065-.338-.786-.308-.786-.308z",
                transform: "translate(-614.796 -339.565)",
              },
            }),
          ]),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M385.072 328.281c.741-3.677 4.318-5.957 4.318-9.024a4.354 4.354 0 0 0-8.636 0c0 3.067 3.577 5.343 4.318 9.024z",
              transform: "translate(-364.738 -312.281)",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "data-name": "Pin",
              d: "M385.072 328.281c.741-3.677 2.385-12.821 0-12.821a4.083 4.083 0 0 0-4.318 3.8c0 3.064 3.577 5.34 4.318 9.021z",
              transform: "translate(-364.738 -312.281)",
            },
          }),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "line" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }