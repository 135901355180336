<script>
    import { Component, Vue } from 'vue-property-decorator';

    import AppState from './AppState.vue';    

    @Component
    export default class ErrorManager extends Vue {

        notice = {}

        notify(error) {
            console.error(error);
            let msg = error.message;
            let key = Date.now();
            this.$set(this.notice, key, msg);   
        }

        dismiss(key) {
            this.$delete(this.notice, key);
        }

        get errors() {
            return Object.values(this.notice);
        }

        get found() {
            return this.errors.length > 0;
        }

        get state() {
            return AppState;
        }

    }
  
</script>
