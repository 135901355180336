<style lang="scss" scoped>
@import 'styles/common.scss';

.account {
    padding-top: $xsmall-size;

    @include icon-section;

    .setting-section {
        max-width: $max-1up;
        margin: $gap 0;

        .main {
            margin: $notch 0;
            padding: $notch 0;

            h3 {
                margin-top: $icon-size;
            }


            .preference {
                display: flex;
                justify-content: space-between;

                .toggle-switch {
                    margin: $gap 0;
                }
            }

            &.readonly {
                background-color: $mono-grey;
                cursor: default;
            }

            .setting {
                @include Text-11;
                color: $mono-dark;
                margin: 0 $space;
            }

            .value {
                @include Title-16;
                color: $mono-black;
                margin: 0 $space $space $space;
            }

            .line {
                display: flex;
                background-color: $mono-misc;
            }
        }



        .note {
            @include Text-13;
            color: $mono-dark;
            margin: $space $space;
        }



    }

    .edit-icon {
        display: none;
    }


    .issue-notice {
        margin: $space $space;
        font-size: 13px;

        .status-title {
            font-weight: bold;

            &.warning {
                color: $validation-bad-dark;
            }
        }
    }
}
</style>

<template>
    <div class="account">
        <profile-icon class="profile icon" :icon="photo" :editable="true" :trashable="profilePhotoExists" @edit="editProfilePhoto" @trash="trashProfilePhoto"></profile-icon>
        <input class="edit-icon" type="file" ref="file" @change="updateProfilePhoto" accept="image/png, image/jpeg"/>
        <dialog-box v-if="editing === 'delete-profile'" title="Remove my photo" warning
            details="Are you sure you want to remove your custom account photo?"
            @cancel="dismiss" @action="deleteProfilePhoto" action="Remove">
        </dialog-box>
        <dialog-box v-if="error" :title="error.title || 'Error'" warning
            :details="error.message" :cancel="null" action="Dismiss"
            @cancel="error=null" @action="error=null">
        </dialog-box>
        <div class="content">
            <div class="secondary-title">My account</div>
            <div class="title">Account settings</div>
            <chip />
            <p>Use this screen to make changes to your account details and communications settings. The changes will apply
                everywhere that you use iHunter.</p>
            <div class="setting-section">
                <textbox @click="editing = 'name'" label="Name" :action="true" :value="user?.displayName"></textbox>
                <input-dialog v-if="editing === 'name'" label="Name" title="Change account name" :required="true"
                    details="Enter the new name you would like to use. This name is publicly visible in the message contact search results within iHunter."
                    :value="user?.displayName" @cancel="dismiss" @action="updateDisplayName" autocomplete="name">
                </input-dialog>
                <div class="note">Your chosen name and photo will be publicly visible in the message contact search results.</div>
            </div>

            <div v-if="!emailProvider" class="setting-section">
                <div class="main readonly">
                    <div class="setting">Account email</div>
                    <div class="value">{{ user.email }}</div>
                </div>
                <div class="note">Your account email cannot be changed because you signed in using Apple, Google, or Facebook. To use a
                different email address you need to sign out and create a new iHunter Account by selecting <b>Sign in with
                email.</b></div>
            </div>
            <div v-if="emailProvider" class="setting-section">
                <textbox @click="editing = 'verify-password'" label="Account email" :action="true" :value="user.email"></textbox>
                <input-dialog v-if="editing === 'verify-password'" label="Password" title="Authentication" :required="true" type="password"
                    details="Please enter your iHunter Account password to make this change." action="Continue"
                    @cancel="dismiss" @action="verifyContactPassword" autocomplete="current-password" key="verify-password">
                </input-dialog>
                <input-dialog v-if="editing === 'update-email'" label="Account email" title="Change account email" :required="true" type="email"
                    details="Enter the new email address you would like to use. You will need to use this new address to sign-in going forward" action="Done"
                    @cancel="dismiss" @action="updateAccountEmail" autocomplete="email" key="update-email">
                </input-dialog>
            </div>
            <div v-if="emailProvider" class="setting-section">
                <textbox @click="editing = 'reset-password'" label="Password" :action="true" value="Reset my password..."></textbox>
                <dialog-box v-if="editing === 'reset-password'" label="Password" title="Reset account password" :required="true" type="password"
                    :details="`Would you like iHunter to send a password reset email to: ${user.email}?`"
                    @cancel="dismiss" @action="resetPassword" key="reset-password" action="Send">
                </dialog-box>
            </div>

            <div class="setting-section">   
                <div class="main">
                    <h3>Communications</h3>
                    <chip />
                    <p>Manage how iHunter gets in touch to send you the latest product news, promotions, discounts, and much more.</p>        

                
                    <div class="preference">
                        <p>Agree to receive iHunter emails:</p>
                        <toggle-switch id="optin" v-model="optin" @toggle="updateOptIn" ref="optin"></toggle-switch>
                    </div>                    
                </div>
                <textbox @click="editing = 'contact'" label="Contact email" :action="true" :value="email"></textbox>
                <input-dialog v-if="editing === 'contact'" label="Contact email" title="Change contact email" :required="true" type="email"
                    details="Change this address if you would like to use something other than your iHunter Account email."
                    :value="email || this.user.email" @cancel="cancelContactUpdate" @action="updateContactEmail" autocomplete="email" :placeholder="email || this.user.email">
                </input-dialog>
                <div class="note">Change this address if you would like to use something other than your iHunter Account email.</div>

                <div class="issue-notice" v-if="status ==='pending'">
                    <div class="status-title">Pending</div>
                    <div>Please click the link in the confirmation email to be added to the iHunter mailing list.</div>
                </div>                
                <div class="issue-notice" v-else-if="status ==='cleaned'">
                    <div class="status-title warning">Issue with email address</div>
                    <div>There was an issue sending emails to this address and it cannot be used. Please enter a different email address to be added to the iHunter mailing list.</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Component, Watch, Vue } from 'vue-property-decorator';

import Page from './Page.vue';
import MailingListProvider from 'common/MailingListProvider';
import ProfileProvider from 'common/ProfileProvider';

import firebase from 'firebase/compat/app';

const UNKNOWN_EMAIL = 'UnknownEmail@iHunter.com';

@Component
export default class AccountPage extends Page {
    editing = null;

    optin = false;
    email = null;
    status = null;
    error = null;

    photoUrl = null;

    created() {
        this.mailinglistProvider = new MailingListProvider(window.app.auth);
        this.profileProvider = new ProfileProvider(window.app.auth);
    }

    mounted() {
        if (!this.authenticated) {
            this.navigate('login', 'account');
        } else {
            this.watchPreferences(this.user.uid);
            this.updateProfilePhotoUrl();

        }
    }

    beforeDestroy() {
        if (this.mailinglistProvider?.unwatchPreferences) {
            this.mailinglistProvider.unwatchPreferences();
        }
    }

    watchPreferences(uid) {
        this.mailinglistProvider.watchPreferences(uid, (prefs) => {
            this.email = prefs?.email;
            this.optin = prefs?.optin || false;
            this.status = prefs?.status;
        });
    }

    async updateOptIn(optin) {
        try {
            if (optin !== null) {
                this.optin = optin;
            }
            await this.mailinglistProvider.setOptIn(this.optin);

            if (this.optin) {
                await this.mailinglistProvider.setOptIn(true);

                // Prompt for email if invalid
                this.updateEmailWhenInvalid(this.email);
            } else {
                await this.mailinglistProvider.setOptIn(null);

                this.updateContactEmail(null);
            }

        } catch (error) {
            this.error = error;
            this.error.title = "Unable to opt-in for communications";
        }
    }

    dismiss() {
        this.editing = null;
    }

    async updateDisplayName(name) {
        try {
            await this.profileProvider.updateUserProfile(name);

        } catch (error) {
            this.error = error;
            this.error.title = "Unable to update profile";

        } finally {
            this.dismiss();
        }
    }

    async updateAccountEmail(email) {
        try {
            await this.profileProvider.updateEmail(email);

        } catch (error) {
            this.error = error;
            this.error.title = "Unable to update email";

        } finally {
            this.dismiss();
        }
    }   

    async updateContactEmail(email) {
        try {
            if (this.optin) {
                // During opt-in, confirm email is valid
                if (!this.updateEmailWhenInvalid(email)) {
                    this.email = email;
                    await this.mailinglistProvider.setEmail(this.email);
                    //await this.mailinglistProvider.setOptIn(this.optin);
                }
            } else {
                this.email = email;
                await this.mailinglistProvider.setEmail(this.email);
            }

        } catch (error) {
            this.error = error;
            this.error.title = "Unable to contact email";

        } finally {
            this.dismiss();
        }
    }

    async cancelContactUpdate() {
        this.dismiss();

        // If they cancel and don't have a valid email, we need to opt-out the user
        if (this.isInvalidEmail(this.email)) {
            this.updateOptIn(false);
            return;
        }

        // Revert on cancel
        this.email = await this.mailinglistProvider.getEmail(); 
        this.optin = await this.mailinglistProvider.getOptIn();
    }

    isEmptyEmail(email) {
        return !email || email.trim() === "";
    }

    isInvalidEmail(email) {
        return this.isEmptyEmail(email) || email === UNKNOWN_EMAIL;
    }

    updateEmailWhenInvalid(email) {
        if (this.isInvalidEmail(email)) {
            this.editing = 'contact';
            return true;
        }

        return false;
    }

    async updateProfilePhotoUrl() {
        try {
            this.photoUrl = await this.profileProvider.getPhotoUrl();
        } catch {
            this.photoUrl = null;
        }
    }

    get profilePhotoExists() {
        return this.photoUrl !== null;
    }

    async resetPassword() {
        try {
            await this.profileProvider.sendPasswordResetEmail();

        } catch (error) {
            this.error = error;
            this.error.title = "Unable to reset password";

        } finally {
            this.dismiss();
        }
    }  


    async verifyContactPassword(password) {
        try {
            await this.profileProvider.reauthenticateWithCredential(password);

            // Check if password is valid
            this.editing = 'update-email';

        } catch (error) {
            this.error = error;
            this.error.title = "Unable to verify password";

        } finally {
            this.dismiss();
        }
    }  

    editProfilePhoto() {
        this.$refs.file.click();
    }

    async updateProfilePhoto(event) {
        let file = event?.target?.files[0];
        if(file) {
            await this.profileProvider.uploadProfilePicture(file, this.user.uid);
            this.updateProfilePhotoUrl();
        }
    }

    async trashProfilePhoto() {
        this.editing = 'delete-profile';
    }

    async deleteProfilePhoto() {
        try {
            await this.profileProvider.deleteProfilePicture(this.user.uid);
            this.updateProfilePhotoUrl();

        } catch (error) {
            this.error = error;
            this.error.title = "Unable to verify password";

        } finally {
            this.dismiss();
        }
    }  

    get emailProvider() {
        return this.authenticationProvider === firebase.auth.EmailAuthProvider.PROVIDER_ID;
    }
}

Vue.component('account-page', AccountPage);

</script>

