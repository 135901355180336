var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "results" }, [
    _c("header", [
      _vm.authenticated
        ? _c("img", {
            staticClass: "profile icon",
            attrs: { src: _vm.photo, referrerpolicy: "no-referrer" },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("div", { staticClass: "secondary-title" }, [
            _vm._v(
              _vm._s(!_vm.count ? "No" : _vm.count) +
                " product" +
                _vm._s(_vm.count !== 1 ? "s" : "") +
                " found"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "title" }, [_vm._v("Search results")]),
          _vm._v(" "),
          _c("chip"),
          _vm._v(" "),
          _vm.count
            ? _c("div", { staticClass: "section" }, [
                _vm.count < 2
                  ? _c(
                      "a",
                      {
                        staticClass: "arrow",
                        attrs: { href: "javascript:void(0)" },
                        on: { click: _vm.addAll },
                      },
                      [_c("cart-icon"), _vm._v(" Add product to cart")],
                      1
                    )
                  : _vm.count > 1
                  ? _c(
                      "a",
                      {
                        staticClass: "arrow",
                        attrs: { href: "javascript:void(0)" },
                        on: { click: _vm.addAll },
                      },
                      [_c("cart-icon"), _vm._v(" Add all products to cart")],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-grid" },
                  [_c("product-list", { attrs: { products: _vm.results } })],
                  1
                ),
              ])
            : _c("p", { staticClass: "details" }, [
                _vm._v(
                  "No product names found using the search term '" +
                    _vm._s(_vm.args) +
                    "'."
                ),
              ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }