var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.renewal
    ? _c("div", [
        _vm.future
          ? _c("div", [
              _vm._v("\n        Change date: "),
              _c("b", [_vm._v(_vm._s(_vm.toDate(_vm.renewal)))]),
            ])
          : _vm.cancelled
          ? _c("div", [
              _vm._v("\n        Expiry date: "),
              _c("b", [_vm._v(_vm._s(_vm.toDate(_vm.renewal)))]),
            ])
          : _c("div", [
              _vm._v("\n        Renewal date: "),
              _c("b", [_vm._v(_vm._s(_vm.toDate(_vm.renewal)))]),
            ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }