import templ8r from '../libs/templ8r';

const PROFILE = {
    PICTURE_STORAGE:       '/users/{uid}/UserProfilePic.jpg',
    PICTURE_DATE:          '/users/{uid}/profilePicDate',    // YYYY-MM-DD HH:mm:ss

};

export default class ProfileResources {
    static PictureStorage(uid) {
        return templ8r(PROFILE.PICTURE_STORAGE, {uid});
    }
    static PictureDate(uid) {
        return templ8r(PROFILE.PICTURE_DATE, {uid});
    }
}
