import FirebaseRepository from './FirebaseRepository';
import MailingListResources from './MailingListResources';

export default class MailingListProvider extends FirebaseRepository {

    constructor(auth) {
        super();

        this.auth = auth;

        this.unwatch = null;

        this.optin = false;
        this.email = null;
        this.status = null;
    }

    async watchPreferences(uid, notify, error) {
        console.log(`Watching mailing list preferences`);

        this.unwatch = await this.watch(MailingListResources.Preferences(uid), async (snapshot) => {

            let prefs = snapshot.val();
            if (prefs) {
                this.optin = prefs.optin;
                this.email = prefs.email;
                this.status = prefs.status;
                notify(prefs);
            }

        }, error);
        return this.unwatchPreferences;
    }

    unwatchPreferences() {
        if (this.unwatch) {
            this.unwatch();
            this.unwatch = null;
        }
    }

    async setOptIn(optin) {
        return await this.set(MailingListResources.OptIn(this.uid), optin);
    }

    async setEmail(email) {
        return await this.set(MailingListResources.Email(this.uid), email);
    }

    async getOptIn() {
        return await this.get(MailingListResources.OptIn(this.uid));
    }

    async getEmail() {
        return await this.get(MailingListResources.Email(this.uid));
    }

    get uid() {
        return this.auth.currentUser.uid;
    }
}
