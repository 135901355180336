<style lang="scss" scoped>
@import 'styles/common.scss';

.login-container {

    .auth {
        align-items: center;
    }

    .box {
        width: 350px;
        padding: 16px;
        background: $brand-i-h-black;
        display: flex;
        flex-direction: column;
        justify-content: start;

        .logo {
            margin-top: 45px;
            object-fit: none;
        }

        .profile {
            display: flex;
            flex-direction: row;

            margin-bottom: 8px;

            >img {
                flex-grow: 0;
                width: 68px;
                height: 68px;
                border-radius: 34px;
                margin-right: 8px;
            }

            span {
                color: $mono-dark;
            }

            h2 {
                margin-top: 8px;
            }
        }

        #auth-container {
            margin: 15px 0;

            button {
                border-radius: 5px;
                font-family: 'Lato', 'Trebuchet MS', sans-serif;
                width: 100%;
            }
        }

        p {
            font-size: 12px;
            color: $mono-misc;
            margin-block: 8px;
        }
    }

    .title {
        display: flex;
        flex-direction: column;

        span {
            position: relative;
            padding: 14px;
            font-size: 32px;
            font-weight: bold;
            text-align: center;
        }
    }

}

.sign-in-word-logo {
    margin-top: $space;
    transform-origin: center left;
    transform: scale(1.5);

    // @include between(small, medium) {
    //     transform: scale(2.5);
    // }

    // @include above(medium) {
    //     transform: scale(3.5);
    // }
}

.login-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
        'header'
        'login'
        'info';

    // @include between(small, medium)  {
    //     //gap: $icon-size;
    //     padding: $icon-size;
    // }

    @include above(medium) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            'header header'
            'login info';

        gap: $gap;
    }

    //max-width: $max-1up; // set minimum and let constrain-width override for larger viewports
    @include constrain-width(true);

    .header {
        grid-area: header;
        color: $mono-white;
        background: $mono-black;

        padding: $gap;

        display: flex;
        flex-direction: row;

        .header-content {
            @include icon-section;

            .icon {
                // min-width: 88px;
                // min-height: 88px;
                // height: auto;
                display: none;

                @include above(medium) {
                    display: initial;
                }
            }

            .chip {
                display: none;

                @include above(medium) {
                    display: block;
                }
            }

            .content {
                .title {
                    display: none;

                    @include above(medium) {
                        display: initial;
                    }
                }
            }
        }
    }

    .login {
        grid-area: login;
        background: $brand-i-h-black;
        color: $mono-white;

        position: sticky;
        top: 0;

        .auth-content {
            // margin-top: $space;
            padding: $icon-size;
        }

    }

    .confirmation {
        font-size: 12px;
        color: $mono-light;
        letter-spacing: 1.54px;
        text-transform: uppercase;
    }

    .info {
        grid-area: info;
        background-color: $mono-white;

        .purchase-summary {
            padding: $icon-size 0;
        }

        &.splashscreen {
            height: 100%;
            background-size: cover;
            background-position: center;
            @include background-image(url(../assets/sign-in/group-35342.png), url(../assets/sign-in/group-35342@2x.png), url(../assets/sign-in/group-35342@3x.png));
            @include background-image(url(../assets/sign-in/group-35342.webp), url(../assets/sign-in/group-35342@2x.webp), url(../assets/sign-in/group-35342@3x.webp));
        }
    }
}
</style>

<template>
    <modal name="login" class="login" :active="active" @close="hide">
        <div class="login-grid">

            <!-- Not authenticated: show login providers -->
            <div class="header" v-show="!isAuthenticated">
                <div class="header-content">
                    <bag-pinkle class="bag-logo icon"></bag-pinkle>
                    <div class="intro content">
                        <word-logo class="sign-in-word-logo">Store</word-logo>
                        <chip />
                        <p>Please sign in to your iHunter Account to continue. All purchases made here are linked to your
                            account and will be available everywhere you use iHunter.</p>
                        <p class="secondary-info">Have questions about your account or previous purchases? Visit the <a
                            @click="help" href="/#/support">Help and support page</a> for more information.</p>
                    </div>
                </div>
                <close @click="hide"></close>
            </div>
            <div class="login" v-show="!isAuthenticated">
                <div class="auth-content">
                    <slot></slot> <!--slot contains the Firebase Authentication #auth-container -->
                </div>
            </div>
            <div class="info splashscreen" v-show="!isAuthenticated">
            </div>



            <!-- Authenticated: confirm user account -->
            <div class="header" v-show="isAuthenticated">
                <div class="header-content">
                    <img v-if="photo" class="profile icon" :src="photo" referrerpolicy="no-referrer">
                    <img v-else class="profile icon default" referrerpolicy="no-referrer">
                    <div class="intro content">
                        <p class="secondary-title">confirmation</p>
                        <h1 v-if="isFirstTimeLogin" class="title">New account created</h1>
                        <h1 v-else class="title">Welcome back, {{ user?.displayName }}</h1>
                        <chip />
                        <p v-if="isFirstTimeLogin">That email address wasn’t associated with an existing iHunter Account so
                            one has been created. Welcome to iHunter!</p>
                        <p v-else>Please confirm that this is the account you use with your other iHunter products. If it
                            doesn’t look quite right then sign out and try again with different details.</p>

                        <p class="secondary-info">You won’t need to do this every time you sign in, but we want to make sure
                            you’re off to a great start.</p>
                    </div>
                </div>
            </div>
            <div class="login" v-if="isAuthenticated">
                <error-bar></error-bar>
                <account-confirmation :user="user" @confirm="confirm" @logout="signOut"
                    v-if="!settings.accountConfirmed"></account-confirmation>
            </div>
            <div class="info" v-if="isAuthenticated">
                <purchase-summary :show-header="true"></purchase-summary>
            </div>
        </div>
    </modal>
</template>

<script>
import { Component, Watch, Vue } from 'vue-property-decorator';

const { empty } = require('ihunter-common');

import AppState from './AppState.vue';
import Settings from './UserSettings.vue';

import VModal from 'vue-js-modal';

@Component({
    components: {
        VModal
    }
})
export default class Login extends Vue.extend({
    props: {
        active: {
            type: Boolean,
            default: false
        }
    }
}) {

    metadata = {};

    requestProcessing = false;

    summary = {};

    mounted() {
        console.log("Login mounted");
    }

    hide() {
        if (!this.isAuthenticated) {
            this.dismiss();
        }
    }

    help() {
        this.hide();
        window.app.navigate('support');
    }

    @Watch('user')
    async onUserChanged() {
        try {
            if (this.user) {// User authorized; we have a token
                // This metadata can be undefined (bug in firebase javascript SDK?)
                if (!this.user?.metadata?.creationTime || !this.user?.metadata?.lastSignInTime) {
                    // Ask the server to fetch the metadata, when required
                    this.user.metadata = await this.dal.fetchUserMetadata();
                }
                this.metadata = this.user.metadata;

            } else {
                // User is signed out
            }

        } catch (error) {
            console.error(error);
            // this.vm.errorHandler(`Error requesting access token from authorization server: ${error.message}`, error);
        }
    }

    get user() {
        return this.state.user;
    }

    get state() {
        return AppState;
    }

    get settings() {
        return Settings;
    }

    async loadUserDetails() {
        try {
            this.summary = await this.dal.fetchUserSummary();

            console.log(this.metadata)


        } catch (error) {
            console.error(error);
            this.summary = {};
            this.error = error;
        }
    }


    get isAuthenticated() {
        return !empty(this.user);
    }


    get isFirstTimeLogin() {
        return this.creationTime && this.creationTime === this.lastSignInTime;
    }

    get creationTime() {
        let time = this.metadata?.creationTime;
        if (time) {
            let date = new Date(time);
            return date.toLocaleDateString();
        }
        return null;
    }

    get lastSignInTime() {
        let time = this.metadata?.lastSignInTime;
        if (time) {
            let date = new Date(time);
            return date.toLocaleDateString();
        }
        return null;
    }

    get photo() {
        return this.user ? this.user.photoURL : null;
    }

    get dal() {
        return this.state.dal;
    }

    signOut() {
        //this.dismiss();

        window.app.signOut();
    }

    confirm() {
        this.dismiss(true);
    }

    dismiss(confirmed) {
        this.$emit('dismiss', confirmed || false);
    }
}

Vue.component('login', Login);

</script>
