var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "purchases" },
    [
      _c("profile-icon", {
        staticClass: "profile icon",
        attrs: { icon: _vm.photo },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("div", { staticClass: "secondary-title" }, [_vm._v("My account")]),
          _vm._v(" "),
          _c("div", { staticClass: "title" }, [_vm._v("My purchases")]),
          _vm._v(" "),
          _c("chip"),
          _vm._v(" "),
          _vm.purchaseComplete
            ? _c(
                "div",
                { staticClass: "product-notice update" },
                [
                  _c("img", {
                    staticClass: "profile",
                    attrs: { src: _vm.photo, referrerpolicy: "no-referrer" },
                  }),
                  _vm._v(" "),
                  _vm._m(0),
                  _vm._v(" "),
                  _c("sash", { attrs: { small: true } }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("p", { staticClass: "details" }, [
            _vm._v(
              "Remember, everything purchased here is linked to your iHunter account and will work\n            everywhere you use iHunter. Make sure all devices are logged in with " +
                _vm._s(_vm.user.email) +
                "."
            ),
          ]),
          _vm._v(" "),
          _c("purchase-summary"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", [_vm._v("Checkout success")]),
      _vm._v(" "),
      _c("div", [_vm._v("New products have been added to your account!")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "Here is every purchase associated with your iHunter Account. On the right you can see where the purchase was\n            made to help you keep track of things that were purchased directly through the mobile apps.\n            Use the "
      ),
      _c("a", { attrs: { href: "/#/billing" } }, [_vm._v("Manage Billing")]),
      _vm._v(
        " page to make any changes to your billing info or manage subscriptions."
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }