var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c(
        "header",
        [
          _c("promotion-banner", {
            attrs: { promotions: _vm.promotions },
            on: { click: _vm.clickPromo },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("main", [
        _c(
          "div",
          { staticClass: "content" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("Select your region")]),
            _vm._v(" "),
            _c("chip"),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Browse everything we have to enhance your iHunter experience. All of our map layers are specifically for\n                a province/territory so you have the local data you need for the for the areas most important to you.\n            "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-grid" },
              _vm._l(_vm.catalog, function (region) {
                return _c("region-card", {
                  key: region.id,
                  attrs: { region: region },
                  on: {
                    click: function ($event) {
                      return _vm.navigate(region.id)
                    },
                  },
                })
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("divider"),
      _vm._v(" "),
      _c(
        "main",
        [
          _c("bag-pinkle", { staticClass: "icon", attrs: { type: "brand" } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v("The iHunter Store"),
              ]),
              _vm._v(" "),
              _c("chip"),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Welcome to the brand new iHunter Store. It’s now easier than ever for you to customize your iHunter\n                experience with all of our map layers and subscriptions available in one place. It’s also now easier\n                than ever for us to put things on sale! Everybody wins."
                ),
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("Your best place to purchase")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "The option to purchase maps and subscriptions through the mobile app is still there but we wanted to give\n                you the flexibility to choose how you shop. The iHunter Store has a few perks that make it the best\n                place to go for customizing your experience:"
                ),
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "red-list" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("li", [
                  _c("b", [
                    _vm._v(
                      _vm._s(_vm.upgradeDiscount) +
                        "% off landowner map updates"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Landowner maps purchased here will get a " +
                        _vm._s(_vm.upgradeDiscount) +
                        "% discount on every future update\n                        that gets released. It’s never been easier (or cheaper) to keep your important layers up to\n                        date."
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(1),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("b", [_vm._v("One purchase, works everywhere")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Everything purchased here is linked to your iHunter account and not a device. That means it will\n                        go with you anywhere you are logged in with the same account. Mobile apps on Android or iOS,\n                        desktop web browser, your maps and subscriptions are right there with you."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("b", [_vm._v("Sales and bundles")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "With all of the iHunter products in one place we can start to bundle items together and run\n                    promotions on different things (or everything). Always keep an eye out for those extra bucks.\n                "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }