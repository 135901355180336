var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "close", on: { click: _vm.click } }, [
    _c("span", { staticClass: "label" }, [_vm._v("close")]),
    _vm._v(" "),
    _c("span", { staticClass: "x" }, [_vm._v("X")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }