var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item" }, [
    _c("div", { staticClass: "badge" }, [
      _vm._v("\n        " + _vm._s(_vm.count) + "\n    "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "info-section" }, [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("div", { staticClass: "secondary" }, [_vm._t("default")], 2),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "arrow" }, [
      _c(
        "svg",
        {
          attrs: {
            "data-name": "Icon 24 • Chevron-next",
            xmlns: "http://www.w3.org/2000/svg",
            width: "8",
            height: "24",
            viewBox: "0 0 8 24",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "m9.03 11.47-4-4a.75.75 0 0 0-1.061 1.061L7.439 12l-3.47 3.47a.75.75 0 0 0 1.061 1.061l4-4a.75.75 0 0 0 0-1.061",
              transform: "translate(-2)",
            },
          }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }