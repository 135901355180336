var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "login",
      attrs: { name: "login", active: _vm.active },
      on: { close: _vm.hide },
    },
    [
      _c("div", { staticClass: "login-grid" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isAuthenticated,
                expression: "!isAuthenticated",
              },
            ],
            staticClass: "header",
          },
          [
            _c(
              "div",
              { staticClass: "header-content" },
              [
                _c("bag-pinkle", { staticClass: "bag-logo icon" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "intro content" },
                  [
                    _c("word-logo", { staticClass: "sign-in-word-logo" }, [
                      _vm._v("Store"),
                    ]),
                    _vm._v(" "),
                    _c("chip"),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "Please sign in to your iHunter Account to continue. All purchases made here are linked to your\n                        account and will be available everywhere you use iHunter."
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "secondary-info" }, [
                      _vm._v(
                        "Have questions about your account or previous purchases? Visit the "
                      ),
                      _c(
                        "a",
                        {
                          attrs: { href: "/#/support" },
                          on: { click: _vm.help },
                        },
                        [_vm._v("Help and support page")]
                      ),
                      _vm._v(" for more information."),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("close", { on: { click: _vm.hide } }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isAuthenticated,
                expression: "!isAuthenticated",
              },
            ],
            staticClass: "login",
          },
          [_c("div", { staticClass: "auth-content" }, [_vm._t("default")], 2)]
        ),
        _vm._v(" "),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isAuthenticated,
              expression: "!isAuthenticated",
            },
          ],
          staticClass: "info splashscreen",
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isAuthenticated,
                expression: "isAuthenticated",
              },
            ],
            staticClass: "header",
          },
          [
            _c("div", { staticClass: "header-content" }, [
              _vm.photo
                ? _c("img", {
                    staticClass: "profile icon",
                    attrs: { src: _vm.photo, referrerpolicy: "no-referrer" },
                  })
                : _c("img", {
                    staticClass: "profile icon default",
                    attrs: { referrerpolicy: "no-referrer" },
                  }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "intro content" },
                [
                  _c("p", { staticClass: "secondary-title" }, [
                    _vm._v("confirmation"),
                  ]),
                  _vm._v(" "),
                  _vm.isFirstTimeLogin
                    ? _c("h1", { staticClass: "title" }, [
                        _vm._v("New account created"),
                      ])
                    : _c("h1", { staticClass: "title" }, [
                        _vm._v(
                          "Welcome back, " + _vm._s(_vm.user?.displayName)
                        ),
                      ]),
                  _vm._v(" "),
                  _c("chip"),
                  _vm._v(" "),
                  _vm.isFirstTimeLogin
                    ? _c("p", [
                        _vm._v(
                          "That email address wasn’t associated with an existing iHunter Account so\n                        one has been created. Welcome to iHunter!"
                        ),
                      ])
                    : _c("p", [
                        _vm._v(
                          "Please confirm that this is the account you use with your other iHunter products. If it\n                        doesn’t look quite right then sign out and try again with different details."
                        ),
                      ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "secondary-info" }, [
                    _vm._v(
                      "You won’t need to do this every time you sign in, but we want to make sure\n                        you’re off to a great start."
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.isAuthenticated
          ? _c(
              "div",
              { staticClass: "login" },
              [
                _c("error-bar"),
                _vm._v(" "),
                !_vm.settings.accountConfirmed
                  ? _c("account-confirmation", {
                      attrs: { user: _vm.user },
                      on: { confirm: _vm.confirm, logout: _vm.signOut },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isAuthenticated
          ? _c(
              "div",
              { staticClass: "info" },
              [_c("purchase-summary", { attrs: { "show-header": true } })],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }