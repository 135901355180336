var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "map-product" }, [
    _vm.validSku
      ? _c("header", [
          _c(
            "div",
            { staticClass: "top" },
            [
              _c("div", { staticClass: "info show-smaller" }, [
                _c("div", { staticClass: "price" }, [
                  _vm._v("$" + _vm._s(_vm.price)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "details" }, [_vm._v("/year")]),
              ]),
              _vm._v(" "),
              _c("subscription-icon", {
                staticClass: "icon",
                attrs: {
                  region: _vm.product?.region,
                  subscription: _vm.product?.subscription,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("div", { staticClass: "secondary-title" }, [_vm._v("add-on")]),
              _vm._v(" "),
              _c("div", { staticClass: "info show-bigger" }, [
                _c("div", { staticClass: "price" }, [
                  _vm._v("$" + _vm._s(_vm.price)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "details" }, [_vm._v("/year")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.name))]),
              _vm._v(" "),
              _c("chip"),
              _vm._v(" "),
              _c("p", { staticClass: "details" }, [
                _vm._v(_vm._s(_vm.metadata?.description_header)),
              ]),
              _vm._v(" "),
              _c("product-notice", {
                attrs: { sku: _vm.sku, "product-info": _vm.info },
              }),
              _vm._v(" "),
              _c("product-cart-button", {
                attrs: { sku: _vm.sku, product: _vm.info, full: true },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "secondary-title" }, [
                _vm._v("works with"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "product-list" }, [
                _c(
                  "div",
                  { staticClass: "product-logo" },
                  [
                    _c("app-icon", { attrs: { region: _vm.region } }),
                    _c("word-logo", { attrs: { dark: true } }, [
                      _vm._v(_vm._s(_vm.short)),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "product-logo" },
                  [
                    _c("web-app-icon"),
                    _c("word-logo", { attrs: { dark: true } }, [_vm._v("Web")]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("divider"),
              _vm._v(" "),
              _vm.metadata?.capabilities?.length
                ? _c(
                    "div",
                    _vm._l(
                      _vm.metadata?.capabilities,
                      function (capability, index) {
                        return capability
                          ? _c(
                              "div",
                              { key: index, staticClass: "capability" },
                              [
                                _c("img", { attrs: { src: capability.image } }),
                                _vm._v(" "),
                                _c("h3", [_vm._v(_vm._s(capability.title))]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(_vm._s(capability.description)),
                                ]),
                                _vm._v(" "),
                                capability.secondaryDescription
                                  ? _c("p", { staticClass: "details" }, [
                                      _vm._v(
                                        _vm._s(capability.secondaryDescription)
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                capability.layers
                                  ? _c("div", [
                                      _c("div", { staticClass: "layers" }, [
                                        _c(
                                          "div",
                                          { staticClass: "section-row" },
                                          [
                                            _c("p", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "section-title",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.metadata.name_generic
                                                    ) + " layers"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "item-count" },
                                                [_vm._v(_vm._s(_vm.layerCount))]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "ul",
                                          _vm._l(
                                            _vm.subscriptionLayers,
                                            function (layer, id) {
                                              return _c(
                                                "li",
                                                {
                                                  key: id,
                                                  staticClass: "item-row",
                                                },
                                                [
                                                  _c("layer-icon", {
                                                    staticClass: "item-icon",
                                                    attrs: {
                                                      fill: layer?.color,
                                                      type: "subscription",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "item-content",
                                                    },
                                                    [
                                                      layer?.name
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "name",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  layer?.name
                                                                ) + " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.layerDescription(id)
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "details more-info",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.layerDescription(
                                                                    id
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e()
                      }
                    ),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.bundledSubscription
                ? _c(
                    "div",
                    [
                      _c("divider"),
                      _vm._v(" "),
                      _c("h3", [
                        _vm._v(
                          "All the benefits of " +
                            _vm._s(_vm.bundledSubscription?.name)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.emphasizeText(
                              _vm.bundledSubscription?.header,
                              _vm.bundledSubscription?.name
                            )
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _vm.bundledSubscription?.description?.length
                        ? _c("div", { staticClass: "brand-bullet" }, [
                            _c(
                              "ul",
                              _vm._l(
                                _vm.bundledSubscription?.description,
                                function (feature, index) {
                                  return _c("li", { key: index }, [
                                    _c("span", [_vm._v(_vm._s(feature))]),
                                  ])
                                }
                              ),
                              0
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("p", [
                        _c(
                          "a",
                          {
                            staticClass: "arrow",
                            attrs: {
                              href: `#/subscription/${_vm.bundledSubscription?.sku}`,
                            },
                          },
                          [_vm._v("More details ▸")]
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.metadata?.disclaimer ? _c("divider") : _vm._e(),
              _vm._v(" "),
              _vm.metadata?.disclaimer
                ? _c("div", { staticClass: "fine-print" }, [
                    _vm._v(_vm._s(_vm.metadata?.disclaimer)),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ])
      : _c(
          "header",
          [
            _c("app-icon", { staticClass: "icon" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("div", { staticClass: "secondary-title" }, [
                  _vm._v("Product details"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "title" }, [
                  _vm._v("Subscription not found"),
                ]),
                _vm._v(" "),
                _c("chip"),
                _vm._v(" "),
                _c("p", [
                  _vm._v("Sorry, we can't find details for the subscription '"),
                  _c("b", [_vm._v(_vm._s(_vm.sku))]),
                  _vm._v("' in our catalog."),
                ]),
                _vm._v(" "),
                _vm._m(0),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("Go "),
      _c("a", { attrs: { href: "javascript:history.back()" } }, [
        _vm._v("back"),
      ]),
      _vm._v(" or navigate "),
      _c("a", { attrs: { href: "/" } }, [_vm._v("home")]),
      _vm._v("."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }