import moment from 'moment';

import { EmailAuthProvider, sendPasswordResetEmail, signInWithCredential, updateProfile  } from "firebase/auth";

import FirebaseRepository from './FirebaseRepository';
import ProfileResources from './ProfileResources';



const { resizeImage } = require('ihunter-common');

export default class ProfileProvider extends FirebaseRepository {

    constructor(auth) {
        super();

        this.auth = auth;
    }

    async uploadProfilePicture(file) {
        try {
            let resizedImage = await resizeImage({
                file: file,
                maxSize: 750 //adjust this if the size is too big/small. This seems to be about 100-150kb, which is OK quality
            });

            let metadata = { 'contentType': file.type };
            await this.uploadObject(ProfileResources.PictureStorage(this.uid), resizedImage.blob, metadata);
            let url = await this.objectUrl(ProfileResources.PictureStorage(this.uid));
            console.log(`Profile photo updated: ${url}`);
            
            await this.updatePhoto(url);
            await this.updatePictureDate();

            return url;
        
        } catch (error) {
            console.error('Upload profile: ' + error.message);
            throw error;
        }
    }

    async deleteProfilePicture() {
        try {
            const path = ProfileResources.PictureStorage(this.uid);
            await this.deleteObject(path);
            await this.updatePhoto('');
            await this.updatePictureDate();
        
        } catch (error) {
            console.error('Delete profile: ' + error.message);
            throw error;
        }
    }

    async updatePictureDate() {
        return await this.set(ProfileResources.PictureDate(this.uid), this.currentTime);
    }

    get uid() {
        return this.auth.currentUser.uid;
    }

    get currentTime() {
        return moment().format("YYYY-MM-DD HH:mm:ss");
    }

    async getPhotoUrl() {
        const path = ProfileResources.PictureStorage(this.uid);
        return await this.objectUrl(path);
    } 

    // get profilePictureExists() {


    //     (async () => {
    //         try {
    //             const path = ProfileResources.PictureStorage(this.uid);
    //             await this.objectUrl(path)
    //             return true;
    //         } catch(e) {
    //             return false;
    //         }
    //         })();
    // }

    async sendPasswordResetEmail() {
        try {
            return await sendPasswordResetEmail(this.auth, this.auth.currentUser.email);

        } catch(error) {
            console.error('Password reset: ' + error.message);
            throw error;
        }
    }

    async updateEmail(email) {
        try {
            return await updateEmail(this.auth.currentUser, email);  
            // return await this.auth.currentUser.updateEmail(email);       
            
        } catch (error) {
            console.error('Updating email address: ' + error.message);
            throw error;
        }
    }

    async updateUserProfile(name) {
        try {  
            return await updateProfile(this.auth.currentUser, {
                displayName: name.trim()
            });     

        } catch (error) {
            console.error('Updating profile name: ' + error.message);
            throw error;
        }
    }

    async updatePhoto(url) {
        try {
            return await updateProfile(this.auth.currentUser, {
                photoURL: url
            });       

        } catch (error) {
            console.error('Updating profile photo: ' + error.message);
            throw error;
        }
    }

    async reauthenticateWithCredential(password) {
        try {
            const credential = EmailAuthProvider.credential(this.auth.currentUser.email, password);
            return await signInWithCredential(this.auth, credential);
            
        } catch (error) {
            console.error('Verifying credentials: ' + error.message);
            throw error;
        }
    }
}

