var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chip" }, [
    _c(
      "svg",
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "32",
          height: "4",
          viewBox: "0 0 32 4",
        },
      },
      [
        _c("path", {
          staticStyle: { fill: "#a61919" },
          attrs: {
            "data-name": "Chip",
            d: "M.444 0h31.112A.444.444 0 0 1 32 .444 3.556 3.556 0 0 1 28.444 4h-28A.444.444 0 0 1 0 3.556V.444A.444.444 0 0 1 .444 0z",
          },
        }),
        _vm._v(" "),
        _c("path", {
          staticStyle: { fill: "#d92121" },
          attrs: {
            "data-name": "Chip",
            d: "M.5 0H24a4 4 0 0 1-4 4H.5a.5.5 0 0 1-.5-.5v-3A.5.5 0 0 1 .5 0z",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }