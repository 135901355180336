<style lang="scss" scoped>
@import 'styles/common.scss';

.region {
    header {


        .header-section {
            display: flex;
            flex-direction: column;
            justify-content: end;
            padding-bottom: $icon-size;
            overflow: hidden;

            @include above(max) {
                padding-top: $xsmall-size;
            }

            .header-gradient {
                display: flex;
                flex-direction: column;
                justify-content: end;

                height: $unit-full;

                @include above(small) {
                    height: $unit-max;
                }

                background-size: cover;
                background-repeat: no-repeat;
                background-position: center top;


                &.bg-0 {
                    @include background-image(url(../assets/splashscreen/0/photo.webp), url(../assets/splashscreen/0/photo@2x.webp), url(../assets/splashscreen/0/photo@3x.webp));
                }

                &.bg-1 {
                    @include background-image(url(../assets/splashscreen/1/photo.webp), url(../assets/splashscreen/1/photo@2x.webp), url(../assets/splashscreen/1/photo@3x.webp));
                }

                &.bg-2 {
                    @include background-image(url(../assets/splashscreen/2/photo.webp), url(../assets/splashscreen/2/photo@2x.webp), url(../assets/splashscreen/2/photo@3x.webp));
                }

                &.bg-3 {
                    @include background-image(url(../assets/splashscreen/3/photo.webp), url(../assets/splashscreen/3/photo@2x.webp), url(../assets/splashscreen/3/photo@3x.webp));
                }

                &.bg-4 {
                    @include background-image(url(../assets/splashscreen/4/photo.webp), url(../assets/splashscreen/4/photo@2x.webp), url(../assets/splashscreen/4/photo@3x.webp));
                }

                &.bg-5 {
                    @include background-image(url(../assets/splashscreen/5/photo.webp), url(../assets/splashscreen/5/photo@2x.webp), url(../assets/splashscreen/5/photo@3x.webp));
                }

                .gradient {
                    @include dynamic-padding(true);
                    display: flex;
                    flex-direction: column;
                    justify-content: end;
                    background-image: linear-gradient(to bottom, rgba(51, 46, 46, 0), $brand-i-h-black);
                    height: 50%;


                    padding-bottom: $icon-size;
                }
            }

            .header-content {
                @include dynamic-padding(true);
                color: $brand-i-h-black;

                background-color: $mono-white;

                padding-top: $icon-size;
                padding-bottom: $icon-size;

                .section-title {
                    @include Title-18;

                    color: $mono-light;
                }

                &.large {
                    display: none;
                }

                @include above(small) {
                    color: $mono-white;
                    background-color: $brand-i-h-black;

                    &.small {
                        display: none;
                    }

                    &.large {
                        display: initial
                    }
                }


                .device-photo {
                    float: right;
                    width: 33%;
                    margin-left: $icon-size;

                    @include above(large) {
                        margin-left: $xsmall-size;
                    }

                    margin-bottom: -$medium-size;
                    transform: translateY(-$medium-size);

                    &.device-ab {
                        @include image(url(../assets/devices/devices-ab.webp), url(../assets/devices/devices-ab@2x.webp), url(../assets/devices/devices-ab@3x.webp));
                    }

                    &.device-bc {
                        @include image(url(../assets/devices/devices-bc.webp), url(../assets/devices/devices-bc@2x.webp), url(../assets/devices/devices-bc@3x.webp));
                    }

                    &.device-mb {
                        @include image(url(../assets/devices/devices-mb.webp), url(../assets/devices/devices-mb@2x.webp), url(../assets/devices/devices-mb@3x.webp));
                    }

                    &.device-nb {
                        @include image(url(../assets/devices/devices-nb.webp), url(../assets/devices/devices-nb@2x.webp), url(../assets/devices/devices-nb@3x.webp));
                    }

                    &.device-ns {
                        @include image(url(../assets/devices/devices-ns.webp), url(../assets/devices/devices-ns@2x.webp), url(../assets/devices/devices-ns@3x.webp));
                    }

                    &.device-on {
                        @include image(url(../assets/devices/devices-on.webp), url(../assets/devices/devices-on@2x.webp), url(../assets/devices/devices-on@3x.webp));
                    }

                    &.device-qc {
                        @include image(url(../assets/devices/devices-qc.webp), url(../assets/devices/devices-qc@2x.webp), url(../assets/devices/devices-qc@3x.webp));
                    }

                    &.device-sk {
                        @include image(url(../assets/devices/devices-sk.webp), url(../assets/devices/devices-sk@2x.webp), url(../assets/devices/devices-sk@3x.webp));
                    }

                    &.device-yt {
                        @include image(url(../assets/devices/devices-yt.webp), url(../assets/devices/devices-yt@2x.webp), url(../assets/devices/devices-yt@3x.webp));
                    }
                }
            }

            .shop {
                font-weight: bold;
                text-align: left;
                color: $general-orange-light;

                font-size: 40px;

                @include between(small, medium) {
                    font-size: 64px;
                }


                @include above(medium) {
                    font-size: 80px;
                }
            }

            .region-word-logo {
                transform-origin: center left;
                transform: scale(1.5);

                @include between(small, medium) {
                    transform: scale(2.5);
                }

                @include above(medium) {
                    transform: scale(3.5);
                }
            }
        }
    }

    .app-icon {
        border-radius: $gap;
        width: $small-size;
        height: $small-size;

        @include between(small, medium) {
            width: $medium-size;
            height: $medium-size;
        }

        @include above(medium) {
            width: $large-size;
            height: $large-size;
        }
    }

    .app-base,
    .subscription-base,
    .products-base {
        @include icon-section;
    }

}
</style>

<template>
    <div class="region">
        <header>
            <div class="header-section">
                <div class="header-gradient" :class="`${gradientBackground}`">

                    <div class="gradient">
                        <app-icon :region="region.id"></app-icon>
                        <div class="shop">Shop</div>
                        <word-logo class="region-word-logo">{{ region?.short }}</word-logo>
                    </div>
                </div>

                <div class="header-content large">
                    <img class="device-photo" :class="`${devicePhoto}`">
                    <p>Whether you are mobile out in the field or planning your hunt from the comfort of home, we have you
                        covered with a version of iHunter. <a href="https://www.ihunterapp.com/features" class="link"
                            target="blank">See the full feature list at
                            ihunterapp.com</a></p>
                    <div class="section-title">Mobile app</div>
                    <p>The mobile app gives you all of the great tracking and location tools, all of the built-in boundary
                        layers, and the current provincial hunting regulations in an easy one-time download.</p>
                    <div>
                        <app-store-badge></app-store-badge>
                        <play-store-badge></play-store-badge>
                    </div>
                    <div class="section-title">Desktop browser</div>
                    <p>iHunter Web makes it easy to research and plan your hunt before getting outside. See all of your
                        maps, boundaries, waypoints and tracks on the big screen, and have everything you do be available in
                        the mobile app when you head out. The best part is, iHunter Web is totally free to use as long as
                        you have an iHunter account, and any subscription layers or maps that you add will work everywhere
                        that account goes!</p>
                    <web-app-badge></web-app-badge>
                </div>

                <div class="header-content small">
                    <p>iHunter {{ region.short }} has a host of location-based features to help you plan and stay informed
                        on
                        your journey. Pin important landmarks, overlay multiple boundary layers, and see the wind conditions
                        or weather forecast for where you are or where you plan to go.
                        Provincial hunting regulations are automatically updated every season so you’ll always have them
                        with you.
                    </p>
                    <p><b>Provincial hunting regulations are automatically updated every season so you’ll always have them
                            with you.</b>
                    </p>
                    <p><a href="https://www.ihunterapp.com" class="link" target="blank">See the full feature list at
                            ihunterapp.com</a></p>
                    <div>
                        <app-store-badge></app-store-badge>
                        <play-store-badge></play-store-badge>
                    </div>
                </div>
            </div>
        </header>

        <div class="app-base" id="base">
            <app-icon class="icon" :region="region?.id"></app-icon>
            <div class="content">
                <div class="secondary-title">Base app</div>
                <div class="title">iHunter {{ region?.name }}</div>
                <chip />
                <p><b>iHunter {{ region?.short }}</b> has a host of location-based features to help you plan and stay
                    informed
                    on your journey. Pin important landmarks, overlay multiple boundary layers, and see the wind conditions
                    or weather forecast for where you are or where you plan to go.
                </p>

                <div class="layers">
                    <div class="section-row">
                        <p><span class="section-title">Built-in layers</span><span class="item-count">{{
                            builtInLayers.length }}</span></p>
                    </div>

                    <ul>
                        <li class="item-row" v-for="(layer) in builtInLayers" :key="layer.name">
                            <layer-icon class="item-icon" :fill="layer?.color"></layer-icon>
                            <div class="item-content">
                                <div class="name" v-if="layer?.name">{{ layer?.name }}</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <divider />

        <div class="subscription-base" id="subscription" v-if="hasSubscriptions" v-for="(subscription) in subscriptions" :key="subscription.sku">
            <div class="top">
                <div class="info show-smaller">
                    <div class="price">${{ subscriptionProductInfo(subscription?.sku_web)?.price }}</div>
                    <div class="details">/year</div>
                </div>
                <subscription-icon class="icon" :region="subscriptionProduct(subscription?.sku_web)?.region" :subscription="subscriptionProduct(subscription?.sku_web)?.subscription"></subscription-icon>
            </div>

            <div class="content">
                <div class="secondary-title">Add-on</div>

                <div class="info show-bigger">
                    <div class="price">${{ subscriptionProductInfo(subscription?.sku_web)?.price }}</div>
                    <div class="details">/year</div>
                </div>

                <div class="title">{{ subscription?.name }}</div>
                <chip />
                <p v-html="emphasizeText(subscription?.header, subscription?.name)"></p>
                <div class="brand-bullet" v-if="subscription?.description?.length">
                    <ul>
                        <li v-for="(feature, index) in subscription?.description" :key="index">
                            <span>{{ feature }}</span>
                        </li>
                    </ul>
                </div>
                <product-notice :sku="subscription?.sku_web"></product-notice>
                <product-cart-button :sku="subscription?.sku_web" :product="subscriptionProductInfo(subscription?.sku_web)"
                    :full="true"></product-cart-button>
                <p><a :href="`#/subscription/${subscription?.sku_web}`" class="arrow">More details ▸</a></p>


                <div class="layers">
                    <div class="section-row" v-if="subscriptionLayers(subscription).length > 0">
                        <p><span class="section-title">{{subscription.generic}} layers</span><span class="item-count">{{
                            subscriptionLayers(subscription).length }}</span></p>
                    </div>
                    <ul>
                        <li class="item-row" v-for="(layer) in subscriptionLayers(subscription)" :key="layer.name">
                            <layer-icon class="item-icon" :fill="layer?.color" type="subscription"></layer-icon>
                            <div class="item-content">
                                <div class="name" v-if="layer?.name">{{ layer?.name }}</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <divider />

        <div class="products-base" v-if="purchasableMapLayer" id="products">
            <div class="top">
                <div class="info show-smaller">
                    <div class="price">${{ lowestPrice }}</div>
                    <div class="details">and up /map</div>
                </div>
                <product-icon class="icon"></product-icon>
            </div>

            <div class="content">
                <div class="secondary-title">Add-on</div>
                <div class="info show-bigger">
                    <div class="price">${{ lowestPrice }}</div>
                    <div class="details">and up /map</div>
                </div>

                <div class="title">Landowner map layers</div>
                <chip />
                <p>
                    No more carrying around a stack of paper maps or driving around the province to purchase them. We’ve
                    collaborated with counties and rural municipalities across Canada to offer landowner maps as boundary
                    layers right within iHunter. Customize your map experience with additional boundary and landowner
                    information for the zones you travel to most.</p>

                <div class="layers">
                    <div class="section-row">
                        <p><span class="section-title">{{ purchasableMapLayer?.name }} maps</span><span
                                class="item-count">{{ keyCount(products) }}</span></p>
                    </div>

                    <product-list :products="products"></product-list>
                </div>
            </div>
        </div>
        <!-- 
        <footer>
            <h2>Region Catalog Model</h2>
            <catalog-explorer :id="region?.id" :catalog="region" :provider="provider"></catalog-explorer>
        </footer> -->
    </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import Page from './Page.vue';

@Component
export default class RegionPage extends Page {

    randomBackground = 0;

    isBaseRegionResource(resource) {
        return resource?.name === "Boundary Data";
    }

    layerCount(resource) {
        return Object.keys(resource?.layers).length;
    }

    keyCount(object) {
        return Object.keys(object).length;
    }

    productPrice(sku) {
        return this.prices[sku];
    }

    created() {
        this.randomBackground = Math.floor((Math.random() * 6));
    }

    get devicePhoto() {
        return 'device-' + this.region.id;
    }

    get gradientBackground() {
        return 'bg-' + this.randomBackground;
    }

    get products() {
        return this.state.regionalMapProducts;
    }

    get lowestPrice() {
        let lowest = null;
        for (let i in this.products) {
            const product = this.products[i];
            if (!lowest || lowest > product.price) {
                lowest = product.price;
            }
        }
        return lowest;
    }

    get builtInLayers() {
        let layers = [];

        for (let id in this.region?.resources) {
            if (id === 'purchasable_layers') {
                continue;
            }
            let resource = this.region?.resources[id];
            for (let layer in resource?.layers) {
                layers.push(resource?.layers[layer]);
            }

        }

        return layers;
    }

    get subscriptions() {
        return this.region?.subscriptions;
    }

    get hasSubscriptions() {
        return this.subscriptions && Object.keys(this.subscriptions).length > 0;
    }

    subscriptionProductInfo(sku) {
        if (this.hasSubscriptions) {
            return this.state.productInfo(sku);
        }
        return null;
    }

    subscriptionProduct(sku) {
        if (!this.state?.products) {
            return;
        }
        return this.state?.products[sku];
    }

    subscriptionLayers(subscription) {
        let layers = [];

        for (let layer in subscription?.layers) {
            layers.push(subscription?.layers[layer]);
        }
        return layers;
    }

    get purchasableMapLayer() {
        return this.state.purchasableMapLayer;
    }
}

Vue.component('region-page', RegionPage);

</script>

