var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "product-list" }, [
    _vm.showCards
      ? _c(
          "div",
          { staticClass: "card-grid" },
          _vm._l(_vm.products, function (product, sku) {
            return _c("product-card", {
              key: sku,
              attrs: { sku: sku, product: product },
            })
          }),
          1
        )
      : _c(
          "ul",
          { staticClass: "list-grid" },
          _vm._l(_vm.sortedProducts, function (product, sku) {
            return _c(
              "li",
              { key: sku },
              [
                _c("product-list-item", {
                  attrs: { sku: product?.sku, product: product },
                }),
              ],
              1
            )
          }),
          0
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }