<style lang="scss" scoped>
@import 'styles/common.scss';

.home {
    //margin-top: $xsmall-size;

    header {
        @include above(max) {
            padding-top: $xsmall-size;

            .sale-banner {
                border-radius: 4px;
            }
        }

        margin-bottom: $xsmall-size;
    }

    main {
        @include icon-section;
    }
}
</style>

<template>
    <div class="home">
        <header>
            <promotion-banner :promotions="promotions" @click="clickPromo"></promotion-banner>
        </header>

        <main>
            <div class="content">
                <div class="title">Select your region</div>
                <chip />
                <p>Browse everything we have to enhance your iHunter experience. All of our map layers are specifically for
                    a province/territory so you have the local data you need for the for the areas most important to you.
                </p>

                <div class="card-grid">
                    <region-card v-for="(region) in catalog" :key="region.id" @click="navigate(region.id)"
                        :region="region"></region-card>
                </div>
            </div>
        </main>
        <divider />
        <main>
            <bag-pinkle class="icon" type="brand"></bag-pinkle>
            <div class="content">
                <div class="title">The iHunter Store</div>
                <chip />
                <p>Welcome to the brand new iHunter Store. It’s now easier than ever for you to customize your iHunter
                    experience with all of our map layers and subscriptions available in one place. It’s also now easier
                    than ever for us to put things on sale! Everybody wins.</p>

                <h3>Your best place to purchase</h3>
                <p>The option to purchase maps and subscriptions through the mobile app is still there but we wanted to give
                    you the flexibility to choose how you shop. The iHunter Store has a few perks that make it the best
                    place to go for customizing your experience:</p>
                <ul class="red-list">
                    <li>
                        <b>One purchase, works everywhere</b>
                        <p>Everything purchased here is linked to your iHunter account and not a device. That means it will
                            go with you anywhere you are logged in with the same account. Mobile apps on Android or iOS,
                            desktop web browser, your maps and subscriptions are right there with you.</p>
                    </li>
                    <li>
                        <b>{{ upgradeDiscount }}% off landowner map updates</b>
                        <p>Landowner maps purchased here will get a {{ upgradeDiscount }}% discount on every future update
                            that gets released. It’s never been easier (or cheaper) to keep your important layers up to
                            date.</p>
                    </li>

                    <li>
                        <b>Sales and bundles</b>
                    <p>With all of the iHunter products in one place we can start to bundle items together and run
                        promotions on different things (or everything). Always keep an eye out for those extra bucks.
                    </p>
                </li>
            </ul>
            </div>
        </main>
    </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import Page from './Page.vue';

@Component
export default class HomePage extends Page {

    clickPromo(promo) {
        console.log(promo);
    }

}

Vue.component('home-page', HomePage);

</script>

