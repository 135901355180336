var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-box",
    {
      ref: "dialog",
      staticClass: "input-dialog",
      attrs: {
        title: _vm.title,
        details: _vm.details,
        action: _vm.action,
        cancel: _vm.cancel,
        type: _vm.type,
      },
      on: { action: _vm.onAction, cancel: _vm.onCancel },
      scopedSlots: _vm._u(
        [
          !_vm.title
            ? {
                key: "title",
                fn: function () {
                  return undefined
                },
                proxy: true,
              }
            : null,
          !_vm.details
            ? {
                key: "details",
                fn: function () {
                  return undefined
                },
                proxy: true,
              }
            : null,
          {
            key: "footer",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
        ],
        null,
        true
      ),
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      [
        _c(
          "div",
          { staticClass: "input-row" },
          [
            _c("textbox", {
              attrs: {
                type: _vm.type,
                label: _vm.label,
                placeholder: _vm.placeholder,
                autocomplete: _vm.autocomplete,
                required: _vm.required,
              },
              on: { input: _vm.onInput },
              model: {
                value: _vm.input,
                callback: function ($$v) {
                  _vm.input = $$v
                },
                expression: "input",
              },
            }),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }