var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "map-product" }, [
    _vm.validSku
      ? _c("header", [
          _c(
            "div",
            { staticClass: "top" },
            [
              _c("div", { staticClass: "info show-smaller" }, [
                _vm.alternate
                  ? _c("div", { staticClass: "alternate" }, [
                      _vm._v("$" + _vm._s(_vm.alternate)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "price" }, [
                  _vm._v("$" + _vm._s(_vm.price)),
                ]),
              ]),
              _vm._v(" "),
              _c("product-icon", { staticClass: "icon" }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("div", { staticClass: "secondary-title" }, [
                _vm._v(_vm._s(_vm.info?.version) + " Landowner Map"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "info show-bigger" }, [
                _vm.alternate
                  ? _c("div", { staticClass: "alternate" }, [
                      _vm._v("$" + _vm._s(_vm.alternate)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "price" }, [
                  _vm._v("$" + _vm._s(_vm.price)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.name))]),
              _vm._v(" "),
              _c("chip"),
              _vm._v(" "),
              _c("p", [
                _vm._v("Additional boundary and landowner information for "),
                _c("b", [_vm._v(_vm._s(_vm.name))]),
                _vm._v("."),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.metadata?.description))]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _vm._v(
                  "Like all other iHunter map layers you can toggle display on/off and control the opacity to customize your experience. Landowner maps are also cacheable so you can download them to your device and be ready to go offline."
                ),
              ]),
              _vm._v(" "),
              _c("product-notice", {
                attrs: { sku: _vm.sku, "product-info": _vm.info },
              }),
              _vm._v(" "),
              _c("product-cart-button", {
                attrs: { sku: _vm.sku, product: _vm.info, full: true },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "secondary-title" }, [
                _vm._v("works with"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "product-list" }, [
                _c(
                  "div",
                  { staticClass: "product-logo" },
                  [
                    _c("app-icon", { attrs: { region: _vm.region } }),
                    _c("word-logo", { attrs: { dark: true } }, [
                      _vm._v(_vm._s(_vm.short)),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "product-logo" },
                  [
                    _c("web-app-icon"),
                    _c("word-logo", { attrs: { dark: true } }, [_vm._v("Web")]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.history.length > 0
                ? _c("div", { staticClass: "secondary-title" }, [
                    _vm._v("Release history"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "product-list" },
                _vm._l(_vm.history, function (version, index) {
                  return _c("div", { key: index, staticClass: "revision" }, [
                    _c("span", [_vm._v(_vm._s(version))]),
                    _vm._v(" "),
                    index !== _vm.history.length - 1
                      ? _c("span", [_vm._v(",")])
                      : _vm._e(),
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _c("divider"),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _vm._v(_vm._s(_vm.layer?.what_is_description)),
              ]),
              _vm._v(" "),
              _vm._m(0),
            ],
            1
          ),
        ])
      : _c(
          "header",
          [
            _c("app-icon", { staticClass: "icon" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("div", { staticClass: "secondary-title" }, [
                  _vm._v("Product details"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "title" }, [_vm._v("Map not found")]),
                _vm._v(" "),
                _c("chip"),
                _vm._v(" "),
                _c("p", [
                  _vm._v("Sorry, we can't find details for the product '"),
                  _c("b", [_vm._v(_vm._s(_vm.sku))]),
                  _vm._v("' in our catalog."),
                ]),
                _vm._v(" "),
                _vm._m(1),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fine-print" }, [
      _c("p", [
        _vm._v(
          "All landowner maps are approximate and should not be used as precise GPS property boundaries, but general guidelines. Landowner names were accurate as of the year listed but may have changed since that date. Neither iHunter nor the copyright holder that created the map accept any responsibility for inaccuracies or incorrect information. Use and rely on the map at your own risk."
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "If you find inaccuracies or otherwise have issues with your map purchase please "
        ),
        _c("a", { attrs: { href: "#/support/" } }, [_vm._v("contact support")]),
        _vm._v(" so we can help."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("Go "),
      _c("a", { attrs: { href: "javascript:history.back()" } }, [
        _vm._v("back"),
      ]),
      _vm._v(" or navigate "),
      _c("a", { attrs: { href: "/" } }, [_vm._v("home")]),
      _vm._v("."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }