var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "updates" },
    [
      _c("profile-icon", {
        staticClass: "profile icon",
        attrs: { icon: _vm.photo },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("div", { staticClass: "secondary-title" }, [_vm._v("My account")]),
          _vm._v(" "),
          _c("div", { staticClass: "title" }, [_vm._v("Map updates")]),
          _vm._v(" "),
          _c("chip"),
          _vm._v(" "),
          _c("purchase-update-section"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }