var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "store" },
    [
      _c(
        "login",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showLogin,
              expression: "showLogin",
            },
          ],
          attrs: { user: _vm.user, active: _vm.showLogin },
          on: { dismiss: _vm.loginDismissed },
        },
        [_c("div", { attrs: { id: "auth-container" } })]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "main-container",
          class: { blur: _vm.showLogin },
          attrs: { id: "main-container" },
        },
        [
          _c(
            "header",
            [
              _c("header-home-tab", {
                attrs: { active: _vm.showHome },
                on: { click: _vm.toggleHome },
              }),
              _vm._v(" "),
              _c("header-search-tab", {
                attrs: { active: _vm.showSearch, label: "Search" },
                on: { click: _vm.toggleSearch },
              }),
              _vm._v(" "),
              _c("header-cart-tab", {
                attrs: {
                  active: _vm.showCart,
                  label: "My Cart",
                  count: _vm.cartItemCount,
                },
                on: { click: _vm.toggleCart },
              }),
              _vm._v(" "),
              _c("header-account-tab", {
                attrs: { active: _vm.showAccount, label: "My Account" },
                on: { click: _vm.toggleAccount },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showAccount && !_vm.showCart && !_vm.showLogin,
                  expression: "!showAccount && !showCart && !showLogin",
                },
              ],
              staticClass: "header-dock",
            },
            [_c("error-bar"), _vm._v(" "), _c("notification-bar")],
            1
          ),
          _vm._v(" "),
          _c(
            "aside",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showAccount,
                  expression: "showAccount",
                },
              ],
              class: { active: _vm.showAccount },
            },
            [
              _c("account-menu", {
                attrs: { user: _vm.user },
                on: { login: _vm.signIn, logout: _vm.signOut },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "aside",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showCart || !_vm.tab,
                  expression: "showCart || !tab",
                },
              ],
              class: { active: _vm.showCart, hidden: !_vm.showCart },
            },
            [_c("cart-menu")],
            1
          ),
          _vm._v(" "),
          _vm.empty(_vm.catalog)
            ? _c("main")
            : _vm.activeRegion
            ? _c("main", [_c("region-page")], 1)
            : _vm.route === "subscription"
            ? _c("main", [_c("subscription-product-page")], 1)
            : _vm.route === "map"
            ? _c("main", [_c("map-product-page")], 1)
            : _vm.route === "updates"
            ? _c("main", [_c("updates-page")], 1)
            : _vm.route === "purchases"
            ? _c("main", [_c("purchases-page")], 1)
            : _vm.route === "support"
            ? _c("main", [_c("support-page")], 1)
            : _vm.route === "billing"
            ? _c("main", [_c("billing-page")], 1)
            : _vm.route === "account"
            ? _c("main", [_c("account-page")], 1)
            : _vm.route === "search"
            ? _c("main", [_c("search-results-page")], 1)
            : _vm.route === "components"
            ? _c("main", [_c("component-library")], 1)
            : _c("main", { staticClass: "main-grid" }, [_c("home-page")], 1),
          _vm._v(" "),
          _c("footer", [_c("app-notice")], 1),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }