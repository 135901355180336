var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-notice" },
    [
      _c("div", { staticClass: "section desktop" }, [
        _c("div", { staticClass: "main" }, [
          _vm._v("\n            Use iHunter on your desktop:\n        "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "icon-buttons" }, [_c("web-app-badge")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "secondary" }, [
          _vm._v(
            "\n            Put your purchases to use planning your next hunt. Log in with your iHunter account to use the web version for free in your desktop browser.\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "section mobile" }, [
        _c("div", { staticClass: "main" }, [
          _vm._v(
            "\n            Mobile app available on your App Store:\n        "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "icon-buttons" },
          [_c("app-store-badge"), _vm._v(" "), _c("play-store-badge")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "secondary" }),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "section social" }, [
      _c("div", { staticClass: "main" }),
      _vm._v(" "),
      _c("div", { staticClass: "icon-buttons" }, [
        _c(
          "a",
          {
            attrs: {
              href: "https://www.instagram.com/ihunterapp/",
              target: "blank",
            },
          },
          [_c("img", { attrs: { src: require("../assets/instagram.svg") } })]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.facebook.com/ihunterapp",
              target: "blank",
            },
          },
          [_c("img", { attrs: { src: require("../assets/facebook.svg") } })]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.youtube.com/channel/UCV0PyPKUCq_RjaPH6kS1SRA",
              target: "blank",
            },
          },
          [_c("img", { attrs: { src: require("../assets/youtube.svg") } })]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "secondary" }, [
        _c("p", [
          _vm._v("Visit "),
          _c("a", { attrs: { href: "https://www.ihunterapp.com" } }, [
            _vm._v("ihunterapp.com"),
          ]),
          _vm._v(" for more product details."),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("Made by "),
          _c("a", { attrs: { href: "https://www.insideoutsidestudios.com" } }, [
            _vm._v("Inside Outside Studios Inc."),
          ]),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Designed and developed in Canada. 🍁")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }