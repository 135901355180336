<style lang="scss" scoped>
    @import 'styles/common.scss';

    .badge {
        svg {
            margin-right: $space;
            margin-bottom: $space;
        }
    }
    
</style>

<template>
    <a class="badge" href="https://play.google.com/store/apps/developer?id=Inside+Outside+Studios+Inc.&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="blank">
        <svg data-name="App store • Google" xmlns="http://www.w3.org/2000/svg" width="135" height="40" viewBox="0 0 135 40">
            <defs>
                <linearGradient id="6kubkdbkda" x1="-.401" y1="6.164" x2=".938" y2="6.164" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#00a0ff"/>
                    <stop offset=".007" stop-color="#00a1ff"/>
                    <stop offset=".26" stop-color="#00beff"/>
                    <stop offset=".512" stop-color="#00d2ff"/>
                    <stop offset=".76" stop-color="#00dfff"/>
                    <stop offset="1" stop-color="#00e3ff"/>
                </linearGradient>
                <linearGradient id="gpdfvawb0b" x1="-.076" y1="17.098" x2="2.305" y2="17.098" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#ffe000"/>
                    <stop offset=".409" stop-color="#ffbd00"/>
                    <stop offset=".775" stop-color="orange"/>
                    <stop offset="1" stop-color="#ff9c00"/>
                </linearGradient>
                <linearGradient id="wzsk65di0c" x1=".015" y1="10.541" x2="1.841" y2="10.541" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#ff3a44"/>
                    <stop offset="1" stop-color="#c31162"/>
                </linearGradient>
                <linearGradient id="am6ptk1kid" x1="-.421" y1="12.541" x2=".394" y2="12.541" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#32a071"/>
                    <stop offset=".068" stop-color="#2da771"/>
                    <stop offset=".476" stop-color="#15cf74"/>
                    <stop offset=".801" stop-color="#06e775"/>
                    <stop offset="1" stop-color="#00f076"/>
                </linearGradient>
            </defs>
            <rect data-name="Rectangle 287" width="135" height="40" rx="5"/>
            <path data-name="Path 8214" d="M140 10.8a4.2 4.2 0 0 1 4.2 4.2v30a4.2 4.2 0 0 1-4.2 4.2H15a4.2 4.2 0 0 1-4.2-4.2V15a4.2 4.2 0 0 1 4.2-4.2h125m0-.8H15a5.015 5.015 0 0 0-5 5v30a5.015 5.015 0 0 0 5 5h125a5.015 5.015 0 0 0 5-5V15a5.015 5.015 0 0 0-5-5z" transform="translate(-10 -10)" style="fill:#a6a6a6"/>
            <path data-name="Path 8215" d="M57.418 20.243a2.709 2.709 0 0 1-.745 2 2.908 2.908 0 0 1-2.2.888 3.152 3.152 0 0 1-2.208-5.367 3.018 3.018 0 0 1 2.208-.9 3.106 3.106 0 0 1 1.232.251 2.468 2.468 0 0 1 .938.671l-.527.527a2.029 2.029 0 0 0-1.643-.711 2.32 2.32 0 0 0-2.33 2.4 2.36 2.36 0 0 0 4.006 1.727 1.892 1.892 0 0 0 .5-1.216h-2.18v-.72h2.908a2.557 2.557 0 0 1 .041.45zm4.61-2.506h-2.733v1.9h2.464v.72h-2.464v1.9h2.733V23h-3.5v-6h3.5zM65.279 23h-.772v-5.263h-1.675V17h4.123v.737h-1.676zm4.659 0v-6h.77v6zm4.19 0h-.771v-5.263h-1.676V17H75.8v.737h-1.672zm9.481-.775a3.116 3.116 0 0 1-4.4 0 3.242 3.242 0 0 1 0-4.45 3.106 3.106 0 0 1 4.4 0 3.232 3.232 0 0 1 0 4.446zm-3.83-.5a2.308 2.308 0 0 0 3.26 0 2.556 2.556 0 0 0 0-3.444 2.308 2.308 0 0 0-3.26 0 2.559 2.559 0 0 0 0 3.444zM85.575 23v-6h.939l2.916 4.667h.033l-.033-1.156V17h.77v6h-.8l-3.051-4.894h-.033l.033 1.157V23z" transform="translate(-10 -10)" style="fill:#fff"/>
            <path data-name="Path 8216" d="M78.136 31.752a4.253 4.253 0 1 0 4.264 4.253 4.192 4.192 0 0 0-4.264-4.253zm0 6.83a2.584 2.584 0 1 1 2.4-2.577 2.46 2.46 0 0 1-2.4 2.577zm-9.313-6.83a4.253 4.253 0 1 0 4.269 4.253 4.192 4.192 0 0 0-4.269-4.253zm0 6.83a2.584 2.584 0 1 1 2.4-2.577 2.46 2.46 0 0 1-2.4 2.577zm-11.079-5.525v1.8h4.317a3.765 3.765 0 0 1-.982 2.271 4.42 4.42 0 0 1-3.335 1.321 4.8 4.8 0 0 1 0-9.6A4.6 4.6 0 0 1 61 30.141l1.273-1.273a6.3 6.3 0 0 0-4.527-1.821 6.606 6.606 0 1 0 0 13.211 6.036 6.036 0 0 0 4.605-1.858 5.962 5.962 0 0 0 1.563-4.221 5.829 5.829 0 0 0-.1-1.127zm45.308 1.4a3.953 3.953 0 0 0-3.641-2.706 4.041 4.041 0 0 0-4.011 4.254 4.162 4.162 0 0 0 4.221 4.253 4.23 4.23 0 0 0 3.544-1.885l-1.45-.967a2.43 2.43 0 0 1-2.094 1.176 2.165 2.165 0 0 1-2.063-1.288l5.687-2.353zm-5.8 1.418a2.333 2.333 0 0 1 2.223-2.475 1.645 1.645 0 0 1 1.579.9zM92.629 40H94.5V27.5h-1.871zm-3.062-7.3H89.5a2.948 2.948 0 0 0-2.239-.95 4.257 4.257 0 0 0 0 8.506 2.9 2.9 0 0 0 2.239-.967h.064v.611c0 1.627-.869 2.5-2.271 2.5a2.354 2.354 0 0 1-2.143-1.515l-1.627.677a4.053 4.053 0 0 0 3.77 2.513c2.191 0 4.044-1.289 4.044-4.43v-7.636h-1.77zm-2.142 5.88a2.587 2.587 0 0 1 0-5.155 2.4 2.4 0 0 1 2.275 2.596 2.376 2.376 0 0 1-2.275 2.561zm24.381-11.08h-4.471V40h1.865v-4.736h2.606a3.888 3.888 0 1 0 0-7.765zm.048 6.025H109.2v-4.286h2.654a2.143 2.143 0 1 1 0 4.285zm11.532-1.795a3.505 3.505 0 0 0-3.33 1.913l1.657.692a1.768 1.768 0 0 1 1.705-.917 1.8 1.8 0 0 1 1.962 1.609v.128a4.127 4.127 0 0 0-1.946-.482c-1.785 0-3.6.981-3.6 2.814a2.889 2.889 0 0 0 3.1 2.751 2.633 2.633 0 0 0 2.381-1.223h.064v.965h1.8v-4.794c.002-2.219-1.654-3.457-3.793-3.457zm-.225 6.851c-.612 0-1.464-.305-1.464-1.061 0-.965 1.061-1.335 1.978-1.335a3.329 3.329 0 0 1 1.7.418 2.262 2.262 0 0 1-2.214 1.977zM133.743 32l-2.139 5.42h-.064L129.32 32h-2.01l3.329 7.575-1.9 4.214h1.946L135.818 32zm-16.807 8h1.864V27.5h-1.866z" transform="translate(-10 -10)" style="fill:#fff"/>
            <g data-name="Group 553">
                <path data-name="Path 8217" d="M20.435 17.538a2.007 2.007 0 0 0-.462 1.405v22.116a2.005 2.005 0 0 0 .462 1.405l.074.072L32.9 30.147v-.292l-12.391-12.39z" style="fill:url(#6kubkdbkda)" transform="translate(-10 -10)"/>
                <path data-name="Path 8218" d="M37.028 34.279 32.9 30.147v-.292l4.131-4.131.093.054 4.893 2.78c1.4.794 1.4 2.093 0 2.887l-4.893 2.78z" style="fill:url(#gpdfvawb0b)" transform="translate(-10 -10)"/>
                <path data-name="Path 8219" d="M37.122 34.225 32.9 30 20.435 42.464a1.627 1.627 0 0 0 2.079.061l14.608-8.3" style="fill:url(#wzsk65di0c)" transform="translate(-10 -10)"/>
                <path data-name="Path 8220" d="m37.122 25.777-14.608-8.3a1.627 1.627 0 0 0-2.079.061L32.9 30z" style="fill:url(#am6ptk1kid)" transform="translate(-10 -10)"/>
                <g data-name="Group 552">
                    <path data-name="Path 8221" d="m37.029 34.132-14.515 8.247a1.665 1.665 0 0 1-2 .011l-.075.074.074.073a1.665 1.665 0 0 0 2-.011l14.608-8.3z" style="isolation:isolate;opacity:.2" transform="translate(-10 -10)"/>
                    <path data-name="Path 8222" d="M20.435 42.318a2.005 2.005 0 0 1-.462-1.405v.147a2 2 0 0 0 .462 1.4l.075-.074z" style="opacity:.12;isolation:isolate" transform="translate(-10 -10)"/>
                </g>
                <path data-name="Path 8223" d="m42.015 31.3-4.986 2.833.093.093 4.893-2.78A1.755 1.755 0 0 0 43.063 30a1.861 1.861 0 0 1-1.048 1.3z" style="opacity:.12;isolation:isolate" transform="translate(-10 -10)"/>
                <path data-name="Path 8224" d="m22.514 17.623 19.5 11.08A1.861 1.861 0 0 1 43.063 30a1.753 1.753 0 0 0-1.048-1.444l-19.5-11.08c-1.4-.794-2.541-.134-2.541 1.466v.147c-.001-1.6 1.142-2.26 2.54-1.466z" style="opacity:.25;isolation:isolate;fill:#fff" transform="translate(-10 -10)"/>
            </g>
        </svg>
    </a>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    @Component
    export default class PlayStoreBadge extends Vue {

    }

    Vue.component('play-store-badge', PlayStoreBadge);

</script>

