var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cart" },
    [
      _c(
        "header",
        [
          _c("error-bar"),
          _vm._v(" "),
          _c("notification-bar"),
          _vm._v(" "),
          _c("div", { staticClass: "header-content" }, [
            !_vm.empty
              ? _c("div", { staticClass: "item-count" }, [
                  _c("b", [_vm._v(_vm._s(_vm.items.length))]),
                  _vm._v(" item"),
                  _vm.items.length > 1 ? _c("span", [_vm._v("s")]) : _vm._e(),
                  _vm._v(" in my cart\n            "),
                ])
              : _c("div", { staticClass: "item-count" }, [
                  _c("b", [_vm._v("Empty")]),
                  _vm._v(" cart\n            "),
                ]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.addPromo.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "promo" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.cart.promo,
                        expression: "cart.promo",
                      },
                    ],
                    attrs: { type: "search", placeholder: "Code" },
                    domProps: { value: _vm.cart.promo },
                    on: {
                      blur: _vm.blur,
                      click: _vm.blur,
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.cart, "promo", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "purchase-details" }, [
                  _vm._v(
                    "Applicable taxes are all dealt with at checkout and payment."
                  ),
                ]),
                _vm._v(" "),
                _vm.authenticated
                  ? _c(
                      "button",
                      {
                        attrs: { disabled: _vm.empty || _vm.processing },
                        on: { click: _vm.checkoutCart },
                      },
                      [_vm._v("Checkout")]
                    )
                  : _c("button", { on: { click: _vm.signIn } }, [
                      _vm._v("Login to checkout"),
                    ]),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.promoCode || _vm.promoTagline
        ? _c("cart-promo-notice", { attrs: { code: _vm.promoCode } }, [
            _vm._v(_vm._s(_vm.promoTagline) + " \n        "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("main", { staticClass: "content" }, [
        _c(
          "ul",
          { staticClass: "items" },
          _vm._l(_vm.items, function (item) {
            return _c(
              "li",
              { key: item.sku },
              [_c("cart-item", { attrs: { sku: item.sku } })],
              1
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("landscape"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "purchase-details" }, [
      _c("span", [_vm._v("Add promo code here:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }