var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "data-name": "Icon 24 • Cart",
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M33.364 1.249h.256a1.709 1.709 0 0 1 1.655 1.374l.416 2.022h12.273a1.037 1.037 0 0 1 .822.411 1.068 1.068 0 0 1 .178.919l-1.382 5.113a2.4 2.4 0 0 1-2.293 1.775h-7.908l.1.51a.976.976 0 0 0 .945.784h7.551a.372.372 0 0 1 0 .744H38.43a1.709 1.709 0 0 1-1.655-1.375L34.562 2.775a.974.974 0 0 0-.945-.784h-.253a.372.372 0 0 1 0-.744zm5.273 14.137a.93.93 0 1 1-.909.93.92.92 0 0 1 .909-.93m6.182 0a.93.93 0 1 1-.909.93.92.92 0 0 1 .909-.93",
          transform: "translate(-33 -1.247)",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }