
import Analytics from 'common/Analytics';
import AppState from './AppState.vue';

export default class StoreAnalytics extends Analytics {

    constructor() {
        super();
    }

    get state() {
        return AppState;
    }

    buildItem(sku, name, price, alternate, quantity) {

        let item = {
            item_id: sku,
            item_name: name,
            discount: alternate ? alternate - price : 0,
            price: price
        }

        if(quantity) {
            item.quantity = quantity;
        }

        return item;
    }

    reportItemView(sku) {
        const info = this.state.productInfo(sku);
        this.report('view_item', {
            currency: 'CAD',
            value: info.price,
            items: [ this.buildItem(sku, info.name, info.price, info.alternate) ]
        });
    }

    reportAddToCart(sku, quantity) {
        const info = this.state.productInfo(sku);
        this.report('add_to_cart', {
            currency: 'CAD',
            value: info.price * quantity,
            items: [ this.buildItem(sku, info.name, info.price, info.alternate, quantity) ]
        });
    }
    
    reportRemoveFromCart(sku, quantity) {
        const info = this.state.productInfo(sku);
        this.report('remove_from_cart', {
            currency: 'CAD',
            value: info.price * quantity,
            items: [ this.buildItem(sku, info.name, info.price, info.alternate, quantity) ]
        });
    }
        
    reportCheckout() {

        let event = {
            currency: 'CAD',
            value: 0,
            items: []
        }

        for(let item of this.state.cart.items) {
            const info = this.state.productInfo(item.sku);
            event.items.push(this.buildItem(item.sku, info.name, info.price, info.alternate, item.count))
            event.value += info.price * item.count;
        }

        if(this.state.cart.code && this.state.cart.code !== '') {
            event.coupon = this.state.cart.code;
        }

        console.log(event);

        this.report('begin_checkout', event);
    }
}
