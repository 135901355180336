var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "item",
      class: { cart: _vm.incart },
      on: { click: _vm.buy },
    },
    [
      _c("div", { staticClass: "info-section" }, [
        _vm.subscription
          ? _c("div", { staticClass: "secondary" }, [_vm._v("Subscription")])
          : _vm.map && _vm.outdated
          ? _c("div", { staticClass: "secondary" }, [
              _vm._v(
                "Update " +
                  _vm._s(_vm.productVersion(_vm.outdated)) +
                  " ▸ " +
                  _vm._s(_vm.version)
              ),
            ])
          : _vm.map
          ? _c("div", { staticClass: "secondary" }, [
              _vm._v(_vm._s(_vm.version)),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "title" }, [
          _c(
            "a",
            {
              attrs: { href: `#/${_vm.type}/${_vm.sku}` },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [_vm._v(_vm._s(_vm.name))]
          ),
        ]),
        _vm._v(" "),
        _vm.alternate
          ? _c("div", { staticClass: "alternate" }, [
              _vm._v("$" + _vm._s(_vm.alternate)),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "price" }, [
          _vm._v("\n            $" + _vm._s(_vm.price) + "\n            "),
          _vm.subscription
            ? _c("div", { staticClass: "recurring" }, [_vm._v("/year")])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      !_vm.owned
        ? _c("div", { staticClass: "action-section" }, [
            _c("div", { staticClass: "action-icon" }, [_c("cart-icon")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "action-text" }, [
              !_vm.incart
                ? _c("span", [_vm._v("Add to cart")])
                : _c("span", [_vm._v("In cart")]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.alternate ? _c("sash") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }