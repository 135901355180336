var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "account" },
    [
      _c("profile-icon", {
        staticClass: "profile icon",
        attrs: {
          icon: _vm.photo,
          editable: true,
          trashable: _vm.profilePhotoExists,
        },
        on: { edit: _vm.editProfilePhoto, trash: _vm.trashProfilePhoto },
      }),
      _vm._v(" "),
      _c("input", {
        ref: "file",
        staticClass: "edit-icon",
        attrs: { type: "file", accept: "image/png, image/jpeg" },
        on: { change: _vm.updateProfilePhoto },
      }),
      _vm._v(" "),
      _vm.editing === "delete-profile"
        ? _c("dialog-box", {
            attrs: {
              title: "Remove my photo",
              warning: "",
              details:
                "Are you sure you want to remove your custom account photo?",
              action: "Remove",
            },
            on: { cancel: _vm.dismiss, action: _vm.deleteProfilePhoto },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.error
        ? _c("dialog-box", {
            attrs: {
              title: _vm.error.title || "Error",
              warning: "",
              details: _vm.error.message,
              cancel: null,
              action: "Dismiss",
            },
            on: {
              cancel: function ($event) {
                _vm.error = null
              },
              action: function ($event) {
                _vm.error = null
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("div", { staticClass: "secondary-title" }, [_vm._v("My account")]),
          _vm._v(" "),
          _c("div", { staticClass: "title" }, [_vm._v("Account settings")]),
          _vm._v(" "),
          _c("chip"),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Use this screen to make changes to your account details and communications settings. The changes will apply\n            everywhere that you use iHunter."
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "setting-section" },
            [
              _c("textbox", {
                attrs: {
                  label: "Name",
                  action: true,
                  value: _vm.user?.displayName,
                },
                on: {
                  click: function ($event) {
                    _vm.editing = "name"
                  },
                },
              }),
              _vm._v(" "),
              _vm.editing === "name"
                ? _c("input-dialog", {
                    attrs: {
                      label: "Name",
                      title: "Change account name",
                      required: true,
                      details:
                        "Enter the new name you would like to use. This name is publicly visible in the message contact search results within iHunter.",
                      value: _vm.user?.displayName,
                      autocomplete: "name",
                    },
                    on: { cancel: _vm.dismiss, action: _vm.updateDisplayName },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "note" }, [
                _vm._v(
                  "Your chosen name and photo will be publicly visible in the message contact search results."
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.emailProvider
            ? _c("div", { staticClass: "setting-section" }, [
                _c("div", { staticClass: "main readonly" }, [
                  _c("div", { staticClass: "setting" }, [
                    _vm._v("Account email"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.user.email)),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(0),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.emailProvider
            ? _c(
                "div",
                { staticClass: "setting-section" },
                [
                  _c("textbox", {
                    attrs: {
                      label: "Account email",
                      action: true,
                      value: _vm.user.email,
                    },
                    on: {
                      click: function ($event) {
                        _vm.editing = "verify-password"
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.editing === "verify-password"
                    ? _c("input-dialog", {
                        key: "verify-password",
                        attrs: {
                          label: "Password",
                          title: "Authentication",
                          required: true,
                          type: "password",
                          details:
                            "Please enter your iHunter Account password to make this change.",
                          action: "Continue",
                          autocomplete: "current-password",
                        },
                        on: {
                          cancel: _vm.dismiss,
                          action: _vm.verifyContactPassword,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.editing === "update-email"
                    ? _c("input-dialog", {
                        key: "update-email",
                        attrs: {
                          label: "Account email",
                          title: "Change account email",
                          required: true,
                          type: "email",
                          details:
                            "Enter the new email address you would like to use. You will need to use this new address to sign-in going forward",
                          action: "Done",
                          autocomplete: "email",
                        },
                        on: {
                          cancel: _vm.dismiss,
                          action: _vm.updateAccountEmail,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.emailProvider
            ? _c(
                "div",
                { staticClass: "setting-section" },
                [
                  _c("textbox", {
                    attrs: {
                      label: "Password",
                      action: true,
                      value: "Reset my password...",
                    },
                    on: {
                      click: function ($event) {
                        _vm.editing = "reset-password"
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.editing === "reset-password"
                    ? _c("dialog-box", {
                        key: "reset-password",
                        attrs: {
                          label: "Password",
                          title: "Reset account password",
                          required: true,
                          type: "password",
                          details: `Would you like iHunter to send a password reset email to: ${_vm.user.email}?`,
                          action: "Send",
                        },
                        on: { cancel: _vm.dismiss, action: _vm.resetPassword },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "setting-section" },
            [
              _c(
                "div",
                { staticClass: "main" },
                [
                  _c("h3", [_vm._v("Communications")]),
                  _vm._v(" "),
                  _c("chip"),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Manage how iHunter gets in touch to send you the latest product news, promotions, discounts, and much more."
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "preference" },
                    [
                      _c("p", [_vm._v("Agree to receive iHunter emails:")]),
                      _vm._v(" "),
                      _c("toggle-switch", {
                        ref: "optin",
                        attrs: { id: "optin" },
                        on: { toggle: _vm.updateOptIn },
                        model: {
                          value: _vm.optin,
                          callback: function ($$v) {
                            _vm.optin = $$v
                          },
                          expression: "optin",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("textbox", {
                attrs: {
                  label: "Contact email",
                  action: true,
                  value: _vm.email,
                },
                on: {
                  click: function ($event) {
                    _vm.editing = "contact"
                  },
                },
              }),
              _vm._v(" "),
              _vm.editing === "contact"
                ? _c("input-dialog", {
                    attrs: {
                      label: "Contact email",
                      title: "Change contact email",
                      required: true,
                      type: "email",
                      details:
                        "Change this address if you would like to use something other than your iHunter Account email.",
                      value: _vm.email || this.user.email,
                      autocomplete: "email",
                      placeholder: _vm.email || this.user.email,
                    },
                    on: {
                      cancel: _vm.cancelContactUpdate,
                      action: _vm.updateContactEmail,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "note" }, [
                _vm._v(
                  "Change this address if you would like to use something other than your iHunter Account email."
                ),
              ]),
              _vm._v(" "),
              _vm.status === "pending"
                ? _c("div", { staticClass: "issue-notice" }, [
                    _c("div", { staticClass: "status-title" }, [
                      _vm._v("Pending"),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "Please click the link in the confirmation email to be added to the iHunter mailing list."
                      ),
                    ]),
                  ])
                : _vm.status === "cleaned"
                ? _c("div", { staticClass: "issue-notice" }, [
                    _c("div", { staticClass: "status-title warning" }, [
                      _vm._v("Issue with email address"),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "There was an issue sending emails to this address and it cannot be used. Please enter a different email address to be added to the iHunter mailing list."
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "note" }, [
      _vm._v(
        "Your account email cannot be changed because you signed in using Apple, Google, or Facebook. To use a\n            different email address you need to sign out and create a new iHunter Account by selecting "
      ),
      _c("b", [_vm._v("Sign in with\n            email.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }