var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item" },
    [
      _c("div", { staticClass: "info-section" }, [
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.name))]),
        _vm._v(" "),
        _vm.subscription
          ? _c("div", { staticClass: "secondary" }, [_vm._v("Renews annually")])
          : _vm.map && _vm.outdated
          ? _c("div", { staticClass: "secondary" }, [
              _vm._v(
                "Update " +
                  _vm._s(_vm.productVersion(_vm.outdated)) +
                  " ▸ " +
                  _vm._s(_vm.version)
              ),
            ])
          : _vm.map
          ? _c("div", { staticClass: "secondary" }, [
              _vm._v(_vm._s(_vm.version) + " landowner map"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "price" }, [
          _vm._v("\n            $" + _vm._s(_vm.price) + "\n        "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "action-row" }, [
        _c("div", { staticClass: "view" }, [
          _c(
            "a",
            {
              attrs: { href: `#/${_vm.type}/${_vm.sku}` },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [_vm._v("View item")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "remove" }, [
          _c(
            "a",
            {
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.remove.apply(null, arguments)
                },
              },
            },
            [_vm._v("Remove")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.alternate ? _c("sash", { attrs: { small: "" } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }