<style lang="scss" scoped>
    @import 'styles/common.scss';

    .item {
        position: relative;
        display: flex;
        flex-direction: row;
        // flex-grow: 1;
        justify-content: space-between;

        width: 100%;
        background-color: $general-orange-light;

        border-radius: 4px;

        .info-section {
            margin: $space $space 0 0;
            flex-grow: 1;

            display: flex;
            flex-direction: column;
            
            .title {
                padding: 0 $space;

                font-size: 13px;
                font-weight: normal;
                color: $mono-black;
            }

            .secondary {
                padding: $notch $space; 

                text-align: left;
                @include Text-11;

                color: $mono-black;
            }
        }

        .badge {
            width: 30px;
            height: 30px;
            border-radius: 30px;
            background-color: $mono-black;
            text-align: center;
            margin: $gap 0 $gap $gap;
            padding-top: 2px;
        }

        .arrow {
            display: flex;
            margin: $gap;
            fill: $mono-white;

            svg { // Why is this hack margin required for BadgeMenuItems?!?
                margin-left: $notch;
            }
        }

        &:hover {
            background-color: $general-orange-glow;

            .arrow {
                fill: $general-orange;
            }
        }

        &:focus, &:active {
            //background-color: $mono-black;
            border: solid 2px $general-orange;
        }
    }
    
</style>

<template>

    <div class="item">
        <div class="badge">
            {{count}}
        </div>

        <div class="info-section">
            <div class="title">{{title}}</div>
            <div class="secondary"><slot></slot></div>
        </div>

        <div class="arrow">
            <svg data-name="Icon 24 • Chevron-next" xmlns="http://www.w3.org/2000/svg" width="8" height="24" viewBox="0 0 8 24">
                <path d="m9.03 11.47-4-4a.75.75 0 0 0-1.061 1.061L7.439 12l-3.47 3.47a.75.75 0 0 0 1.061 1.061l4-4a.75.75 0 0 0 0-1.061" transform="translate(-2)"/>
            </svg>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    @Component
    export default class BadgeMenuItem extends Vue.extend({
        props: {
            title: {
                type: String,
                required: true
            },
            count: {
                type: Number,
                required: true
            }
        }
    }) {

    }

    Vue.component('badge-menu-item', BadgeMenuItem);

</script>

