var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("img", {
    staticClass: "subscription-icon",
    class: `${this.region} ${this.subscription}`,
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }