var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.alternate && _vm.map
      ? _c(
          "div",
          { staticClass: "product-notice update" },
          [
            _c("profile-icon", {
              staticClass: "red small",
              attrs: { icon: _vm.photo },
            }),
            _vm._v(" "),
            _c("div", [
              _c("div", [_vm._v("You own a previous version of this map.")]),
              _vm._v(" "),
              _c("div", [
                _c("b", [_vm._v("Save " + _vm._s(_vm.upgradeDiscount) + "%")]),
                _vm._v(" when you upgrade to the newest year."),
              ]),
            ]),
            _vm._v(" "),
            _c("sash", { attrs: { small: true } }),
          ],
          1
        )
      : _vm.purchase
      ? _c(
          "div",
          { staticClass: "product-notice" },
          [
            _c("profile-icon", {
              staticClass: "red small",
              attrs: { icon: _vm.photo },
            }),
            _vm._v(" "),
            _vm.map
              ? _c("div", [
                  _c("div", [_vm._v("This map is already on your account.")]),
                ])
              : _vm.subscription
              ? _c("div", [
                  !_vm.webSubscription
                    ? _c(
                        "div",
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.name) + " is active on your acccount."
                            ),
                          ]),
                          _vm._v(" "),
                          this.billingInfo
                            ? _c("billing-subscription-date", {
                                attrs: { info: this.billingInfo },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c("div", [
                        _c(
                          "div",
                          [
                            _vm._v(
                              "You have an active " +
                                _vm._s(_vm.name) +
                                " subscription from iHunter "
                            ),
                            _c("platform-label", {
                              attrs: { purchase: _vm.purchase },
                            }),
                          ],
                          1
                        ),
                      ]),
                ])
              : _vm._e(),
          ],
          1
        )
      : _vm.inheritSubscription
      ? _c("div", { staticClass: "product-notice inherit" }, [
          _c(
            "svg",
            {
              staticClass: "icon",
              attrs: {
                width: "12",
                height: "12",
                viewBox: "0 0 12 12",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M11.75 6A5.75 5.75 0 1 1 6 .25 5.757 5.757 0 0 1 11.75 6zM5.587 8.65a.5.5 0 0 0 .338-.2l3.35-4.543a.5.5 0 1 0-.806-.593L5.43 7.439 3.445 5.85a.5.5 0 1 0-.624.782l2.391 1.912a.5.5 0 0 0 .375.106z",
                  fill: "#349F2F",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("div", [
            _c("div", [
              _vm._v(
                "These feature are all include in your " +
                  _vm._s(_vm.upgradeName) +
                  " subscription."
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }