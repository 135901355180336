<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import AppState from './AppState.vue';

    @Component
    export default class ProductItem extends Vue.extend({
        props: {
            sku: {
                type: String,
                required: true
            },
            productInfo: {
                type: Object,
                required: false
            }
        }
    }) {

        buy() {
            if(this.owned || this.ownGroup){
                return;
            }

            if(this.cart.contains(this.sku)){
                this.cart.remove(this.sku);
            }else {
                this.cart.buy(this.sku);

                // When buying, we need to remove peer skus
                for (let sku of this.peers) {
                    if (this.cart.contains(sku)) {
                        this.cart.remove(sku);
                    }
                }
            }
        }

        get peers() {

            let peers = [];

            const group = this.product?.group;

            if (group) {
                for (let product of this.state?.groups[group]) {
                    if (product.sku !== this.sku) {
                        peers.push(product.sku);
         
                    }
                }
            }
            return peers;
        }

        get state() {
            return AppState;
        }

        get cart() {
            return this.state?.cart;
        }

        get upgradeDiscount() {
            return this.state?.upgradeDiscount;
        }

        get incart() {
            return this.cart.contains(this.sku);
        }

        get info() {
            return this.productInfo ?? this.state.productInfo(this.sku);
        }

        get billingInfo() {
            return this.state.subscriptionBillingInfo(this.sku);
        }

        get owned() {
            return !!this.info?.purchase || !!this.billingInfo;
        }

        get region() {
            return this.state?.catalog[this.info?.region]?.short;
        }

        get product() {
            return this.state?.products[this.sku];
        }

        get price() {
            return this.info?.price;
        }

        get name() {
            return this.info?.name;
        }

        get alternate() {
            return this.info?.alternate;
        }

        get outdated() {
            return this.info?.outdated;
        }

        get version() {
            return this.info?.version;
        }

        get type() {
            return this.info?.type;
        }

        get purchase() {
            return this.info?.purchase;
        }

        get map() {
            return this.type === 'map';
        }

        get subscription() {
            return this.type === 'subscription';
        }


        get purchases() {
            return this.state?.purchases;
        }

        productVersion(sku) {
            return this.state?.products[sku]?.version;
        }

        get inheritSubscription() {
            if (!this.upgradeSku) {
                return false;
            }

            // Inhert if we already own the upgradeSku
            return !!this.state.purchases[this.upgradeSku];
        }

        get ownGroup() {
            if (!this.upgradeSku && !this.downgradeSku) {
                return false;
            }

            // Inhert if we already own the upgrade or downgrade
            let own = this.state.purchases[this.upgradeSku] || this.state.purchases[this.downgradeSku];
            return !!own;
        }

        get upgradeSku() {
            const groupId = this.product?.group;
            if (!groupId || !this.sku) {
                return null;
            }

            const group = this.state?.groups[groupId];
            if (!group || group.length <= 1) {
                return null;
            }

            if (group[0]?.sku !== this.sku || group[1]?.sku === this.sku) {
                return null;
            }

            return group[1].sku;
        }

        get downgradeSku() {
            const groupId = this.product?.group;
            if (!groupId || !this.sku) {
                return null;
            }

            const group = this.state?.groups[groupId];
            if (!group || group.length <= 1) {
                return null;
            }

            if (group[0]?.sku == this.sku) {
                return null;
            }

            return group[0].sku;
        }
    }

</script>

