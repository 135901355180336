import { render, staticRenderFns } from "./HeaderCartTab.vue?vue&type=template&id=459f086a&scoped=true&"
import script from "./HeaderCartTab.vue?vue&type=script&lang=js&"
export * from "./HeaderCartTab.vue?vue&type=script&lang=js&"
import style0 from "./HeaderTab.vue?vue&type=style&index=0&id=459f086a&prod&lang=scss&scoped=true&"
import style1 from "./HeaderCartTab.vue?vue&type=style&index=1&id=459f086a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "459f086a",
  null
  
)

export default component.exports