var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "120",
        height: "120",
        viewBox: "0 0 120 120",
      },
    },
    [
      _c("path", {
        staticStyle: { fill: "#332e2e" },
        attrs: {
          "data-name": "Path 8280",
          d: "M4.238 76.827a2.726 2.726 0 1 0-3.022 4.538l57.273 38.177A2.714 2.714 0 0 0 60 120v-6.006z",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          "data-name": "Path 8281",
          d: "M119.54 77.58a2.725 2.725 0 0 0-3.779-.758L60 113.994V120a2.746 2.746 0 0 0 .363-.027h.036c.078-.012.153-.034.228-.052.054-.012.109-.02.162-.036a2.707 2.707 0 0 0 .721-.341l57.272-38.184a2.726 2.726 0 0 0 .758-3.78",
        },
      }),
      _vm._v(" "),
      _c("g", { attrs: { "data-name": "Underline" } }, [
        _c("path", {
          staticStyle: { fill: "#332e2e" },
          attrs: {
            "data-name": "Path 8278",
            d: "M1.216 66.024 58.489 104.2a2.714 2.714 0 0 0 1.51.458v-6.005L4.238 61.486a2.726 2.726 0 0 0-3.022 4.538",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "data-name": "Path 8279",
            d: "M60 104.659c.069 0 .136-.01.205-.015s.131 0 .195-.014a2.569 2.569 0 0 0 .3-.068c.029-.008.059-.011.088-.02.072-.021.14-.053.21-.08a2.675 2.675 0 0 0 .511-.261l57.272-38.182a2.726 2.726 0 1 0-3.021-4.538L60 98.653z",
          },
        }),
      ]),
      _vm._v(" "),
      _c("path", {
        staticStyle: { fill: "#a61919" },
        attrs: {
          "data-name": "Path 8276",
          d: "M120 40.877v.013a2.725 2.725 0 0 1-1.216 2.284L61.511 81.359a2.728 2.728 0 0 1-1.511.458l.015-.8-.015.01v6a2.717 2.717 0 0 0 1.511-.458L118.784 48.4A2.672 2.672 0 0 0 120 46.238v-5.363z",
        },
      }),
      _vm._v(" "),
      _c("path", {
        staticStyle: { fill: "#c41d1d" },
        attrs: {
          "data-name": "Path 8277",
          d: "M60 81.818a2.736 2.736 0 0 1-1.516-.458L1.211 43.178A2.728 2.728 0 0 1 0 40.922v5.311a2.776 2.776 0 0 0 1.215 2.161l57.274 38.182a2.685 2.685 0 0 0 2.562.239 2.707 2.707 0 0 1-1.051.218z",
        },
      }),
      _vm._v(" "),
      _c("path", {
        staticStyle: { fill: "#d92121" },
        attrs: {
          "data-name": "Path 8282",
          d: "M61.511.459a2.732 2.732 0 0 0-3.027 0L1.211 38.64a2.731 2.731 0 0 0 0 4.538L58.484 81.36a2.727 2.727 0 0 0 3.027 0 2.72 2.72 0 0 1-1.511.457z",
        },
      }),
      _vm._v(" "),
      _c("path", {
        staticStyle: { fill: "#c41d1d" },
        attrs: {
          "data-name": "Path 8283",
          d: "M119.543 42.414a2.72 2.72 0 0 0-.76-3.774L61.511.459 60 81.817a2.725 2.725 0 0 0 1.511-.458l57.272-38.185a2.723 2.723 0 0 0 .76-.76",
        },
      }),
      _vm._v(" "),
      _c("g", [
        _c("path", {
          staticStyle: { fill: "#d92121" },
          attrs: {
            "data-name": "Path 8284",
            d: "M60 81.817v5.217a2.717 2.717 0 0 0 1.511-.458L78.2 75.448l-.01-5.209-16.679 11.12a2.725 2.725 0 0 1-1.511.458",
          },
        }),
        _vm._v(" "),
        _c("path", {
          staticStyle: { fill: "#f33" },
          attrs: {
            "data-name": "Path 8285",
            d: "M58.484 81.36 41.809 70.244 41.8 75.45l16.688 11.126a2.717 2.717 0 0 0 1.512.458v-5.216a2.736 2.736 0 0 1-1.516-.458",
          },
        }),
        _vm._v(" "),
        _c("path", {
          staticStyle: { fill: "#fff" },
          attrs: {
            "data-name": "Path 8286",
            d: "M69.173 57.331s4.22-1.588 4.22-11.346c3.211 0 4.409-9.079-.037-9.079a19.694 19.694 0 0 0 2.056-11.346c-2.294-9.079-24.907-9.079-27.2 0-9.632-1.954-1.972 10.04-1.972 11.346-4.587 0-4.587 9.079 0 9.079 0 9.758 4.586 11.346 4.586 11.346v9.079a160.135 160.135 0 0 0-9.011 3.837L58.484 81.36a2.727 2.727 0 0 0 3.027 0l16.67-11.114a160.76 160.76 0 0 0-9.008-3.835z",
          },
        }),
        _vm._v(" "),
        _c("path", {
          staticStyle: { fill: "#ededed" },
          attrs: {
            "data-name": "Path 8287",
            d: "M69.173 66.409V57.33s4.22-1.588 4.22-11.346c2.609 0 3.889-5.993 1.893-8.241a2.477 2.477 0 0 0-1.929-.837 19.7 19.7 0 0 0 2.056-11.346 6.056 6.056 0 0 0-.3-.87c-.036-.085-.084-.164-.124-.247a6.24 6.24 0 0 0-.295-.561c-.059-.1-.128-.189-.193-.283a6.642 6.642 0 0 0-.341-.468c-.078-.1-.163-.187-.247-.28a7.963 7.963 0 0 0-.393-.41c-.1-.092-.195-.18-.3-.269a9.268 9.268 0 0 0-.783-.615q-.229-.162-.473-.313c-.131-.082-.262-.164-.4-.242-.158-.09-.322-.176-.487-.261s-.3-.158-.461-.232-.307-.136-.463-.2c-.183-.078-.365-.158-.554-.23-.111-.043-.226-.08-.338-.12A21.87 21.87 0 0 0 63 18.779h-.034a25 25 0 0 0-1.061-.024h-.17c-.309 0-.618.009-.926.021-.125 0-.25.011-.374.019-.148.008-.294.019-.441.03-.469.608-2.189 5.5 1.453 10.276 2.15 8.074 3.661 21.195-1.326 30.211 1.718 1.084 1.389 4.625 1.389 7.1A107.227 107.227 0 0 0 60 81.817a2.734 2.734 0 0 0 1.511-.459l16.67-11.114a160.02 160.02 0 0 0-9.008-3.835z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }