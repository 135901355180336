var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "platform-label" },
    [
      !_vm.multiplePlatforms
        ? _c("div", { staticClass: "description" }, [
            _vm.platform === "apple" ? _c("span", [_vm._v("iOS")]) : _vm._e(),
            _vm._v(" "),
            _vm.platform === "android"
              ? _c("span", [_vm._v("Android")])
              : _vm._e(),
            _vm._v(" "),
            _vm.platform === "web" ? _c("span", [_vm._v("Store")]) : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("platform-icon", { attrs: { purchase: _vm.purchase } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }