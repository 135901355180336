var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cart-promo-notice" },
    [
      _c("div", { staticClass: "notice" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _c("div", { staticClass: "code" }, [
        _vm._v("\n        " + _vm._s(_vm.code) + "\n    "),
      ]),
      _vm._v(" "),
      _c("sash", { attrs: { small: "", invert: "" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }