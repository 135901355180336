var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "section" }, [
    !_vm.count
      ? _c("div", { staticClass: "info" }, [
          _vm._v(
            "\n        It looks like all of your map layers are up to date. Thanks for being such an awesome iHunter user. Dynamic best wishes about a relevant holiday or season!\n    "
          ),
        ])
      : _c("div", { staticClass: "info" }, [
          _vm.count <= 6
            ? _c("span", [_vm._v(_vm._s(_vm.wordForm(_vm.count)))])
            : _c("span", [_vm._v(_vm._s(_vm.count))]),
          _vm._v(
            " of your maps have newer years available. All landowner maps purchased receive a " +
              _vm._s(_vm.upgradeDiscount) +
              "% discount on all future updates.\n    "
          ),
        ]),
    _vm._v(" "),
    _vm.count < 2
      ? _c(
          "a",
          {
            staticClass: "arrow",
            attrs: { href: "javascript:void(0)" },
            on: { click: _vm.addAll },
          },
          [_c("cart-icon"), _vm._v(" Add map to cart")],
          1
        )
      : _vm.count > 1
      ? _c(
          "a",
          {
            staticClass: "arrow",
            attrs: { href: "javascript:void(0)" },
            on: { click: _vm.addAll },
          },
          [
            _c("cart-icon"),
            _vm._v(" Add all " + _vm._s(_vm.count) + " to cart"),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "details" }, [
      _vm._v(
        "\n        Remember, everything purchased here is linked to your iHunter account and will work everywhere you use iHunter. Make sure all devices are logged in with the same account.\n    "
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card-grid" },
      _vm._l(_vm.updates, function (product, sku) {
        return _c("product-card", {
          key: sku,
          attrs: { sku: sku, "product-info": product },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }