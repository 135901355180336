<script>
    import Vue from 'vue';
    import { reactive } from 'vue'
    import Component from 'vue-class-component';

    const CART_ITEMS            = 'cart-items';
    const CART_PROMO            = 'cart-promo-code';

    const CHECKOUT_PAYMENT      = 'checkout-payment';
    const ACCOUNT_CONFIRMATION  = 'account-confirmed';

    @Component
    class Settings extends Vue.extend({
        // Save settings changes to local storage
        watch: {
            cart(value) { this.save(CART_ITEMS, value); },
            promo(value) { this.save(CART_PROMO, value); },
            checkoutPayment(value) { this.save(CHECKOUT_PAYMENT, value); },
            accountConfirmed(value) { this.save(ACCOUNT_CONFIRMATION, value); },
        }
    }){
        // Defaults for reactive settings
        accountConfirmed = false;
        cart = [];
        promo = '';
        checkoutPayment = '';

        created() {
            // Restore from local storage
            this.cart = this.load(CART_ITEMS);
            this.promo = this.load(CART_PROMO);
            this.checkoutPayment = this.load(CHECKOUT_PAYMENT);

            this.accountConfirmed = this.load(ACCOUNT_CONFIRMATION);


            // Wait for initial watches to fire before completing load
            this.$nextTick(() => {
                this.loaded = true;
            })
        }

        get storage() {
            return window.localStorage;
        }

        load(item) {
            return JSON.parse(this.storage.getItem(item));
        }

        save(item, value) {

            if(!this.loaded) {
                // Item hasn't been loaded yet, ignore default values
                return;
            }

            if(value === null || value === undefined) {
                console.log(`Clearing setting '${item}'`);
                this.storage.removeItem(item);
            }else {
                let data = JSON.stringify(value);
                console.log(`Updating setting '${item}': '${data}'`);
                this.storage.setItem(item, data);
            }
        }
    }


    const UserSettings = reactive(new Settings());
    export { UserSettings, UserSettings as default };
  
</script>
