var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "badge",
      attrs: {
        href: "https://itunes.apple.com/us/app/ihunter/id570558600?ls=1",
        target: "blank",
      },
    },
    [
      _c(
        "svg",
        {
          attrs: {
            "data-name": "App store • Apple",
            xmlns: "http://www.w3.org/2000/svg",
            width: "120",
            height: "40",
            viewBox: "0 0 120 40",
          },
        },
        [
          _c("g", { attrs: { "data-name": "Group 3" } }, [
            _c("g", { attrs: { "data-name": "Group 1" } }, [
              _c("path", {
                staticStyle: { fill: "#a6a6a6" },
                attrs: {
                  "data-name": "Path 1",
                  d: "M110.444 0H8.461c-.307 0-.612.008-.922.013a13.288 13.288 0 0 0-2.01.177 6.7 6.7 0 0 0-1.903.628A6.455 6.455 0 0 0 2 2 6.259 6.259 0 0 0 .822 3.618a6.588 6.588 0 0 0-.627 1.9 12.957 12.957 0 0 0-.18 2C.006 7.83 0 8.138 0 8.444v23.115c0 .31.006.611.015.922a12.957 12.957 0 0 0 .18 2 6.575 6.575 0 0 0 .627 1.9A6.209 6.209 0 0 0 2 38a6.291 6.291 0 0 0 1.626 1.18 6.733 6.733 0 0 0 1.906.631 13.53 13.53 0 0 0 2.01.177c.31.007.615.011.922.011h103.067c.306 0 .619 0 .924-.011a13.352 13.352 0 0 0 2.006-.177 6.837 6.837 0 0 0 1.914-.631A6.3 6.3 0 0 0 118 38a6.4 6.4 0 0 0 1.185-1.614 6.591 6.591 0 0 0 .621-1.9 13.469 13.469 0 0 0 .186-2v-.922c.008-.363.008-.725.008-1.094V9.536c0-.366 0-.729-.008-1.092v-.921a13.469 13.469 0 0 0-.186-2 6.6 6.6 0 0 0-.621-1.9 6.475 6.475 0 0 0-2.807-2.8 6.8 6.8 0 0 0-1.914-.627 13.116 13.116 0 0 0-2.006-.177c-.306 0-.619-.011-.924-.013h-1.087z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  "data-name": "Path 2",
                  d: "M8.466 39.125c-.306 0-.6 0-.907-.011a12.759 12.759 0 0 1-1.874-.163 5.912 5.912 0 0 1-1.661-.551 5.42 5.42 0 0 1-1.4-1.017A5.322 5.322 0 0 1 1.6 35.99a5.71 5.71 0 0 1-.545-1.657 12.379 12.379 0 0 1-.167-1.875c-.006-.211-.015-.913-.015-.913V8.444s.009-.691.015-.895a12.336 12.336 0 0 1 .166-1.872A5.744 5.744 0 0 1 1.6 4.016a5.374 5.374 0 0 1 1.018-1.4A5.581 5.581 0 0 1 4.023 1.6a5.851 5.851 0 0 1 1.658-.544A12.657 12.657 0 0 1 7.562.887l.9-.012h103.066l.916.013a12.455 12.455 0 0 1 1.864.163 5.967 5.967 0 0 1 1.676.548 5.6 5.6 0 0 1 2.422 2.42 5.752 5.752 0 0 1 .537 1.649 12.961 12.961 0 0 1 .174 1.887v.89c.008.375.008.732.008 1.092v20.928c0 .363 0 .718-.008 1.075v.93a12.694 12.694 0 0 1-.171 1.854 5.728 5.728 0 0 1-.542 1.67 5.646 5.646 0 0 1-2.424 2.406 5.89 5.89 0 0 1-1.673.55 12.613 12.613 0 0 1-1.875.163c-.294.007-.6.011-.9.011h-1.087z",
                },
              }),
            ]),
            _vm._v(" "),
            _c("g", { attrs: { "data-name": "<Group>" } }, [
              _c("g", { attrs: { "data-name": "<Group>" } }, [
                _c("path", {
                  staticStyle: { fill: "#fff" },
                  attrs: {
                    "data-name": "<Path>",
                    d: "M24.769 20.3a4.949 4.949 0 0 1 2.357-4.152 5.066 5.066 0 0 0-3.991-2.158c-1.679-.176-3.307 1-4.163 1-.872 0-2.19-.987-3.608-.958a5.315 5.315 0 0 0-4.473 2.728c-1.934 3.348-.491 8.269 1.361 10.976.927 1.325 2.01 2.806 3.428 2.753 1.387-.058 1.905-.884 3.579-.884 1.659 0 2.145.884 3.591.851 1.488-.024 2.426-1.331 3.321-2.669a10.962 10.962 0 0 0 1.517-3.087 4.782 4.782 0 0 1-2.919-4.4zM22.037 12.211a4.872 4.872 0 0 0 1.115-3.491 4.957 4.957 0 0 0-3.208 1.66 4.636 4.636 0 0 0-1.144 3.361 4.1 4.1 0 0 0 3.237-1.53z",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("g", { attrs: { "data-name": "Group 2" } }, [
                _c("path", {
                  staticStyle: { fill: "#fff" },
                  attrs: {
                    "data-name": "Path 3",
                    d: "M42.3 27.14h-4.731l-1.137 3.36h-2l4.483-12.418h2.083L45.477 30.5h-2.039zm-4.243-1.549h3.752l-1.85-5.447h-.049z",
                    transform: "translate(.337)",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticStyle: { fill: "#fff" },
                  attrs: {
                    "data-name": "Path 4",
                    d: "M55.16 25.97c0 2.813-1.506 4.621-3.778 4.621a3.069 3.069 0 0 1-2.849-1.584h-.043v4.484h-1.859V21.442h1.8v1.506h.034a3.212 3.212 0 0 1 2.883-1.6c2.297 0 3.812 1.816 3.812 4.622zm-1.91 0c0-1.833-.947-3.038-2.393-3.038-1.42 0-2.375 1.23-2.375 3.038 0 1.824.955 3.046 2.375 3.046 1.443 0 2.393-1.197 2.393-3.046z",
                    transform: "translate(.337)",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticStyle: { fill: "#fff" },
                  attrs: {
                    "data-name": "Path 5",
                    d: "M65.125 25.97c0 2.813-1.506 4.621-3.778 4.621a3.069 3.069 0 0 1-2.847-1.584h-.043v4.484H56.6V21.442h1.8v1.506h.034a3.212 3.212 0 0 1 2.883-1.6c2.293 0 3.808 1.816 3.808 4.622zm-1.91 0c0-1.833-.947-3.038-2.393-3.038-1.42 0-2.375 1.23-2.375 3.038 0 1.824.955 3.046 2.375 3.046 1.445 0 2.393-1.2 2.393-3.046z",
                    transform: "translate(.337)",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticStyle: { fill: "#fff" },
                  attrs: {
                    "data-name": "Path 6",
                    d: "M71.71 27.036c.138 1.231 1.334 2.04 2.969 2.04 1.566 0 2.693-.809 2.693-1.919 0-.964-.68-1.541-2.289-1.937l-1.609-.388c-2.28-.551-3.339-1.617-3.339-3.348 0-2.143 1.867-3.614 4.519-3.614 2.624 0 4.423 1.472 4.483 3.614h-1.876c-.112-1.239-1.137-1.987-2.634-1.987s-2.521.757-2.521 1.858c0 .878.654 1.395 2.255 1.79l1.368.336c2.548.6 3.606 1.626 3.606 3.442 0 2.323-1.851 3.778-4.794 3.778-2.754 0-4.613-1.421-4.733-3.667z",
                    transform: "translate(.337)",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticStyle: { fill: "#fff" },
                  attrs: {
                    "data-name": "Path 7",
                    d: "M83.346 19.3v2.143h1.722v1.472h-1.722v4.991c0 .775.345 1.137 1.1 1.137a5.807 5.807 0 0 0 .613-.043v1.463a5.1 5.1 0 0 1-1.032.086c-1.833 0-2.548-.688-2.548-2.444v-5.191h-1.316v-1.472h1.316V19.3z",
                    transform: "translate(.337)",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticStyle: { fill: "#fff" },
                  attrs: {
                    "data-name": "Path 8",
                    d: "M86.065 25.97c0-2.849 1.678-4.639 4.294-4.639s4.295 1.79 4.295 4.639-1.661 4.639-4.295 4.639-4.294-1.783-4.294-4.639zm6.7 0c0-1.954-.9-3.107-2.4-3.107s-2.4 1.162-2.4 3.107c0 1.962.895 3.106 2.4 3.106s2.4-1.145 2.4-3.106z",
                    transform: "translate(.337)",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticStyle: { fill: "#fff" },
                  attrs: {
                    "data-name": "Path 9",
                    d: "M96.186 21.442h1.772v1.541H98a2.159 2.159 0 0 1 2.178-1.636 2.866 2.866 0 0 1 .637.069v1.738a2.6 2.6 0 0 0-.835-.112 1.873 1.873 0 0 0-1.937 2.083V30.5h-1.857z",
                    transform: "translate(.337)",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticStyle: { fill: "#fff" },
                  attrs: {
                    "data-name": "Path 10",
                    d: "M109.384 27.837c-.25 1.644-1.851 2.771-3.9 2.771-2.634 0-4.269-1.765-4.269-4.6s1.644-4.682 4.19-4.682c2.5 0 4.08 1.721 4.08 4.466v.637h-6.395v.112a2.358 2.358 0 0 0 2.436 2.564 2.048 2.048 0 0 0 2.091-1.273zm-6.282-2.7h4.526a2.177 2.177 0 0 0-2.221-2.3 2.292 2.292 0 0 0-2.306 2.3z",
                    transform: "translate(.337)",
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("g", { attrs: { "data-name": "<Group>" } }, [
            _c("g", { attrs: { "data-name": "Group 4" } }, [
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  "data-name": "Path 11",
                  d: "M37.826 8.731a2.64 2.64 0 0 1 2.808 2.969c0 1.906-1.03 3-2.808 3h-2.155V8.731zM36.6 13.854h1.125a1.876 1.876 0 0 0 1.968-2.146 1.881 1.881 0 0 0-1.968-2.134H36.6z",
                  transform: "translate(.337 .001)",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  "data-name": "Path 12",
                  d: "M41.681 12.444a2.133 2.133 0 1 1 4.247 0 2.134 2.134 0 1 1-4.247 0zm3.333 0c0-.976-.438-1.547-1.208-1.547s-1.207.571-1.207 1.547.435 1.55 1.207 1.55 1.208-.57 1.208-1.55z",
                  transform: "translate(.337 .001)",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  "data-name": "Path 13",
                  d: "M51.573 14.7h-.922l-.931-3.316h-.07l-.926 3.316h-.913l-1.241-4.5h.9l.807 3.436h.066l.926-3.436h.853l.926 3.436h.07l.8-3.436h.889z",
                  transform: "translate(.337 .001)",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  "data-name": "Path 14",
                  d: "M53.854 10.195h.855v.715h.066a1.348 1.348 0 0 1 1.344-.8 1.465 1.465 0 0 1 1.559 1.675V14.7h-.889v-2.694c0-.724-.314-1.083-.972-1.083a1.033 1.033 0 0 0-1.075 1.141V14.7h-.889z",
                  transform: "translate(.337 .001)",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  "data-name": "Path 15",
                  d: "M59.094 8.437h.889V14.7h-.889z",
                  transform: "translate(.337 .001)",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  "data-name": "Path 16",
                  d: "M61.218 12.444a2.133 2.133 0 1 1 4.248 0 2.134 2.134 0 1 1-4.248 0zm3.333 0c0-.976-.438-1.547-1.208-1.547s-1.207.571-1.207 1.547.435 1.55 1.207 1.55 1.208-.57 1.208-1.55z",
                  transform: "translate(.337 .001)",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  "data-name": "Path 17",
                  d: "M66.4 13.424c0-.811.6-1.278 1.675-1.344l1.22-.07v-.389c0-.476-.314-.744-.922-.744-.5 0-.84.182-.938.5h-.86c.091-.773.818-1.27 1.84-1.27 1.129 0 1.766.562 1.766 1.513v3.08h-.855v-.633h-.07a1.515 1.515 0 0 1-1.353.707 1.36 1.36 0 0 1-1.5-1.348zm2.9-.384v-.376l-1.1.07c-.62.042-.9.252-.9.649s.352.641.835.641a1.061 1.061 0 0 0 1.165-.984z",
                  transform: "translate(.337 .001)",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  "data-name": "Path 18",
                  d: "M71.348 12.444c0-1.423.731-2.324 1.869-2.324a1.484 1.484 0 0 1 1.381.79h.066V8.437h.889V14.7H74.7v-.711h-.07a1.563 1.563 0 0 1-1.414.786c-1.144-.003-1.868-.904-1.868-2.331zm.918 0c0 .955.45 1.53 1.2 1.53s1.212-.583 1.212-1.526-.468-1.53-1.212-1.53-1.2.579-1.2 1.526z",
                  transform: "translate(.337 .001)",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  "data-name": "Path 19",
                  d: "M79.23 12.444a2.133 2.133 0 1 1 4.247 0 2.134 2.134 0 1 1-4.247 0zm3.333 0c0-.976-.438-1.547-1.208-1.547s-1.207.571-1.207 1.547.435 1.55 1.207 1.55 1.208-.57 1.208-1.55z",
                  transform: "translate(.337 .001)",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  "data-name": "Path 20",
                  d: "M84.669 10.195h.855v.715h.066a1.348 1.348 0 0 1 1.344-.8 1.465 1.465 0 0 1 1.559 1.675V14.7h-.889v-2.694c0-.724-.314-1.083-.972-1.083a1.033 1.033 0 0 0-1.075 1.141V14.7h-.889z",
                  transform: "translate(.337 .001)",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  "data-name": "Path 21",
                  d: "M93.515 9.074v1.142h.976v.749h-.976v2.315c0 .472.194.678.637.678a2.966 2.966 0 0 0 .339-.021v.74a2.915 2.915 0 0 1-.483.045c-.988 0-1.382-.348-1.382-1.216v-2.542h-.715v-.749h.715V9.074z",
                  transform: "translate(.337 .001)",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  "data-name": "Path 22",
                  d: "M95.7 8.437h.881v2.481h.07a1.386 1.386 0 0 1 1.373-.807 1.483 1.483 0 0 1 1.551 1.679v2.91h-.89v-2.69c0-.719-.335-1.083-.963-1.083a1.052 1.052 0 0 0-1.134 1.142V14.7H95.7z",
                  transform: "translate(.337 .001)",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  "data-name": "Path 23",
                  d: "M104.761 13.482a1.828 1.828 0 0 1-1.951 1.3 2.045 2.045 0 0 1-2.08-2.324 2.077 2.077 0 0 1 2.076-2.353c1.253 0 2.009.856 2.009 2.27v.31h-3.18v.05a1.19 1.19 0 0 0 1.2 1.29 1.079 1.079 0 0 0 1.071-.546zm-3.126-1.451h2.274a1.086 1.086 0 0 0-1.108-1.167 1.152 1.152 0 0 0-1.166 1.167z",
                  transform: "translate(.337 .001)",
                },
              }),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }