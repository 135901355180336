import { query, ref, onValue } from "firebase/database";

import Observable from "./Observable";
import FirebaseRepository from './FirebaseRepository';
import PurchaseResources from './PurchaseResources';
import isNumeric from "../libs/isNumeric";

const EVENT = {
    Update: 'purchase-update',       // Update to purchases
};

const PLATFORM = {
    Apple:      'apple', 
    Android:    'android', 
    Web:        'web', 
};

export default class PurchaseProvider extends Observable {

    constructor() {
        super();
        
        this.firebase = new FirebaseRepository();

        this.unwatchPlatform = {};

        this.keymap = {};
        this.items = {};
        this.purchases = {};

        this.onUpdate = this.createHandlerMethod(EVENT.Update);
    }

    updateProductCatalog(catalog, region) {
        this.updateMobileKeymap(catalog, region);
        this.refreshPurchases();
    }

    updateMobileKeymap(catalog, region) {
        if (!catalog || !catalog.subscriptions) {
            return;
        }
        // Enumerate catalog subscriptions (map products are 1:1)
        for (const subscription of Object.values(catalog.subscriptions)) {
            if (subscription.sku_ios) {
                if (!this.keymap[PLATFORM.Apple]) {
                    this.keymap[PLATFORM.Apple] = {};
                }
                if (!this.keymap[PLATFORM.Apple][region]) {
                    this.keymap[PLATFORM.Apple][region] = {};
                }
                this.keymap[PLATFORM.Apple][region][subscription.sku_ios] = subscription.sku_web;
            }
            if (subscription.sku_android) {
                if (!this.keymap[PLATFORM.Android]) {
                    this.keymap[PLATFORM.Android] = {};
                }
                if (!this.keymap[PLATFORM.Android][region]) {
                    this.keymap[PLATFORM.Android][region] = {};
                }
                this.keymap[PLATFORM.Android][region][subscription.sku_android] = subscription.sku_web;
            }
        }
    }

    // Convert possibly-duplicate, regional, platform-specific, mobile product keys to unique web skus
    remapMobileKey(platform, region, key) {
        return this.keymap[platform] && this.keymap[platform][region] && this.keymap[platform][region][key] ? this.keymap[platform][region][key] : key;
    }

    // remapMobilePlatformPurchase(key, platform) {
    //     // Check to see if mobile key exist in our platform-specific keymap
    //     if (this.keymap[platform] && this.keymap[platform][key]) {
    //         // Convert key to sku
    //         const sku = this.keymap[platform][key];

    //         if(!this.purchases[sku]) {
    //             this.purchases[sku] = {};
    //         }
    //         if(!this.purchases[sku][platform]) {
    //             this.purchases[sku][platform] = {};
    //         }
    //         // Add new sku purchase
    //         this.purchases[sku][platform] = this.purchases[key][platform];

    //         // Remove entire old platform key purchase
    //         delete this.purchases[key];
    //     }
    // }

    refreshPurchases() {
        console.log(`Refreshing purchases`);

        this.purchases = {};
        for (let platform in this.items) {
            console.log(`Platform purchase refresh ${platform}`);
            this.processPurchases(platform, this.items[platform]);
        }

        // Emit update event once after all known platforms are updated
        this.emit(EVENT.Update, this.purchases);
    }

    watch(uid, notify) {
        this.onUpdate(notify); // Register update notifier

        this.watchPlatform(PLATFORM.Apple, PurchaseResources.Apple(uid));
        this.watchPlatform(PLATFORM.Android, PurchaseResources.Android(uid));
        this.watchPlatform(PLATFORM.Web, PurchaseResources.Web(uid));
    }

    watchPlatform(platform, route) {

        if (this.unwatchPlatform[platform]) {
            return; // Already watching...
        }

        console.log(`Watching ${platform} product purchases`);

        let path = ref(this.firebase.db, route);
        this.unwatchPlatform[platform] = onValue(query(path), async (snapshot) => {
            try {
                console.log(`Platform purchase update ${platform}`);

                this.items[platform] = snapshot.val();
                this.processPurchases(platform, this.items[platform]);

                // Emit update event for each set of platform purchases
                this.emit(EVENT.Update, this.purchases);

            } catch (error) {
                console.error(`Updating ${platform} product purchases`, error);
            }
        }, (error) => {
            console.error(`Watching ${platform} product purchases`, error);
        });
    }

    
    processPurchases(platform, items) {
        if(!items) {
            return this.purchases;
        }
    
        for (let region in items) {
            for(let key in items[region]) {
                if (this.validatePurchase(items[region][key])) {
                    let sku = this.reconstructSku(key);
                    sku = this.remapMobileKey(platform, region, sku);

                    if(!this.purchases[sku]) {
                        this.purchases[sku] = {};
                    }
                    if(!this.purchases[sku][platform]) {
                        this.purchases[sku][platform] = {};
                    }
                    this.purchases[sku][platform] = items[region][key];
                }
            }
        }

        return this.purchases;
    }

    reconstructSku(key) {
        return key.replace(/,/g,".");
    }

    validatePurchase(purchase) {
        if(!purchase) {
            return false;
        }
    
        let invoice = purchase.expirydate || purchase;
        if(isNumeric(invoice)) { // A numeric invoice is probably an expiry date
            const expiry = new Date(+invoice * 1000);
            if(expiry.getTime() > 0) { // If valid datetime number
                const today = new Date();
                today.setHours(0, 0, 0, 0); // Grace period until midnight on the expiry date
                let expired = expiry.getTime() < today.getTime();
                return !expired;
            }
        }
        return true;
    }
    
}




