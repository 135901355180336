var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "cart-button",
      class: {
        cart: _vm.incart,
        owned: _vm.owned || _vm.ownGroup,
        full: _vm.full,
      },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.buy.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "action-icon" }, [_c("cart-icon")], 1),
      _vm._v(" "),
      !_vm.cart.contains(_vm.sku)
        ? _c("div", { staticClass: "action-text" }, [
            _vm.full
              ? _c("div", [_c("span", [_vm._v("Add to cart")])])
              : _c("div", [
                  _c("span", { staticClass: "show-small" }, [_vm._v("+")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "show-medium" }, [_vm._v("Add")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "show-large" }, [
                    _vm._v("to cart"),
                  ]),
                ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }