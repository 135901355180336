import DataAccessLayer from 'common/DataAccessLayer';

export default class StoreDataAccessLayer extends DataAccessLayer {
   
    constructor() {
        super();
    }

    fetchUserMetadata() {
        return this.get(this.apiServer + '/user/metadata');
    }

    fetchUserSummary() {
        return this.get(this.apiServer + '/user/summary');
    }

    fetchUserPurchases() {
        return this.get(this.apiServer + '/user/purchases');
    }

    fetchCountyMetadata(province, sku) {

        return this.getJson(this.tileServer + `/tiles/${province}/${sku}/metadata.json`);
    }

    fetchProvinceMetadata(province) {
        return this.getJson(this.webServer + `/res/provinces/${province}/province.json`);
    }

    fetchSubscriptionMetadata(province) {
        return this.getJson(this.webServer + `/res/provinces/${province}/subscription/metadata.json`);
    }

    checkoutCart(items) {
        return this.post(this.apiServer + '/billing-api/checkout', items);
    }

    checkoutSession(session) {
        return this.get(this.apiServer + '/billing-api/checkout/' + session);
    }

    portal() {
        return this.post(this.apiServer + '/billing-api/portal');
    }

    subscriptions() {
        return this.get(this.apiServer + '/billing-api/subscriptions');
    }


    renewSubscription(sku) {
        return this.put(this.apiServer + `/billing-api/subscription/${sku}/renew`);
    }

    
    cancelSubscription(sku) {
        return this.put(this.apiServer + `/billing-api/subscription/${sku}/cancel`);
    }

    updateSubscription(sku, upgradeSku) {
        return this.put(this.apiServer + `/billing-api/subscription/${sku}/update`, {
            sku: upgradeSku
        });
    }

    mail(name, email, message) {
        let form = {
            name, 
            email, 
            message
        }
        return this.post(this.apiServer + '/mail/info/store', form);
    }
}
