var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sash" }, [
    _c(
      "svg",
      {
        class: { invert: _vm.invert },
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: _vm.size,
          height: _vm.size,
          viewBox: "0 0 42 42",
        },
      },
      [
        _c("defs", [
          _c("clipPath", { attrs: { id: "kz5zdy8uja" } }, [
            _c("path", {
              staticStyle: { fill: "#d03f3f" },
              attrs: { d: "M0 0h38a4 4 0 0 1 4 4v38H0V0z" },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("g", { staticStyle: { "clip-path": "url(#kz5zdy8uja)" } }, [
          _c("path", {
            staticStyle: { fill: "#332e2e", opacity: ".08" },
            attrs: { transform: "rotate(-45 -.775 -.13)", d: "M0 0h12v60H0z" },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              transform: "rotate(-45 -2.883 -1.869)",
              d: "M0 0h12v60H0z",
            },
          }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }