var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "region" },
    [
      _c("header", [
        _c("div", { staticClass: "header-section" }, [
          _c(
            "div",
            {
              staticClass: "header-gradient",
              class: `${_vm.gradientBackground}`,
            },
            [
              _c(
                "div",
                { staticClass: "gradient" },
                [
                  _c("app-icon", { attrs: { region: _vm.region.id } }),
                  _vm._v(" "),
                  _c("div", { staticClass: "shop" }, [_vm._v("Shop")]),
                  _vm._v(" "),
                  _c("word-logo", { staticClass: "region-word-logo" }, [
                    _vm._v(_vm._s(_vm.region?.short)),
                  ]),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "header-content large" },
            [
              _c("img", {
                staticClass: "device-photo",
                class: `${_vm.devicePhoto}`,
              }),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "section-title" }, [
                _vm._v("Mobile app"),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "The mobile app gives you all of the great tracking and location tools, all of the built-in boundary\n                    layers, and the current provincial hunting regulations in an easy one-time download."
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [_c("app-store-badge"), _vm._v(" "), _c("play-store-badge")],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "section-title" }, [
                _vm._v("Desktop browser"),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "iHunter Web makes it easy to research and plan your hunt before getting outside. See all of your\n                    maps, boundaries, waypoints and tracks on the big screen, and have everything you do be available in\n                    the mobile app when you head out. The best part is, iHunter Web is totally free to use as long as\n                    you have an iHunter account, and any subscription layers or maps that you add will work everywhere\n                    that account goes!"
                ),
              ]),
              _vm._v(" "),
              _c("web-app-badge"),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "header-content small" }, [
            _c("p", [
              _vm._v(
                "iHunter " +
                  _vm._s(_vm.region.short) +
                  " has a host of location-based features to help you plan and stay informed\n                    on\n                    your journey. Pin important landmarks, overlay multiple boundary layers, and see the wind conditions\n                    or weather forecast for where you are or where you plan to go.\n                    Provincial hunting regulations are automatically updated every season so you’ll always have them\n                    with you.\n                "
              ),
            ]),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _c(
              "div",
              [_c("app-store-badge"), _vm._v(" "), _c("play-store-badge")],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "app-base", attrs: { id: "base" } },
        [
          _c("app-icon", {
            staticClass: "icon",
            attrs: { region: _vm.region?.id },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("div", { staticClass: "secondary-title" }, [
                _vm._v("Base app"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [
                _vm._v("iHunter " + _vm._s(_vm.region?.name)),
              ]),
              _vm._v(" "),
              _c("chip"),
              _vm._v(" "),
              _c("p", [
                _c("b", [_vm._v("iHunter " + _vm._s(_vm.region?.short))]),
                _vm._v(
                  " has a host of location-based features to help you plan and stay\n                informed\n                on your journey. Pin important landmarks, overlay multiple boundary layers, and see the wind conditions\n                or weather forecast for where you are or where you plan to go.\n            "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "layers" }, [
                _c("div", { staticClass: "section-row" }, [
                  _c("p", [
                    _c("span", { staticClass: "section-title" }, [
                      _vm._v("Built-in layers"),
                    ]),
                    _c("span", { staticClass: "item-count" }, [
                      _vm._v(_vm._s(_vm.builtInLayers.length)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  _vm._l(_vm.builtInLayers, function (layer) {
                    return _c(
                      "li",
                      { key: layer.name, staticClass: "item-row" },
                      [
                        _c("layer-icon", {
                          staticClass: "item-icon",
                          attrs: { fill: layer?.color },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "item-content" }, [
                          layer?.name
                            ? _c("div", { staticClass: "name" }, [
                                _vm._v(_vm._s(layer?.name)),
                              ])
                            : _vm._e(),
                        ]),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("divider"),
      _vm._v(" "),
      _vm._l(_vm.subscriptions, function (subscription) {
        return _vm.hasSubscriptions
          ? _c(
              "div",
              {
                key: subscription.sku,
                staticClass: "subscription-base",
                attrs: { id: "subscription" },
              },
              [
                _c(
                  "div",
                  { staticClass: "top" },
                  [
                    _c("div", { staticClass: "info show-smaller" }, [
                      _c("div", { staticClass: "price" }, [
                        _vm._v(
                          "$" +
                            _vm._s(
                              _vm.subscriptionProductInfo(subscription?.sku_web)
                                ?.price
                            )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "details" }, [_vm._v("/year")]),
                    ]),
                    _vm._v(" "),
                    _c("subscription-icon", {
                      staticClass: "icon",
                      attrs: {
                        region: _vm.subscriptionProduct(subscription?.sku_web)
                          ?.region,
                        subscription: _vm.subscriptionProduct(
                          subscription?.sku_web
                        )?.subscription,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c("div", { staticClass: "secondary-title" }, [
                      _vm._v("Add-on"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "info show-bigger" }, [
                      _c("div", { staticClass: "price" }, [
                        _vm._v(
                          "$" +
                            _vm._s(
                              _vm.subscriptionProductInfo(subscription?.sku_web)
                                ?.price
                            )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "details" }, [_vm._v("/year")]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(subscription?.name)),
                    ]),
                    _vm._v(" "),
                    _c("chip"),
                    _vm._v(" "),
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.emphasizeText(
                            subscription?.header,
                            subscription?.name
                          )
                        ),
                      },
                    }),
                    _vm._v(" "),
                    subscription?.description?.length
                      ? _c("div", { staticClass: "brand-bullet" }, [
                          _c(
                            "ul",
                            _vm._l(
                              subscription?.description,
                              function (feature, index) {
                                return _c("li", { key: index }, [
                                  _c("span", [_vm._v(_vm._s(feature))]),
                                ])
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("product-notice", {
                      attrs: { sku: subscription?.sku_web },
                    }),
                    _vm._v(" "),
                    _c("product-cart-button", {
                      attrs: {
                        sku: subscription?.sku_web,
                        product: _vm.subscriptionProductInfo(
                          subscription?.sku_web
                        ),
                        full: true,
                      },
                    }),
                    _vm._v(" "),
                    _c("p", [
                      _c(
                        "a",
                        {
                          staticClass: "arrow",
                          attrs: {
                            href: `#/subscription/${subscription?.sku_web}`,
                          },
                        },
                        [_vm._v("More details ▸")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "layers" }, [
                      _vm.subscriptionLayers(subscription).length > 0
                        ? _c("div", { staticClass: "section-row" }, [
                            _c("p", [
                              _c("span", { staticClass: "section-title" }, [
                                _vm._v(
                                  _vm._s(subscription.generic) + " layers"
                                ),
                              ]),
                              _c("span", { staticClass: "item-count" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.subscriptionLayers(subscription).length
                                  )
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "ul",
                        _vm._l(
                          _vm.subscriptionLayers(subscription),
                          function (layer) {
                            return _c(
                              "li",
                              { key: layer.name, staticClass: "item-row" },
                              [
                                _c("layer-icon", {
                                  staticClass: "item-icon",
                                  attrs: {
                                    fill: layer?.color,
                                    type: "subscription",
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "item-content" }, [
                                  layer?.name
                                    ? _c("div", { staticClass: "name" }, [
                                        _vm._v(_vm._s(layer?.name)),
                                      ])
                                    : _vm._e(),
                                ]),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            )
          : _vm._e()
      }),
      _vm._v(" "),
      _c("divider"),
      _vm._v(" "),
      _vm.purchasableMapLayer
        ? _c(
            "div",
            { staticClass: "products-base", attrs: { id: "products" } },
            [
              _c(
                "div",
                { staticClass: "top" },
                [
                  _c("div", { staticClass: "info show-smaller" }, [
                    _c("div", { staticClass: "price" }, [
                      _vm._v("$" + _vm._s(_vm.lowestPrice)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "details" }, [
                      _vm._v("and up /map"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("product-icon", { staticClass: "icon" }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c("div", { staticClass: "secondary-title" }, [
                    _vm._v("Add-on"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "info show-bigger" }, [
                    _c("div", { staticClass: "price" }, [
                      _vm._v("$" + _vm._s(_vm.lowestPrice)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "details" }, [
                      _vm._v("and up /map"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [
                    _vm._v("Landowner map layers"),
                  ]),
                  _vm._v(" "),
                  _c("chip"),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                No more carrying around a stack of paper maps or driving around the province to purchase them. We’ve\n                collaborated with counties and rural municipalities across Canada to offer landowner maps as boundary\n                layers right within iHunter. Customize your map experience with additional boundary and landowner\n                information for the zones you travel to most."
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "layers" },
                    [
                      _c("div", { staticClass: "section-row" }, [
                        _c("p", [
                          _c("span", { staticClass: "section-title" }, [
                            _vm._v(
                              _vm._s(_vm.purchasableMapLayer?.name) + " maps"
                            ),
                          ]),
                          _c("span", { staticClass: "item-count" }, [
                            _vm._v(_vm._s(_vm.keyCount(_vm.products))),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("product-list", { attrs: { products: _vm.products } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "Whether you are mobile out in the field or planning your hunt from the comfort of home, we have you\n                    covered with a version of iHunter. "
      ),
      _c(
        "a",
        {
          staticClass: "link",
          attrs: {
            href: "https://www.ihunterapp.com/features",
            target: "blank",
          },
        },
        [
          _vm._v(
            "See the full feature list at\n                        ihunterapp.com"
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("b", [
        _vm._v(
          "Provincial hunting regulations are automatically updated every season so you’ll always have them\n                        with you."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c(
        "a",
        {
          staticClass: "link",
          attrs: { href: "https://www.ihunterapp.com", target: "blank" },
        },
        [
          _vm._v(
            "See the full feature list at\n                        ihunterapp.com"
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }