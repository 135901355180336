var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "list-item",
      class: { cart: _vm.incart },
      on: { click: _vm.click },
    },
    [
      _c(
        "div",
        { staticClass: "icon-section" },
        [_c("layer-icon", { attrs: { type: _vm.type, fill: "#D92121" } })],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "item-content" }, [
        _c("div", { staticClass: "info-section" }, [
          _vm.subscription
            ? _c("div", { staticClass: "secondary" }, [_vm._v("Subscription")])
            : _vm.map && _vm.outdated
            ? _c("div", { staticClass: "secondary" }, [
                _vm._v(
                  "Update " +
                    _vm._s(_vm.productVersion(_vm.outdated)) +
                    " ▸ " +
                    _vm._s(_vm.version)
                ),
              ])
            : _vm.map
            ? _c("div", { staticClass: "secondary" }, [
                _vm._v(_vm._s(_vm.version)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "title" }, [
            _c(
              "a",
              {
                attrs: { href: `#/${_vm.type}/${_vm.sku}` },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.name))]
            ),
          ]),
          _vm._v(" "),
          _vm.map
            ? _c("div", { staticClass: "details" }, [
                _c("b", [_vm._v(_vm._s(_vm.region))]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.alternate
            ? _c("div", { staticClass: "alternate" }, [
                _vm._v("$" + _vm._s(_vm.alternate)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "price" }, [
            !_vm.owned
              ? _c("div", [
                  _vm._v(
                    "\n                    $" +
                      _vm._s(_vm.price) +
                      "\n                    "
                  ),
                  _vm.subscription
                    ? _c("div", { staticClass: "recurring" }, [_vm._v("/year")])
                    : _vm._e(),
                ])
              : _c(
                  "div",
                  [_c("platform-label", { attrs: { purchase: _vm.purchase } })],
                  1
                ),
          ]),
        ]),
        _vm._v(" "),
        !_vm.owned
          ? _c(
              "div",
              { staticClass: "action-section" },
              [_c("product-cart-button", { attrs: { sku: _vm.sku } })],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.alternate ? _c("sash", { attrs: { small: true } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }