<style lang="scss" scoped>
    @import 'styles/common.scss';
    // @import '../../common/styles/colors.scss';

    .item {

        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        width: 100%;
        background-color: $mono-grey;

        .info-section {
            margin: $space $space 0 0;
            flex-grow: 1;

            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-rows: auto 1fr;

            grid-template-areas:
            'title price'
            'secondary secondary';

            
            .title {
                grid-area: title;
                padding: 0 $space;

                font-size: 13px;
                font-weight: normal;
                color: $mono-black;
            }

            .secondary {
                grid-area: secondary;

                padding: $notch $space; 

                text-align: left;
                @include Text-11;
            }

            .price {
                grid-area: price;
                padding: 0 $space;
                text-align: left;
                @include Price-13;
            }

            // .alternate{
            //     grid-area: alternate;
            //     padding: $notch $space; 
            //     text-align: left;
            //     @include Text-11;
            //     text-decoration: line-through;
            // }

            // .recurring {
            //     grid-area: recurring;
            //     font-size: 11px;
            //     text-align: left;
            //     padding: 4px 8px;
            //     color: $mono-black;
            // }
        }

        .action-row {
            display: flex;

            .view {
                padding: 0 $space;
                a {
                    @include URL-11;
                    color: $general-blue-light;
                }
            }

            .remove {
                padding: 0 $space;
                a {
                    @include Nega-URL-11;
                }
            }
        }
    }
    
</style>

<template>

    <div class="item">

        <div class="info-section">

            <div class="title">{{name}}</div>
            <div class="secondary" v-if="subscription">Renews annually</div>
            <div class="secondary" v-else-if="map && outdated">Update {{productVersion(outdated)}} ▸ {{version}}</div>
            <div class="secondary" v-else-if="map">{{version}} landowner map</div>

            <!-- <div class="alternate" v-if="alternate">${{alternate}}</div> -->
            <div class="price">
                ${{price}}
            </div>
            <!-- <div class="recurring" v-if="subscription">/year</div> -->
        </div>

        <div class="action-row">
            <div class="view">
                <a :href="`#/${type}/${sku}`" @click.stop>View item</a>
            </div>
            <div class="remove">
                <a href="javascript:void(0)" @click.stop="remove">Remove</a>
            </div>
        </div>

        <sash v-if="alternate" small></sash>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import ProductItem from './ProductItem.vue';

    @Component
    export default class CartItem extends ProductItem {

        view() {
            //this.cart.remove(this.sku);
        }

        remove() {
            this.cart.remove(this.sku);
        }
    }

    Vue.component('cart-item', CartItem);

</script>

