var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "account" },
    [
      _c("error-bar"),
      _vm._v(" "),
      _c("notification-bar"),
      _vm._v(" "),
      _c(
        "header",
        [
          _c("profile-icon", {
            staticClass: "profile dark",
            attrs: { icon: _vm.photo },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "info" }, [
            _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
            _vm._v(" "),
            _c("div", { staticClass: "email" }, [_vm._v(_vm._s(_vm.email))]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("main", { staticClass: "content" }, [
        _c("ul", { staticClass: "list" }, [
          _c(
            "li",
            {
              staticClass: "list-item",
              on: {
                click: function ($event) {
                  return _vm.navigate("updates")
                },
              },
            },
            [
              _c(
                "badge-menu-item",
                { attrs: { title: "Map updates", count: _vm.count } },
                [_vm._v("New versions available.")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "list" }, [
          _c(
            "li",
            {
              staticClass: "list-item",
              on: {
                click: function ($event) {
                  return _vm.navigate("purchases")
                },
              },
            },
            [
              _c("menu-item", { attrs: { title: "My purchases" } }, [
                _vm._v("View your purchase history."),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              staticClass: "list-item",
              on: {
                click: function ($event) {
                  return _vm.navigate("support")
                },
              },
            },
            [
              _c("menu-item", { attrs: { title: "Help and support" } }, [
                _vm._v("View help resources or contact us."),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              staticClass: "list-item",
              on: {
                click: function ($event) {
                  return _vm.navigate("billing")
                },
              },
            },
            [
              _c(
                "menu-item",
                { attrs: { title: "Billing and subscriptions" } },
                [_vm._v("Manage your billing information.")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              staticClass: "list-item",
              on: {
                click: function ($event) {
                  return _vm.navigate("account")
                },
              },
            },
            [
              _c("menu-item", { attrs: { title: "Account settings" } }, [
                _vm._v("Update your personal details."),
              ]),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "sign-out" }, [
          _c(
            "a",
            {
              staticClass: "arrow",
              attrs: { href: "javascript:void(0)" },
              on: { click: _vm.signOut },
            },
            [_vm._v("Sign out ▸")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("landscape", { attrs: { dark: true } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }