import EventEmitter from 'eventemitter3';
import Environment from "./Environment";

export default class Observable extends EventEmitter {

    constructor() {
        super();
    }

    createHandlerMethod(state) {
        return (handler) => {
            if(typeof handler !== "function") {
                throw TypeError('Invalid callback handler');
            }

            return super.on(state, (...args) => {
                handler(...args);
            }); 
        };
    }

    emit(event, ...args){
        if(!event) {
            throw new Error(`Unable to emit undefined event`);
        }

        if(Environment.isLocal()) { // Only log verbose when local
            if(args.length > 0) {
                console.info(`Event ${event} %o`, [...args]);
            }else {
                console.info(`Event ${event}`);
            }
        }

        super.emit(event, ...args);
    }

}
