const DISCOUNT = {
    UPGRADE: 0.20, // 20% off map upgrades
}

export default class CatalogPromotions {

    static ApplyUpgradeDiscount(price) {
        return this.ApplyDiscount(price, DISCOUNT.UPGRADE);
    }

    static UpgradeDiscount() {
        return 100 * DISCOUNT.UPGRADE;
    }


    static ApplyDiscount(price, discount) {
        let sale = price * (1.0 - discount);
        return sale.toFixed(2); // Round to 2 decimal places
    }

}
