<style lang="scss" scoped>
    @import 'styles/common.scss';

    .header-tab {
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        flex-shrink: 0;

        height: 100%;
        align-items: center;

        user-select: none;
        pointer-events: none;

        .tab-item {

            pointer-events: all;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;

            margin-left: auto;

            min-width: $half;
            height: 100%;

            position: relative;
        }


        &:hover {
            // background-color: $brand-i-h-black-dark;

            &.active {
                .close:before {
                    color: $mono-glow;
                }
            }

            .tab-item {
                background-color: $brand-i-h-black-dark;
                // background: $mono-black;
            }

            .underline {
                background-color: $mono-white;
            }
        }

        &.active {
           // @include below(large)  {
                .tab-item {
                    background: $mono-black;
                }
           // }

            .label {
                @include Label-active;
            }

            .close:before {
                content: 'x';
                color: $mono-dark;

                &:hover {
                    color: $mono-glow;
                }


            }

            .underline {
                background-color: $general-orange;
            }
        }
        
        .label {
            text-transform: uppercase;
            @include Label-inactive;
        }

        .icon {
            margin: auto;

        }

        .underline {
            width: $icon-size;
            height: $notch;
            margin: $notch auto 0 auto;
            border-radius: $notch $notch 0 0;
        }

        .close {
            position: absolute;
            top: 0;
            right: $space;
        }
    }


</style>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import AppState from './AppState.vue';

    @Component
    export default class HeaderTab extends Vue.extend({
        props: {
            label: {
                type: String
            },
            active: {
                type: Boolean,
                default: false
            },
            count: {
                type: Number
            }
        }
    }) {
        click(event) {
            this.$emit('click', event);
        }

        get showBadge() {
            return this.count && this.count != 0;
        }

        get id() {
            return this.count;
        }

        get state() {
            return AppState;
        }

        navigate(route, args) {
            this.$parent.navigate(route, args);
        }

        get route() {
            return this.$parent.route;
        }

        get args() {
            return this.$parent.args;
        }
    }


</script>

