var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "region-card",
      class: { active: _vm.active },
      attrs: { role: "listitem" },
      on: { click: _vm.click },
    },
    [
      _c("app-icon", { attrs: { region: _vm.id } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "region-labels" },
        [_vm._m(0), _vm._v(" "), _c("word-logo", [_vm._v(_vm._s(_vm.short))])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", [_vm._v("Shop")]),
      _c("span", { staticClass: "arrow" }, [_vm._v(" ▸")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }