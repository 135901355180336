<style lang="scss" scoped>
    @import 'styles/common.scss';

    .product-notice {
        position: relative;
        display: flex;
        align-items: center;
        width: fit-content;
        font-size: 13px;
        color: $mono-dark;
        gap: 6px;

        padding: $space;
        margin: $gap 0;

        border-radius: 4px;
        border: solid 1px $mono-misc;
        background-color: $mono-grey;

        &.update {
            color: $mono-black;   
            border: solid 1px $general-orange;
            background-color: $general-orange-glow;
        }

        &.inherit {
            color:  $success-text;   
            border: solid 1px  $success-glow-border;
            background-color:  $success-glow;
        }
    }

</style>

<template>
    <div>
        <!-- Map upgrade available -->
        <div v-if="alternate && map" class="product-notice update">
            <profile-icon class="red small" :icon="photo"></profile-icon>
            <div>
                <div>You own a previous version of this map.</div>
                <div><b>Save {{upgradeDiscount}}%</b> when you upgrade to the newest year.</div>
            </div>
            <sash :small="true"></sash>
        </div>

        <!-- Product owned -->
        <div v-else-if="purchase" class="product-notice">
            <profile-icon class="red small" :icon="photo"></profile-icon>
            <!-- Map product -->
            <div v-if="map">
                <div>This map is already on your account.</div>
            </div>

            <!-- Subscription product -->
            <div v-else-if="subscription">
                <!-- Active web subscription -->
                <div v-if="!webSubscription">
                    <div>{{ name }} is active on your acccount.</div>
                    <billing-subscription-date v-if="this.billingInfo" :info="this.billingInfo"></billing-subscription-date>
                </div>

                <!-- Other platforms -->
                <div v-else>
                    <div>You have an active {{ name }} subscription from iHunter <platform-label :purchase="purchase"></platform-label></div>
                </div>
            </div>
        </div>

        <!-- Inherit subscription -->
        <div v-else-if="inheritSubscription" class="product-notice inherit">
            <!-- Green checkmark -->
            <svg class="icon" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.75 6A5.75 5.75 0 1 1 6 .25 5.757 5.757 0 0 1 11.75 6zM5.587 8.65a.5.5 0 0 0 .338-.2l3.35-4.543a.5.5 0 1 0-.806-.593L5.43 7.439 3.445 5.85a.5.5 0 1 0-.624.782l2.391 1.912a.5.5 0 0 0 .375.106z" fill="#349F2F"/>
            </svg>

            <div>
                <div>These feature are all include in your {{ upgradeName }} subscription.</div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import ProductItem from './ProductItem.vue';

    @Component
    export default class ProductNotice extends ProductItem {

        get user() {
            return this.state?.user;
        }

        get photo() {
            return this.user?.photoURL;
        }

        get activeSubscription() {
            return !!this.billingInfo;
        }

        get legacySubscription() {
            return this.purchase?.web.startsWith('legacy');
        }

        get webSubscription() {
            return !!this.purchase?.web;
        }

        get upgradeInfo() {
            return this.upgradeSku ? this.state.productInfo(this.upgradeSku) : null;
        }

        get upgradeName() {
            return this.upgradeInfo ? this.upgradeInfo?.name : null;
        }

    }

    Vue.component('product-notice', ProductNotice);

</script>

