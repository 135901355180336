<style lang="scss" scoped>
    @import 'styles/common.scss';

    .cart-promo-notice {
        position: relative;
        padding: $gap;
        background-color: $general-orange-light;
        color: $mono-black;

        .notice {
            font-size: 11px;
        }

        .code {
            margin-top: $gap;
            text-align: center;
            @include Price-18;
        }

    }
    
</style>

<template>

    <div class="cart-promo-notice">

        <div class="notice">
            <slot></slot>
        </div>

        <div class="code">
            {{ code }}
        </div>

        <sash small invert></sash>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    @Component
    export default class CartPromoNotice extends Vue.extend({
        props: {
            code: {
                type: String,
                // default: null,
            }
        }
    }) {

    }

    Vue.component('cart-promo-notice', CartPromoNotice);

</script>

