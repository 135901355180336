<style lang="scss" scoped>
    @import 'styles/common.scss';

    .store {
        margin: 0;
        padding: 0;

        width: 100%;
        // height: 100%;
        position: relative;
    }

    .main-container {
        width: 100%;
        height: 100%;
        position: relative;

        display: flex;
        flex-direction: column;
        align-content: center;

        &.blur {
            filter: blur(5px);
            transition: transform .2s ease-out;;
        }
    }

    header {
        position: fixed;
        top: 0;
        width: 100%;
        height: $header-height;

        color: $mono-white;
        background-color: $brand-i-h-black;

        display: flex;
        align-items: center;
        justify-content: space-between;

        z-index: 10;

        .tab-row {
            display: flex;
            flex-direction: row;
            justify-content: end;
            width: 100%;
            height: 100%;
        }
    }

    .header-dock {
        position: fixed;
        top: $header-height;
        width: 100%;

        z-index: 10;
    }

    aside {
        position: fixed;
        top: $header-height;
        right: 0;

        display: flex;
        flex-direction: column;
        width: $aside-width;
        max-height: calc(100vh - $header-height);
        overflow: auto;

        z-index: 2;

        color: $mono-black;
        background-color: $aside-colour;
        font-size: 16px;

        transform: translateX($aside-width);
        transition: transform 0.2s ease-out;

        @include above(large)  {
            transform: translateX(0);

            .close-icon {
                display: none;
            }    
        }

        @include below(large)  {
            &.hidden {
                display: none;
            }
        }

        &.active {
            transform: translateX(0);
        }
    }

    main {
        display: flex;
        flex-grow: 1;
        align-self: center;
        padding-top: $header-height;
        padding-bottom: $small-size;

        @include constrain-width;
        // @include dynamic-padding(true);

        // margin-top: $header-height;
        //margin-bottom: $footer-height;

        @include above(large)  {
            padding-right: $aside-width;
        }


    }

    footer {
        width: 100%;

        color: $mono-white;
        background-color: $mono-black;

        overflow: hidden;

        padding: $gap;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        @include above(large)  {
            padding-right: $aside-width;
        }
    }

    .regions {
        display: flex;
        flex-direction: column;
        .region-card {
            margin: $space;
        }
    }


</style>

<template>

    <div class="store">
        <login v-show="showLogin" :user="user" :active="showLogin" @dismiss="loginDismissed">
            <div id="auth-container"></div>
        </login>

        <div class="main-container" :class="{blur:showLogin}" id="main-container">
            <header>
                <header-home-tab @click="toggleHome" :active="showHome"></header-home-tab>
                <header-search-tab @click="toggleSearch" :active="showSearch" label="Search"></header-search-tab>
                <header-cart-tab @click="toggleCart" :active="showCart" label="My Cart" :count="cartItemCount"></header-cart-tab>
                <header-account-tab @click="toggleAccount" :active="showAccount" label="My Account"></header-account-tab>
            </header>

            <!-- Notices -->
            <div class="header-dock" v-show="!showAccount && !showCart && !showLogin">
                <error-bar></error-bar>
                <notification-bar></notification-bar>
            </div>

            <!-- Account -->
            <aside v-show="showAccount" :class="{active: showAccount}">
                <account-menu :user="user" @login="signIn" @logout="signOut"></account-menu>
            </aside>

            <!-- Cart -->
            <aside v-show="showCart || !tab" :class="{active: showCart, hidden: !showCart}">
                <cart-menu></cart-menu>
            </aside>

            <!-- Loading -->
            <main v-if="empty(catalog)">
                <!-- <h2>Loading...</h2> -->
            </main>

            <!-- Region view -->
            <main v-else-if="activeRegion">
                <region-page></region-page>
            </main>
            <main v-else-if="route==='subscription'">
                <subscription-product-page></subscription-product-page>
            </main>
            <main v-else-if="route==='map'">
                <map-product-page></map-product-page>
            </main>
            <main v-else-if="route==='updates'">
                <updates-page></updates-page>
            </main>
            <main v-else-if="route==='purchases'">
                <purchases-page></purchases-page>
            </main>
            <main v-else-if="route==='support'">
                <support-page></support-page>
            </main>
            <main v-else-if="route==='billing'">
                <billing-page></billing-page>
            </main>
            <main v-else-if="route==='account'">
                <account-page></account-page>
            </main>
            <main v-else-if="route==='search'">
                <search-results-page></search-results-page>
            </main>
            <main v-else-if="route==='components'">
                <component-library></component-library>
            </main>
            <main v-else class="main-grid">
                <home-page></home-page>
            </main>

            <footer>
                <app-notice></app-notice>
            </footer>
        </div>

    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import JsonViewer from 'vue-json-viewer';

    import Environment from 'common/Environment';
    import StoreAnalytics from './StoreAnalytics';
    import Settings from './UserSettings.vue';
    import AppState from './AppState.vue';

    const { empty } = require('ihunter-common');
    

    @Component({
        components: {
            JsonViewer
        }
    })
    export default class Store extends Vue {
       
        app = null;
        jwt = null;      // user token
        user = null;     // firebase auth

        loaded = false;

        route = null;
        args = null;
        params = null;

        summary = {};

        tab = null;
        nav = false;
        login = false;  // Login dialog visible

        affiliate = null;

        get settings() {
            return Settings;
        }

        created() {
            this.app = window.app;

            //this.analytics = new Analytics(this.analyticsId);
            //console.log(this.analytics.client);

            this.state.watchCatalog();
            this.state.watchPromotions();

            this.analytics = new StoreAnalytics();

            // this.accountManager = new AccountManager(this.app);
        }

        mounted() {
            this.authorizeUser();

            if(this.largerViewport()) {
                this.tab = 'cart';
            }
            
        }

        async checkout() {
            try {

                let client = this.analytics.lookupClientId();
                
                //console.log("checkout");
                let response = await this.dal.checkoutCart({
                    items: this.cart.items,
                    promo: this.cart.code,
                    origin: 'store',
                    affiliate: this.affiliate, 
                    success: window.location.origin + '/#/checkout/complete',
                    cancel: window.location.href,
                    client: client
                });

                this.analytics.reportCheckout();

                console.log(response);
                this.settings.checkoutPayment = response.payment; // Remember checkout payment for post-checkout reporting
                window.location.replace(response.url);

            }catch(error) {
                this.state.error.notify(new Error(`Checkout error (${error.message})`, error));
            }
        }

        async portal() {
            try {
                console.log("portal");
                let response = await this.dal.portal();
                console.debug(response);
                window.location.replace(response.url);

            }catch(error) {
                this.state.error.notify(new Error(`Portal error (${error.message})`, error));
            }
        }

        async mail(name, email, message) {
            try {
                console.log("mail");
                let response = await this.dal.mail(name, email, message);
                console.log(response);
                return response;

            }catch(error) {
                this.state.error.notify(new Error(`Contact error (${error.message})`, error));
            }
        }

        authorizeUser() {
            window.app.signIn('#auth-container');
        }

        authStatusChanged(jwt, user) {
            console.log(`Authentication status change: ${jwt? 'signed-in':'signed-out'}`);

            if(jwt && user) {
                this.jwt = jwt;
                this.user = user;

                this.state.authenticated(this.jwt, this.user);

                if (this.redirect) {
                    if (this.redirect.route) {
                        this.navigate(this.redirect.route, redirect.args, redirect.params);
                    } else {
                        this.navigate(this.redirect)
                    }
                    this.redirect = null;
                }

            }else {
                this.user = null;
                this.jwt = null;
                this.redirect = null;
            }

            this.loaded = true;
        }

        switchTabToRoute() {

        }

        routeChanged(route, args, params) {
            this.route = route;
            this.args = args;
            this.params = params;

            this.state.routeChanged(route, args, params);

            
            switch (this.route) {

                case 'login':
                    this.login = true;
                    this.redirect = args;
                    break;

                // Switch to the appriopriate tab when we target one of the account pages
                case 'updates':
                case 'purchases':
                case 'support':
                case 'billing':
                case 'account':
                    if (this.largerViewport()) {
                        this.tab = 'account';
                    }
                    break;
                default:
                    if (this.largerViewport()) {
                        this.tab = 'cart';
                    }
            }

            // if(this.route === 'login') {
            //     this.login = true;
            // }
            // else if(this.route === 'checkout' && this.args === 'complete') {

            //     this.checkoutComplete(params.session);
            // }
        }

        // async checkoutComplete() {
        //     // const payment = this.settings.checkoutPayment;
        //     // if(!payment) {
        //     //     console.warn(`Unable to find checkout payment: ${payment}`)
        //     //     //this.navigate('purchases');
        //     //     return;
        //     // }

        //     // console.log(`Checkout complete with purchase: ${payment}`)
        //     //this.navigate('purchases', 'success', {payment});

        //     try {
        //         console.log("checkout");
        //         let response = await this.dal.checkoutSession(this.params.session);

        //         console.log(response);
        //         //this.settings.checkoutPayment = response.payment; // Remember checkout payment for post-checkout reporting
        //         //window.location.replace(response.url);

        //     }catch(error) {
        //         // let details = error.message?? error.error??
        //         this.state.error.notify(new Error(`Checkout error (${error.message})`, error));
        //     }
        // }

        errorHandler(message, error) {
            this.state.error.notify(new Error(message, error));
            console.error(error);
        }

        loginDismissed(confirmed) {
            this.login = false;

            if(confirmed) {
                this.settings.accountConfirmed = true;
            }

            // If a redirect was defined, but we cancelled, then redirect to a non-auth page. 
            if (this.redirect) {
                this.redirect = null;
                this.navigate();
            }
        }

        empty(obj) {
            return empty(obj);
        }

        get showHome() {
            return !this.route;
        }

        get showCart() {
            return this.tab === 'cart';
        }

        get showAccount() {
            return this.tab === 'account';
        }

        get showSearch() {
            return this.tab === 'search';
        }

        get showLogin() {
            return this.login || (this.isAuthenticated && !this.isAccountConfirmed);
        }

        get isAccountConfirmed() {
            return this.settings.accountConfirmed;
        }

        get isAuthenticated() {
            return this.jwt !== null;
        }

        get activeRegion() {
            return this.state.region;
        }

        get cartItemCount() {
            return 14;
        }

        get config() {
            return Environment.config;
        }

        get appUrl() {
            return Environment.config.webAppURL;
        }

        get projectId() {
            return Environment.config.projectId;
        }

        get photo() {
            return this.user?.photoURL;
        }

        get dal() {
            return this.state.dal;
        }

        get purchases() {
            return this.state.purchases;
        }

        get cart() {
            return this.state.cart;
        }

        get catalog() {
            return this.state.catalog;
        }

        get products() {
            return this.state.products;
        }

        get state() {
            return AppState;
        }

        signIn() {

            // if (event.redirect) {
            //     this.redirect = event.redirect;
            // }

            this.settings.accountConfirmed = null; // Reset account confirmation to prompt after sign-in

            this.login = true;
        }

        signOut() {
            this.settings.accountConfirmed = null; // Reset account confirmation to prompt after sign-in

            window.app.signOut();
        }

        toggleTab(tab) {
            if(this.tab != tab){
                this.tab = tab;
            }else if(this.largerViewport()) {
                this.tab = 'cart';
            }else {
                this.tab = null;
            }
        }

        toggleAccount() {
            if(!this.isAuthenticated) {
                this.signIn();

            }else {
                this.toggleTab('account');                
            }
        }

        toggleHome() {
            if(!this.showHome) {
                this.viewHome();
            }
        }

        toggleCart() {
            this.toggleTab('cart');
        }

        toggleSearch() {
            this.toggleTab('search');
        }

        navigate(route, args, params) {
            if (!this.largerViewport()) {
                this.tab = null; // Hide tabs and nav bar
            }
            this.app.navigate(route, args, params); 
        }

        viewHome() {
            this.app.navigate();
        }

        largerViewport() {
            return window.matchMedia('(min-width: 1136px)').matches;
        }

        smallerViewport() {
            return window.matchMedia('(max-width: 575px)').matches;
        }

    }
    
</script>
